/**
 * Route types for the application
 */

/**
 * Represents a route from the database
 */
export interface RouteData {
  id: string;
  route_key: string;
  path: string;
  label: string;
  title: string;
  description: string;
  is_active: boolean;
  parent_route_key: string | null;
  route_type: RouteType;
  icon_url: string | null;
  sort_order: number;
  requires_auth: boolean;
  last_updated: string;
  metadata: Record<string, any> | null;
}

/**
 * Types of routes in the application
 */
export enum RouteType {
  MAIN = 'MAIN',
  SOLUTION = 'SOLUTION',
  LEGAL = 'LEGAL',
  SUPPORT = 'SUPPORT',
  SOCIAL = 'SOCIAL',
  AUTH = 'AUTH'
}

/**
 * Routes grouped by their type
 */
export interface GroupedRoutes {
  [RouteType.MAIN]: RouteData[];
  [RouteType.SOLUTION]: RouteData[];
  [RouteType.LEGAL]: RouteData[];
  [RouteType.SUPPORT]: RouteData[];
  [RouteType.SOCIAL]: RouteData[];
  [RouteType.AUTH]: RouteData[];
}

/**
 * SEO metadata for routes
 */
export interface RouteMetadata {
  title: string;
  description: string;
  keywords?: string;
  ogImage?: string;
  ogType?: string;
}

/**
 * Record of route metadata for all routes
 */
export type RoutesMetadataRecord = Record<string, RouteMetadata>; 