import { User } from 'firebase/auth';
import { collection, addDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../utils/firebase/config';
import { getDeviceInfo, getIPAddress } from '../utils/deviceInfo';

/**
 * Track a user sign-up in the database
 * @param userId The user's unique identifier
 * @param phoneNumber The user's full phone number including country code
 * @returns Promise<boolean> True if tracking was successful
 */
export async function trackSignup(userId: string, phoneNumber: string): Promise<boolean> {
  try {
    // Get device and browser info
    const deviceInfo = getDeviceInfo();
    
    // Get IP address (async operation)
    const ipAddress = await getIPAddress();
    
    // Extract UTM parameters from URL if available
    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source') || null;
    const utmMedium = urlParams.get('utm_medium') || null;
    const utmCampaign = urlParams.get('utm_campaign') || null;
    
    // Prepare tracking data
    const trackingData = {
      user_id: userId,
      ip_address: ipAddress,
      browser_name: deviceInfo.browser,
      browser_version: deviceInfo.browserVersion,
      platform: deviceInfo.platform,
      operating_system: deviceInfo.os,
      is_mobile: deviceInfo.isMobile,
      screen_resolution: deviceInfo.screenSize,
      language: navigator.language,
      user_agent: navigator.userAgent,
      referrer: document.referrer || null,
      signup_date: new Date().toISOString(),
      source: 'website',
      phone_number: phoneNumber,
      utm_source: utmSource,
      utm_medium: utmMedium,
      utm_campaign: utmCampaign
    };
    
    // Save to Firestore
    try {
      const userSignupRef = collection(db, 'user_signups_tracking');
      await addDoc(userSignupRef, trackingData);
      return true;
    } catch (error) {
      console.error('Error tracking signup:', error);
      return false;
    }
  } catch (err) {
    console.error('Exception tracking signup:', err);
    return false;
  }
}

/**
 * Track user activity such as logins, page views, etc.
 * @param userId The user's unique identifier
 * @param eventType The type of event to track (login, page_view, etc.)
 * @param eventData Optional additional data to store with the event
 * @returns Promise<boolean> True if tracking was successful
 */
export async function trackUserActivity(
  userId: string, 
  eventType: string, 
  eventData?: Record<string, any>
): Promise<boolean> {
  try {
    const activityData = {
      user_id: userId,
      event_type: eventType,
      event_data: eventData || null,
      timestamp: new Date().toISOString()
    };
    
    // Save to Firestore
    try {
      const userActivityRef = collection(db, 'user_activity');
      await addDoc(userActivityRef, activityData);
      return true;
    } catch (error) {
      console.error('Error tracking user activity:', error);
      return false;
    }
  } catch (err) {
    console.error('Exception tracking user activity:', err);
    return false;
  }
} 