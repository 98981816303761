/**
 * Device info utility
 * Provides functions to get information about the user's device and browser
 */

export interface DeviceInfo {
  browser: string;
  browserVersion: string;
  os: string;
  platform: string;
  isMobile: boolean;
  screenSize: string;
}

/**
 * Get information about the user's device and browser
 * @returns DeviceInfo object with device details
 */
export function getDeviceInfo(): DeviceInfo {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform || 'Unknown';
  const vendor = navigator.vendor || '';
  
  // Detect browser
  let browser = 'Unknown';
  let browserVersion = '';
  
  // Chrome
  if (userAgent.indexOf('Chrome') !== -1 && userAgent.indexOf('Edg') === -1 && userAgent.indexOf('OPR') === -1) {
    browser = 'Chrome';
    const match = userAgent.match(/Chrome\/(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  // Safari
  else if (userAgent.indexOf('Safari') !== -1 && userAgent.indexOf('Chrome') === -1) {
    browser = 'Safari';
    const match = userAgent.match(/Version\/(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  // Firefox
  else if (userAgent.indexOf('Firefox') !== -1) {
    browser = 'Firefox';
    const match = userAgent.match(/Firefox\/(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  // Edge
  else if (userAgent.indexOf('Edg') !== -1) {
    browser = 'Edge';
    const match = userAgent.match(/Edg\/(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  // Opera
  else if (userAgent.indexOf('OPR') !== -1) {
    browser = 'Opera';
    const match = userAgent.match(/OPR\/(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  // IE
  else if (userAgent.indexOf('Trident') !== -1) {
    browser = 'Internet Explorer';
    const match = userAgent.match(/rv:(\d+\.\d+)/);
    browserVersion = match ? match[1] : '';
  }
  
  // Detect if mobile
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  
  // Detect OS
  let os = 'Unknown';
  if (userAgent.indexOf('Windows') !== -1) {
    os = 'Windows';
  } else if (userAgent.indexOf('Mac') !== -1) {
    os = 'MacOS';
  } else if (userAgent.indexOf('Android') !== -1) {
    os = 'Android';
  } else if (userAgent.indexOf('iOS') !== -1 || userAgent.indexOf('iPhone') !== -1 || userAgent.indexOf('iPad') !== -1) {
    os = 'iOS';
  } else if (userAgent.indexOf('Linux') !== -1) {
    os = 'Linux';
  }
  
  // Get screen size
  const screenSize = `${window.screen.width}x${window.screen.height}`;
  
  return {
    browser,
    browserVersion,
    os,
    platform,
    isMobile,
    screenSize
  };
}

/**
 * Get the user's IP address using a third-party service
 * @returns Promise<string | null> The IP address or null if not found
 */
export async function getIPAddress(): Promise<string | null> {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) {
      throw new Error(`Failed to fetch IP address: ${response.status}`);
    }
    const data = await response.json();
    return data.ip || null;
  } catch (error) {
    console.error('Error getting IP address:', error);
    return null;
  }
} 