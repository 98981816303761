import grad18 from "../assets/Grad_18.png";
import grad15 from "../assets/Grad_15.png";
import grad17 from "../assets/Grad_17.png";

interface ChooseProps {
  id?: string;
}

export const Choose : React.FC<ChooseProps> = ({ id }) =>{
  return (
    <div id={id} className="w-[80%] mx-auto">
      <div>Why Choose Valuefy's Structured Implementation?</div>
      <div className="flex flex-wrap justify-between gap-10">
        <div className="bg-[#3c138d1a] md:w-[30%] w-full  rounded-lg flex flex-col items-center justify-center gap-6 py-10 px-5">
          <img src={grad18} alt="" className="w-[80%] mb-10" />
          <p className="text-white text-3xl font-bold text-center">
            Seamless <br />
            Integration
          </p>
          <p className="text-[18px] text-center text-[#CBD5E1]">
            We eliminate technology silos and ensure flawless connectivity.
          </p>
        </div>
        <div className="bg-[#3c138d1a] md:w-[30%] w-full rounded-lg flex flex-col items-center justify-center gap-6 py-10 px-5">
          <img src={grad15} alt="" className="w-[80%] mb-10" />
          <p className="text-white text-3xl font-bold text-center">
            Risk-Free <br /> Migration
          </p>
          <p className=" text-[#CBD5E1] text-[18px] text-center">
            Our phased approach minimizes disruptions & protects your data.
          </p>
        </div>
        <div className="bg-[#3c138d1a] md:w-[30%] w-full rounded-lg flex flex-col items-center justify-center gap-6 py-10 px-5">
          <img src={grad17} alt="" className="w-[80%] mb-10" />
          <p className="text-white text-3xl font-bold text-center">
            Future-Proof <br /> WealthTech
          </p>
          <p className=" text-[#CBD5E1] text-[18px] text-center">
            Regular optimizations ensure long-term scalability.
          </p>
        </div>
      </div>
      <div className="mt-20">
        <div className="text-white text-center">
          <p className="w-[70%] mx-auto text-[20px] mb-10">
          We make sure your technology works for you—so you can focus on growth, not operational challenges.
          </p>
          <button className="bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-8 py-2 rounded-md mb-20">
            Request a Personalized Pricing Plan
          </button>
        </div>
      </div>
    </div>
  );
};
