import React from 'react';
import Navbar from './Navbar';

// You can create these components later
import WMHero from './WMHero';
import ValuefyFeatures from './ValuefyFeatures';
import CIOReality from './CIOReality';
import BookDemoCIO from './BookDemoCIO';
import CIOShowcase from './CIOShowcase';
// Footer removed to avoid duplication with RootLayout footer
// import CIOFooter from './CIOFooter';

const CIOPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <CIOReality/>
      <ValuefyFeatures/>
      <CIOShowcase/>
      <BookDemoCIO/>
      {/* CIOFooter removed to avoid duplication */}
    </div>
  );
};

export default CIOPage;