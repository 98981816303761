import React from 'react';
import Navbar from './Navbar';

// You can create these components later
import WMHero from './WMHero';
import TodaysReality from './TodaysReality';
import VideoShowcase from './VideoShowcase';
import ValuefyFeatures from './ValuefyFeatures';
import BookDemoWM from './BookDemoWM';
// import SolutionFeatures from './SolutionFeatures';
// import SolutionCTA from './SolutionCTA';

const SolutionPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <TodaysReality/>
      <ValuefyFeatures/>
      <VideoShowcase />
      <BookDemoWM/>
      {/* <SolutionHero />
      <SolutionFeatures />
      <SolutionCTA /> */}
    </div>
  );
};

export default SolutionPage;