import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import aggregate1 from '../assets/wealth-manager/aggregate_1.png';
import grid from '../assets/wealth-manager/grid.png';
import agg2 from '../assets/wealth-manager/agg2.png';
import agg3 from '../assets/wealth-manager/agg3.png';
import anala1 from '../assets/wealth-manager/anala1.png';
import anala2 from '../assets/wealth-manager/anala2.png';
import adv2 from '../assets/wealth-manager/adv2.png';
import transa1 from '../assets/wealth-manager/transa1.png';
import transa2 from '../assets/wealth-manager/transa2.png';
import engage1 from '../assets/wealth-manager/engage1.png';
import engage2 from '../assets/wealth-manager/engage2.png';
import engage3 from '../assets/wealth-manager/engage3.png';
import co1 from '../assets/wealth-manager/co1.png';
import co3 from '../assets/wealth-manager/co3.png';
import co4 from '../assets/wealth-manager/co4.png';
import co5 from '../assets/wealth-manager/co5.png';
import co6 from '../assets/wealth-manager/co6.png';
import co7 from '../assets/wealth-manager/co7.png';
import z1 from '../assets/wealth-manager/z1.png';
import z2 from '../assets/wealth-manager/z2.png';
import z3 from '../assets/wealth-manager/z3.png';
import z4 from '../assets/wealth-manager/z4.png';
import z5 from '../assets/wealth-manager/z5.png';
import z6 from '../assets/wealth-manager/z6.png';
import z7 from '../assets/wealth-manager/z7.png';
import z8 from '../assets/wealth-manager/z8.png';
import z9 from '../assets/wealth-manager/z9.png';
import z10 from '../assets/wealth-manager/z10.png';
import z11 from '../assets/wealth-manager/z11.png';
import z12 from '../assets/wealth-manager/z12.png';
import z13 from '../assets/wealth-manager/z13.png';
import z14 from '../assets/wealth-manager/z14.png';
import z15 from '../assets/wealth-manager/z15.png';
import z16 from '../assets/wealth-manager/z16.png';
import z17 from '../assets/wealth-manager/z17.png';
import z18 from '../assets/wealth-manager/z18.png';
import z19 from '../assets/wealth-manager/z19.png';
import z20 from '../assets/wealth-manager/z20.png';
import z21 from '../assets/wealth-manager/z21.png';
import z22 from '../assets/wealth-manager/z22.png';
import z23 from '../assets/wealth-manager/z23.png';
import z24 from '../assets/wealth-manager/z24.png';
import z25 from '../assets/wealth-manager/z25.png';
import z26 from '../assets/wealth-manager/z26.png';
import z27 from '../assets/wealth-manager/z27.png';
import z28 from '../assets/wealth-manager/z28.png';
import z29 from '../assets/wealth-manager/z29.png';
import z30 from '../assets/wealth-manager/z30.png';
import z31 from '../assets/wealth-manager/z31.png';
import z32 from '../assets/wealth-manager/z32.png';
import z33 from '../assets/wealth-manager/z33.png';
import z34 from '../assets/wealth-manager/z34.png';
import z35 from '../assets/wealth-manager/z35.png';
import z36 from '../assets/wealth-manager/z36.png';
import z37 from '../assets/wealth-manager/z37.png';
import z47 from '../assets/wealth-manager/z47.png';
import z39 from '../assets/wealth-manager/z39.png';
import z40 from '../assets/wealth-manager/z40.png';
import z41 from '../assets/wealth-manager/z41.png';
import z42 from '../assets/wealth-manager/z42.png';
import z43 from '../assets/wealth-manager/z43.png';
import z44 from '../assets/wealth-manager/z44.png';
import z45 from '../assets/wealth-manager/z45.png';
import z46 from '../assets/wealth-manager/z46.png';
import f1 from '../assets/wealth-manager/f1.png';
import f2 from '../assets/wealth-manager/f2.png';
import { SOLUTION_ROUTES } from "../constants/routes";

type TabType = 'aggregate' | 'analytics' | 'advisory' | 'transaction' | 'engagement';
type RouteType = typeof SOLUTION_ROUTES.WEALTH_MANAGER.path | 
                typeof SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path | 
                typeof SOLUTION_ROUTES.COMPLIANCE_OFFICER.path | 
                typeof SOLUTION_ROUTES.OPERATION_TEAM.path | 
                typeof SOLUTION_ROUTES.CIO.path | 
                typeof SOLUTION_ROUTES.FAMILY_OFFICE.path;

interface ContentConfig {
  mainHeading: string;
  subHeading: string;
  tabContents: {
    [key in TabType]: {
      title: string;
      subtitle: string;
      features: {
        id: number;
        quote: string;
        description: string;
        image: string;
        title: string;
        caption: string;
      }[];
    };
  };
}

const ValuefyFeatures = () => {
  const [activeTab, setActiveTab] = useState<TabType>('aggregate');
  const location = useLocation();
  const currentPath = location.pathname as RouteType;

  // Define content configurations for each route
  const routeContentMap: Record<RouteType, ContentConfig> = {
    [SOLUTION_ROUTES.WEALTH_MANAGER.path]: {
      mainHeading: "That's why we built Valuefy",
      subHeading: "An AI-powered platform that empowers Wealth Managers with everything they need to save time, serve clients better, and win more mandates.",
      tabContents: {
        aggregate: {
          title: "All Your Data, One Source of Truth",
          subtitle: "A single source of truth for all investments, cutting manual effort by 80%.",
          features: [
            {
              id: 1,
              quote: `"I need a single view of all my client's investments`,
              description: `across asset classes, custodians, and structures."`,
              image: agg2,
              title: "Automated Data Aggregation & Standardization",
              caption: "No more manual inputs"
            },
            {
              id: 2,
              quote: `"Manual reconciliation wastes hours of my team's time."`,
              description: "",
              image: aggregate1,
              title: "Seamless Integration Across Custodians",
              caption: "All data in one place."
            },
            {
              id: 3,
              quote: `"I can't rely on multiple sources`,
              description: `for accuracy."`,
              image: z1,
              title: "AI-Powered Reconciliation",
              caption: "Accurate, real-time, reliable"
            }
          ]
        },
        analytics: {
          title: "Make Faster, Smarter Investment Decisions",
          subtitle: "Effortless tracking of all portfolios, with real-time risk monitoring and actionable insights",
          features: [
            {
              id: 1,
              quote: `"I don't have a real-time, on-demand view `,
              description: `of my portfolios."`,
              image: aggregate1,
              title: "Predictive Risk Analysis",
              caption: "Detect issues before they occur"
            },
            {
              id: 2,
              quote: `"Risk analytics and performance tracking are fragmented."`,
              description: "",
              image: anala1,
              title: "Cross-Portfolio Opportunity Finder",
              caption: "Automated opportunity detection"
            },
            {
              id: 3,
              quote: `"My clients expect insights I can't always provide instantly."`,
              description: "",
              image: anala2,
              title: "Tailored Reporting Engine",
              caption: "Perfect reports in seconds"
            }
          ]
        },
        advisory: {
          title: "Scale Advice, Minimize Complexity",
          subtitle: "Less manual work, more time for strategy, and a seamless client experience.",
          features: [
            {
              id: 1,
              quote: `"How do I standardize and scale `,
              description: `my advisory process?"`,
              image: z2,
              title: "Personalized Strategy Builder",
              caption: "Client-specific recommendations"
            },
            {
              id: 2,
              quote: `"Managing small mandates is too resource-intensive."`,
              description: "",
              image: adv2,
              title: "Compliance Assistant",
              caption: "Always up-to-date"
            },
            {
              id: 3,
              quote: `"I need a way to personalize client proposals at scale."`,
              description: "",
              image: z3,
              title: "AI-Powered Portfolio Optimization",
              caption: "Maximum returns, minimized risk"
            }
          ]
        },
        transaction: {
          title: "Secure, Compliant, and Hassle-Free",
          subtitle: "No revenue leakage, zero compliance worries, and faster execution.",
          features: [
            {
              id: 1,
              quote: `"Compliance rules are complex and ever-changing."`,
              description: "",
              image: z4,
              title: "Automated Transaction Processing",
              caption: "Fast, accurate, reliable"
            },
            {
              id: 2,
              quote: `"Revenue tracking is inconsistent, and leakage happens."`,
              description: "",
              image: transa1,
              title: "Unified Trading Interface",
              caption: "One platform for all trades"
            },
            {
              id: 3,
              quote: `"Approvals, order execution, and reporting take too long."`,
              description: "",
              image: transa2,
              title: "Real-time Transaction Monitoring",
              caption: "Complete visibility and control"
            }
          ]
        },
        engagement: {
          title: "Build Trust, Deliver Unmatched Client Experience",
          subtitle: "Faster onboarding, deeper client relationships, and higher retention.",
          features: [
            {
              id: 1,
              quote: `"Client onboarding is slow, and paperwork is a nightmare."`,
              description: "",
              image: engage1,
              title: "Client Portal & Mobile App",
              caption: "24/7 access and transparency"
            },
            {
              id: 2,
              quote: `"Engaging high-value clients at scale is a challenge."`,
              description: "",
              image: engage2,
              title: "Automated Client Communications",
              caption: "Personalized at scale"
            },
            {
              id: 3,
              quote: `"My RMs spend too much time on operations, not relationships."`,
              description: "",
              image: engage3,
              title: "Client Insights Dashboard",
              caption: "Understand needs before they ask"
            }
          ]
        }
      }
    },
    [SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path]: {
      mainHeading: "That's why we built Valuefy",
      subHeading: "An AI-powered platform that empowers Relationship Managers with everything they need to save time, serve clients better, and win more mandates.",
      tabContents: {
        aggregate: {
          title: "One Platform. Complete Client View.",
          subtitle: "Faster client servicing, no data gaps, no delays",
          features: [
            {
              id: 1,
              quote: `"I need accurate, real-time data`,
              description: `for all my clients."`,
              image: z5,
              title: "Unified Client Dashboard",
              caption: "All data, one place."
            },
            {
              id: 2,
              quote: `"Switching between multiple systems`,
              description: `slows me down."`,
              image: z6,
              title: "Real-Time Data Aggregation",
              caption: "Always updated & accurate."
            },
            {
              id: 3,
              quote: `"I need a single view`,
              description: `of all asset classes and investments."`,
              image: z7,
              title: "AI-Powered Reconciliation",
              caption: "No manual errors."
            }
          ]
        },
        analytics: {
          title: "Real-Time Insights for Every Client Interaction.",
          subtitle: "client interactions—anytime, anywhere.",
          features: [
            {
              id: 1,
              quote: `"I spend too much time preparing reports`,
              description: `before meetings"`,
              image: z8,
              title: "On-Demand Portfolio Analytics",
              caption: "Always ready for client conversations."
            },
            {
              id: 2,
              quote: `"I need to answer client queries`,
              description: `instantly."`,
              image: z9,
              title: "Mobile-Friendly Dashboards",
              caption: "Client insights on the go."
            },
            {
              id: 3,
              quote: `"I want a portfolio view that workson mobile."`,
              description: ``,
              image: z10,
              title: "Personalized Reporting Tools",
              caption: "Custom statements in seconds."
            }
          ]
        },
        advisory: {
          title: "Close More Deals, Confidently.",
          subtitle: "More mandates won, fewer manual processes.",
          features: [
            {
              id: 1,
              quote: `"I need a solid proposal tool to win`,
              description: `client mandates."`,
              image: z11,
              title: "Model Portfolio Management",
              caption: "Show before & after scenarios."
            },
            {
              id: 2,
              quote: `"How do I showcase portfolio impact`,
              description: `before execution?"`,
              image: adv2,
              title: "Seamless Collaboration with Investment Teams",
              caption: "Work faster, together."
            },
            {
              id: 3,
              quote: `"Collaboration between`,
              description: `RM and investment teams is slow."`,
              image: z12,
              title: "Proposal Builder",
              caption: "Create high-impact client presentations in clicks."
            }
          ]
        },
        transaction: {
          title: "Secure, Fast, and Hassle-Free.",
          subtitle: "no missed opportunities.",
          features: [
            {
              id: 1,
              quote: `"How do I ensure no revenue leakage`,
              description: `in my book?"`,
              image: z13,
              title: "Revenue & Fee Management Dashboard",
              caption: "No leakage, full visibility"
            },
            {
              id: 2,
              quote: `"Tracking transactions & special fees`,
              description: `takes too much time."`,
              image: transa1,
              title: "Transaction Tracking in Real-Time",
              caption: "Always know where things stand."
            },
            {
              id: 3,
              quote: `"I waste hours checking if`,
              description: `orders are stuck."`,
              image: transa2,
              title: "Automated Order Execution",
              caption: "No manual errors, no delays"
            }
          ]
        },
        engagement: {
          title: "Simplify Onboarding. Strengthen Client Relationships.",
          subtitle: "Faster onboarding, happier clients, stronger retention.",
          features: [
            {
              id: 1,
              quote: `"Onboarding clients takes too long,`,
              description: `and paperwork is a nightmare."`,
              image: engage1,
              title: "Digital Onboarding",
              caption: "Paperless, secure, zero delays"
            },
            {
              id: 2,
              quote: `"I'm constantly worried`,
              description: `about compliance risks during onboarding."`,
              image: engage2,
              title: "AI-Powered Compliance Checks",
              caption: "Zero risk of regulatory issues"
            },
            {
              id: 3,
              quote: `"Clients expect a seamless`,
              description: `digital experience."`,
              image: engage3,
              title: "Advisor Workbench",
              caption: "Manage client relationships seamlessly."
            }
          ]
        }
      }
    },
    [SOLUTION_ROUTES.COMPLIANCE_OFFICER.path]: {
      mainHeading: "That's why we built Valuefy",
      subHeading: "A single, AI-powered platform that ensures seamless compliance, real-time monitoring, and complete control over regulatory obligations.",
      tabContents: {
        aggregate: {
          title: "One Source of Truth for All Compliance Data",
          subtitle: "Zero data security gaps, zero compliance surprises.",
          features: [
            {
              id: 1,
              quote: `"I need to ensure all client and firm data is secure and in line with privacy laws."`,
              description: "",
              image: z14,
              title: "Centralized Data Security & Privacy Controls",
              caption: "All compliance data in one place, fully secured."
            },
            {
              id: 2,
              quote: `"Are all agreements and contracts in place and up to date?"`,
              description: "",
              image: z15,
              title: "Automated Agreement Management",
              caption: "Rack, update, and renew agreements without manual effort."
            },
            {
              id: 3,
              quote: `"How do I track`,
              description: `when agreements need renewal?"`,
              image: z16,
              title: "Audit-Ready Reports",
              caption: "Stay ahead of regulatory audits with real-time data."
            }
          ]
        },
        // Define other tabs for Compliance Officer route
        analytics: {
          title: "Real-Time Monitoring of Compliance Risks",
          subtitle: "Instant compliance visibility, reduced regulatory risk.",
          features: [
            {
              id: 1,
              quote: `"I need a real-time dashboard`,
              description: `to track breaches and suspicious activities."`,
              image: co1,
              title: "Compliance Risk Dashboard",
              caption: "Monitor breaches, exceptions, and rule violations in real-time."
            },
            {
              id: 2,
              quote: `"Regulators expect instant visibility`,
              description: `into firm-wide compliance."`,
              image: z17,
              title: "Automated Suspicious Activity Detection",
              caption: "AI-driven alerts for potential financial crime."
            },
            {
              id: 3,
              quote: `"Manual compliance reporting is too slow and error-prone."`,
              description: "",
              image: z18,
              title: "Regulatory Audit View",
              caption: "Instantly access compliance data for FINMA, DIFC PIB, and other regulators."
            }
          ]
        },
        advisory: {
          title: "Ensure Every Trade & Transaction is Fully Compliant",
          subtitle: "Zero compliance breaches, seamless trade monitoring.",
          features: [
            {
              id: 1,
              quote: `"How do I ensure every trade follows the right compliance rules?"`,
              description: "",
              image: co3,
              title: "Pre-Trade & Post-Trade Compliance Checks",
              caption: "No more rule violations."
            },
            {
              id: 2,
              quote: `"Can I detect and prevent manual errors `,
              description: `before submission?"`,
              image: co4,
              title: "Automated Trade Approval Workflows",
              caption: "Faster approvals, no delays."
            },
            {
              id: 3,
              quote: `"Tracking compliance approvals is manual and fragmented."`,
              description: "",
              image: co5,
              title: "Transaction Monitoring Against Mandates",
              caption: "Every trade is checked before submission."
            }
          ]
        },
        transaction: {
          title: "Ensure Accuracy and Prevent Revenue Leakage",
          subtitle: "Faster settlements, zero revenue leakage, complete transaction oversight.",
          features: [
            {
              id: 1,
              quote: `"How do I track all transactions`,
              description: `across multiple systems?"`,
              image: co6,
              title: "Blotter Management Across Systems",
              caption: "Real-time transaction tracking, no blind spots."
            },
            {
              id: 2,
              quote: `"Ensuring accurate posting and settlement is a constant challenge."`,
              description: "",
              image: transa1,
              title: "Automated Recon & Settlement Tracking",
              caption: "No manual errors, 100% accuracy."
            },
            {
              id: 3,
              quote: `"Manual checks take too long,`,
              description: `and mistakes can be costly."`,
              image: transa2,
              title: "Approval Workflows for RMs & Clients",
              caption: "No unauthorized transactions slip through."
            }
          ]
        },
        engagement: {
          title: "Digitize Compliance & Risk Monitoring",
          subtitle: "Fully digital compliance, reduced regulatory risk, and faster onboarding.",
          features: [
            {
              id: 1,
              quote: `"AML & KYC processes are too slow and manual."`,
              description: "",
              image: co7,
              title: "Automated KYC & AML Checks",
              caption: "4-eye verification process for 100% accuracy."
            },
            {
              id: 2,
              quote: `"Tracking regulatory trust and audit requirements is exhausting."`,
              description: "",
              image: f1,
              title: "Regulatory Audit Trail",
              caption: "Ensure compliance records are always audit-ready."
            },
            {
              id: 3,
              quote: `"Due diligence processes are inconsistent and time-consuming."`,
              description: "",
              image: f2,
              title: "Seamless DD/EDD Process",
              caption: "Digitized due diligence for faster approvals."
            }
          ]
        }
      }
    },
    [SOLUTION_ROUTES.OPERATION_TEAM.path]: {
      mainHeading: "That's why we built Valuefy",
      subHeading: "A platform designed to automate the most complex operational workflows, minimize errors, and maximize efficiency.",
      tabContents: {
        aggregate: {
          title: "One Platform for All Data & Reconciliation",
          subtitle: "Faster reporting, zero manual reconciliations, complete accuracy.",
          features: [
            {
              id: 1,
              quote: `"I spend too much time manually reconciling data `,
              description: `across custodians and brokers."`,
              image: z19,
              title: "Automated Data Aggregation & Standardization",
              caption: "No manual work, no errors."
            },
            {
              id: 2,
              quote: `"How do I ensure all reports are accurate and up to date?"`,
              description: "",
              image: z20,
              title: "Flexible Reporting & Custom Dashboards",
              caption: "Serve every team's needs instantly."
            },
            {
              id: 3,
              quote: `"Different teams ask for the same data in different formats`,
              description: `—it's exhausting!"`,
              image: z21,
              title: "AI-Powered Reconciliation",
              caption: "Identify and fix discrepancies automatically."
            }
          ]
        },
        // Define other tabs for Operations Team
        analytics: {
          title: "Monitor, Manage, and Optimize with Ease",
          subtitle: "No more operational blind spots, real-time insights at your fingertips.",
          features: [
            {
              id: 1,
              quote: `"I need a real-time view of transactions, holdings, and open orders."`,
              description: "",
              image: z22,
              title: "Multi-Screen Data Management",
              caption: "Organize, analyze, and track everything in one place."
            },
            {
              id: 2,
              quote: `"Can I customize reports based on different team's needs?"`,
              description: "",
              image: z23,
              title: "Automated Transaction Monitoring",
              caption: "Instant alerts on pending or failed transactions."
            },
            {
              id: 3,
              quote: `"How do I handle multiple data formats from different custodians?"`,
              description: "",
              image: z24,
              title: "Custom Data Overlays",
              caption: "Classify investments by sector, benchmark, or custom criteria."
            }
          ]
        },
        advisory: {
          title: "Reduce Response Time & Increase Efficiency",
          subtitle: "Faster response times, increased productivity, and seamless inter-department collaboration.",
          features: [
            {
              id: 1,
              quote: `"How do I ensure faster response times to RM, compliance, and auditors?"`,
              description: "",
              image: z25,
              title: "One-Click Data Access for RMs, Compliance & Audit",
              caption: "No more endless requests."
            },
            {
              id: 2,
              quote: `"Managing different revenue models and tracking data takes too long."`,
              description: "",
              image: z26,
              title: "Automated Alerts for Pending Actions",
              caption: "Keep everything on track."
            },
            {
              id: 3,
              quote: `"I need to streamline how teams request and access operational data."`,
              description: "",
              image: z27,
              title: "Revenue Model Management Tools",
              caption: "Ensure accurate revenue tracking and reporting."
            }
          ]
        },
        transaction: {
          title: "Automate Trade & Settlement Processing",
          subtitle: "Faster settlements, zero trade discrepancies, seamless execution.",
          features: [
            {
              id: 1,
              quote: `"Trade and settlement take too much time to track manually."`,
              description: "",
              image: z28,
              title: "Automated Trade & Settlement Tracking",
              caption: "Real-time visibility, no manual"
            },
            {
              id: 2,
              quote: `"Dealing with custodians for transactions and holdings is inefficient."`,
              description: "",
              image: z29,
              title: "Custodian Data Integration",
              caption: "Eliminate delays, access holdings instantly."
            },
            {
              id: 3,
              quote: `"How do I avoid errors in transaction approvals and reporting?"`,
              description: "",
              image: z30,
              title: "Built-in Compliance Checks for Transactions",
              caption: "Avoid errors before they happen."
            }
          ]
        },
        engagement: {
          title: "Simplify Workflows & Free Up Your Time",
          subtitle: "Seamless collaboration, fewer follow-ups, and greater efficiency.",
          features: [
            {
              id: 1,
              quote: `"Too much time is wasted in back-and-forth communication with teams."`,
              description: "",
              image: z31,
              title: "Automated Workflow & Task Management",
              caption: "Track, assign, and complete faster."
            },
            {
              id: 2,
              quote: `"How do I keep track of pending compliance, audit, and operational tasks?"`,
              description: "",
              image: z32,
              title: "Role-Based Dashboards",
              caption: "Everyone sees only what they need—no clutter."
            },
            {
              id: 3,
              quote: `"Can I automate operational workflows to make processes more efficient?"`,
              description: "",
              image: z33,
              title: "Data Health & Audit Monitoring",
              caption: "Proactively manage data integrity."
            }
          ]
        }
      }
    },
    [SOLUTION_ROUTES.CIO.path]: {
      mainHeading: "That's why we built Valuefy",
      subHeading: "A powerful AI-powered investment platform designed to help advisors gain deeper insights, optimize portfolios, and deliver high-value advisory with precision.",
      tabContents: {
        aggregate: {
          title: "One Platform for All Investment Data",
          subtitle: "Instant access to a complete, structured, and reliable investment view.",
          features: [
            {
              id: 1,
              quote: `"I need a single source of truth `,
              description: `across all custodians and asset classes."`,
              image: z34,
              title: "Aggregated Portfolio Data Across Custodians",
              caption: "One platform, all investments."
            },
            {
              id: 2,
              quote: `"How do I overlay my custom classifications and strategies onto client portfolios?"`,
              description: "",
              image: z35,
              title: "Customizable Data Overlays",
              caption: "Segment by sector, benchmark, or strategy."
            },
            {
              id: 3,
              quote: `"Can I get a real-time, consolidated view`,
              description: `of all investments?"`,
              image: z36,
              title: "Real-Time Data Refresh",
              caption: "No delays, always accurate."
            }
          ]
        },
        // Define other tabs for CIO
        analytics: {
          title: "Advanced Portfolio & Risk Insights in Seconds",
          subtitle: "Faster investment decisions, deeper client insights, and reduced risk.",
          features: [
            {
              id: 1,
              quote: `"I need to quickly assess portfolio health and risk exposure."`,
              description: "",
              image: z37,
              title: "Top-Down & Bottom-Up Portfolio Analysis",
              caption: "Drill down into client positions effortlessly."
            },
            {
              id: 2,
              quote: `"How do I get a top-down view`,
              description: `of transactions and holdings?"`,
              image: z26,
              title: "Real-Time Risk Analytics",
              caption: "Identify exposures before they become problems."
            },
            {
              id: 3,
              quote: `"Can I personalize insights for my client's unique needs?"`,
              description: "",
              image: z21,
              title: "AI-Driven Investment Insights",
              caption: "Actionable, personalized recommendations."
            }
          ]
        },
        advisory: {
          title: "Streamline Portfolio Management & Execution",
          subtitle: "Better portfolio performance, streamlined advisory, and more confident investing.",
          features: [
            {
              id: 1,
              quote: `"How do I manage and rebalance portfolios efficiently?."`,
              description: "",
              image: z39,
              title: "Model Portfolio Management",
              caption: "Standardized, scalable investment strategies."
            },
            {
              id: 2,
              quote: `"Can I test investment scenarios`,
              description: `before executing trades?"`,
              image: z40,
              title: "Scenario Analysis & Simulations",
              caption: "Test strategies before committing."
            },
            {
              id: 3,
              quote: `"How do I ensure my models are consistently applied across clients?"`,
              description: "",
              image: z41,
              title: "Portfolio Rebalancing & Optimization",
              caption: "Automated execution, no manual errors."
            }
          ]
        },
        transaction: {
          title: "Smarter, Faster, and More Secure Execution",
          subtitle: "Faster settlements, zero trade discrepancies, seamless execution.",
          features: [
            {
              id: 1,
              quote: `"How do I ensure compliance `,
              description: `with mandates before executing trades?"`,
              image: z42,
              title: "Pre-Trade Compliance Checks",
              caption: "Ensure trades follow mandates before execution."
            },
            {
              id: 2,
              quote: `"Can I track transactions across all clients and custodians in real-time?"`,
              description: "",
              image: z43,
              title: "Automated Trade Blotter & Execution Tracking",
              caption: "See all transactions in one place."
            },
            {
              id: 3,
              quote: `"How do I prevent execution delays and errors?"`,
              description: "",
              image: z44,
              title: "Real-Time Order Monitoring",
              caption: "Never miss an execution update."
            }
          ]
        },
        engagement: {
          title: "Elevate Client Conversations with Data-Driven Advice",
          subtitle: "Stronger client relationships, higher mandate wins, and deeper trust.",
          features: [
            {
              id: 1,
              quote: `"How do I show clients the impact`,
              description: `of my investment strategies?"`,
              image: z45,
              title: "Dynamic Client Reporting & Personalized Pitchbooks",
              caption: "Make every conversation insightful."
            },
            {
              id: 2,
              quote: `"Can I deliver personalized, data-backed proposals to win mandates?"`,
              description: "",
              image: z46,
              title: "Advisor Workbench",
              caption: "Collaborate with RMs, Compliance & Operations in real-time."
            },
            {
              id: 3,
              quote: `"How do I collaborate seamlessly `,
              description: `with RMs and compliance teams?"`,
              image: z47,
              title: "Client-Specific Investment Strategies",
              caption: "Tailored, high-value recommendations."
            }
          ]
        }
      }
    },
    [SOLUTION_ROUTES.FAMILY_OFFICE.path]: {
      mainHeading: "Valuefy for Family Offices",
      subHeading: "Comprehensive wealth management solutions tailored for multi-generational family needs.",
      tabContents: {
        aggregate: {
          title: "Family Wealth Integration",
          subtitle: "Holistic view of all family assets, entities, and structures.",
          features: [
            {
              id: 1,
              quote: "Our family assets are spread across multiple structures.",
              description: "",
              image: agg2,
              title: "Multi-Entity Aggregation",
              caption: "Complete family wealth picture"
            },
            {
              id: 2,
              quote: "Consolidated reporting across asset classes is challenging.",
              description: "",
              image: agg3,
              title: "Cross-Asset Reporting",
              caption: "From real estate to private equity"
            },
            {
              id: 3,
              quote: "We need better visibility into family business operations.",
              description: "",
              image: agg3,
              title: "Business Integration Platform",
              caption: "Connect family enterprises"
            }
          ]
        },
        // Define other tabs for Family Offices
        analytics: {
          title: "Multi-Generational Planning",
          subtitle: "Tools for long-term wealth preservation and transfer.",
          features: [
            {
              id: 1,
              quote: "Long-term wealth projection tools are inadequate.",
              description: "",
              image: aggregate1,
              title: "Generational Wealth Simulator",
              caption: "Project across decades"
            },
            {
              id: 2,
              quote: "Tax optimization across family entities is complex.",
              description: "",
              image: anala1,
              title: "Tax Efficiency Analyzer",
              caption: "Optimize family tax structure"
            },
            {
              id: 3,
              quote: "Estate planning lacks integration with investments.",
              description: "",
              image: anala2,
              title: "Integrated Estate Planning",
              caption: "Align all aspects of wealth"
            }
          ]
        },
        advisory: {
          title: "Family Governance",
          subtitle: "Tools to support family decision-making and education.",
          features: [
            {
              id: 1,
              quote: "Family governance processes lack structure.",
              description: "",
              image: agg3,
              title: "Governance Framework",
              caption: "Structured decision processes"
            },
            {
              id: 2,
              quote: "Next generation education is inconsistent.",
              description: "",
              image: adv2,
              title: "Family Education Platform",
              caption: "Prepare future generations"
            },
            {
              id: 3,
              quote: "Family mission and values need better implementation.",
              description: "",
              image: agg3,
              title: "Values-Based Planning",
              caption: "Align wealth with purpose"
            }
          ]
        },
        transaction: {
          title: "Private Investment Management",
          subtitle: "Tools for direct investments, private equity, and alternatives.",
          features: [
            {
              id: 1,
              quote: "Private investment oversight lacks sophistication.",
              description: "",
              image: agg3,
              title: "Private Investment Dashboard",
              caption: "Comprehensive monitoring"
            },
            {
              id: 2,
              quote: "Deal flow management is fragmented.",
              description: "",
              image: transa1,
              title: "Deal Flow Platform",
              caption: "Streamlined opportunity assessment"
            },
            {
              id: 3,
              quote: "Private equity reporting is inconsistent.",
              description: "",
              image: transa2,
              title: "PE Performance Analytics",
              caption: "True performance metrics"
            }
          ]
        },
        engagement: {
          title: "Family Collaboration",
          subtitle: "Tools to engage all family members in wealth management.",
          features: [
            {
              id: 1,
              quote: "Family member engagement with finances varies widely.",
              description: "",
              image: engage1,
              title: "Family Portal",
              caption: "Personalized for each member"
            },
            {
              id: 2,
              quote: "Communication about wealth decisions is inconsistent.",
              description: "",
              image: engage2,
              title: "Family Communication Platform",
              caption: "Transparent, secure sharing"
            },
            {
              id: 3,
              quote: "Philanthropy lacks coordination across family members.",
              description: "",
              image: engage3,
              title: "Family Impact Platform",
              caption: "Coordinate charitable efforts"
            }
          ]
        }
      }
    }
  };

  // Default to RM content if path is not found in the map
  const defaultPath: RouteType = SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path;
  const currentContent = routeContentMap[currentPath] || routeContentMap[defaultPath];
  
  // Get the active tab content for the current route
  const activeContent = currentContent.tabContents[activeTab];

  // Reset to default tab when route changes
  useEffect(() => {
    setActiveTab('aggregate');
  }, [currentPath]);

  return (
    <section className="bg-black text-white py-16 px-6 relative bg-gradient-to-b from-[#000000] via-[#000000] to-[#140029]">
      <div className="max-w-8xl mx-auto">
        {/* Main Heading */}
        <h2 className="text-5xl font-bold text-center mb-3" style={{ lineHeight: '148%' }}>
          {currentContent.mainHeading}
        </h2>
        <p className="text-center text-gray-400 mb-10 text-3xl mx-auto px-6 sm:px-12 lg:px-32" style={{ lineHeight: '148%' }}>
          <span dangerouslySetInnerHTML={{ __html: currentContent.subHeading.replace(/empowers Relationship Managers/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">empowers Relationship Managers</span>').replace(/empowers Wealth Managers/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-500">empowers Wealth Managers</span>').replace(/ensures seamless compliance/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">ensures seamless compliance</span>').replace(/complete control over regulatory obligations/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">complete control over regulatory obligations</span>').replace(/A powerful AI-powered investment platform designed to help advisors/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">A powerful AI-powered investment platform designed to help advisors</span>').replace(/designed to automate the most complex operational workflows,/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">designed to automate the most complex operational workflows,</span>')
            .replace(/save time, serve clients better, and win more mandates/g, '<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text">save time, serve clients better, and win more mandates</span>') }} />
        </p>

        <div className="mb-2">
          <img src={grid} alt="Valuefy Grid" className="w-full" />
        </div>

        {/* Tabs Navigation */}
        <div className="flex flex-wrap justify-center mb-10 border-b border-gray-800 gap-4">
          {Object.keys(currentContent.tabContents).map((tab) => (
            <button
              key={tab}
              className={`px-3 sm:px-4 md:px-6 py-2 md:py-3 text-lg sm:text-xl md:text-2xl font-medium uppercase transition-colors mx-1 sm:mx-2 md:mx-4 mb-[-1] ${
                activeTab === tab 
                  ? 'text-white border-b-2 border-white' 
                  : 'text-gray-400 hover:text-gray-200'
              }`}
              onClick={() => setActiveTab(tab as TabType)}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        {/* Tab Content Heading */}
        <div className="text-center mb-12">
          <h3 className="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-3xl font-bold mb-4">{activeContent.title}</h3>
          <p className="text-white-400 text-2xl">{activeContent.subtitle}</p>
        </div>

        {/* Features Content Area */}
        <div className="space-y-16">
          {activeContent.features.map((feature, index) => (
            <div 
              key={feature.id} 
              className={`flex flex-col md:flex-row items-center gap-8 ${
                index % 2 === 1 ? 'md:flex-row-reverse' : ''
              }`}
            >
              {/* Text Content */}
              <div className="hidden md:block w-full md:w-1/2 space-y-2 order-2 md:order-1">
                <div className="mb-4 md:ml-16">
                  <p className="text-lg font-medium">
                    <span className="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-4xl font mb-4 text-transparent bg-clip-text italic" style={{lineHeight:'154%'}}>{feature.quote}</span>
                    {feature.description && (
                      <span className="text-white text-4xl font-bold mb-4 text-transparent bg-clip-text" style={{lineHeight:'154%'}}> {feature.description}</span>
                    )}
                  </p>
                </div>
              </div>

              {/* Image Content */}
              <div className="w-full md:w-2/5 lg:w-1/3 order-1 md:order-2">
                <div className="bg-purple-900 bg-opacity-10 p-6 md:p-8 lg:p-10 rounded-lg">
                  <img 
                    src={feature.image} 
                    alt={feature.title}
                    className="w-full h-auto rounded mb-2"
                  />
                  <h4 className="text-lg font-semibold">{feature.title}</h4>
                  <p className="text-sm text-gray-400 mb-2">{feature.caption}</p>
                  <p className="md:hidden text-sm font-medium">
                    <span className="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-2xl font-bold mb-4 text-transparent bg-clip-text" style={{lineHeight:'154%'}}>{feature.quote}</span>
                    {feature.description && (
                      <span className="text-white text-2xl font-bold mb-4 text-transparent bg-clip-text" style={{lineHeight:'154%'}}> {feature.description}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ValuefyFeatures;