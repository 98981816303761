import React, { useState, useEffect, useRef } from 'react';
import { CountryCode } from '../../utils/types/auth';

interface CountrySelectorProps {
  selectedCountry: CountryCode;
  onSelect: (country: CountryCode) => void;
  className?: string;
  disabled?: boolean;
}

// This is a subset of countries. You can expand this list as needed.
const countries: CountryCode[] = [
  { code: 'IN', name: 'India', dialCode: '+91', flag: '🇮🇳' },
  { code: 'US', name: 'United States', dialCode: '+1', flag: '🇺🇸' },
  { code: 'GB', name: 'United Kingdom', dialCode: '+44', flag: '🇬🇧' },
  { code: 'CA', name: 'Canada', dialCode: '+1', flag: '🇨🇦' },
  { code: 'AU', name: 'Australia', dialCode: '+61', flag: '🇦🇺' },
  { code: 'SG', name: 'Singapore', dialCode: '+65', flag: '🇸🇬' },
  { code: 'AE', name: 'United Arab Emirates', dialCode: '+971', flag: '🇦🇪' },
  // Add more countries as needed
];

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  selectedCountry,
  onSelect,
  className = '',
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  
  // Position dropdown based on screen size
  const [dropdownPosition, setDropdownPosition] = useState<'bottom' | 'fullscreen'>('bottom');
  
  useEffect(() => {
    const handleResize = () => {
      setDropdownPosition(window.innerWidth < 640 ? 'fullscreen' : 'bottom');
    };
    
    handleResize(); // Set initial position
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  
  // Focus search input when dropdown opens
  useEffect(() => {
    if (isOpen && searchInputRef.current) {
      // Delay focus for iOS compatibility
      setTimeout(() => {
        searchInputRef.current?.focus();
      }, 100);
    }
  }, [isOpen]);

  const filteredCountries = countries.filter(country =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    country.dialCode.includes(searchQuery) ||
    country.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
      // Reset search when opening dropdown
      if (!isOpen) {
        setSearchQuery('');
      }
    }
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <button
        type="button"
        className={`flex items-center gap-1 sm:gap-2 px-2 sm:px-3 py-3 bg-black/50 border border-gray-800 rounded-lg text-white hover:border-gray-700 focus:outline-none focus:ring-2 focus:ring-purple-500 ${
          disabled ? 'opacity-70 cursor-not-allowed' : ''
        }`}
        onClick={toggleDropdown}
        disabled={disabled}
        aria-label="Select country code"
        aria-expanded={isOpen}
      >
        <span className="text-base sm:text-xl">{selectedCountry.flag}</span>
        <span className="text-xs sm:text-sm font-medium">{selectedCountry.dialCode}</span>
        <svg
          className={`w-3 h-3 sm:w-4 sm:h-4 transition-transform ${isOpen ? 'rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && !disabled && (
        <div 
          className={`
            z-50 bg-black border border-gray-800 rounded-lg shadow-lg
            ${dropdownPosition === 'fullscreen' 
              ? 'fixed inset-0 flex flex-col p-4' 
              : 'absolute mt-1 w-52 sm:w-64'}
          `}
        >
          {dropdownPosition === 'fullscreen' && (
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-white font-medium">Select Country</h3>
              <button 
                onClick={() => setIsOpen(false)}
                className="p-1 rounded-full hover:bg-gray-800"
                aria-label="Close country selector"
              >
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          )}
          
          <div className="p-2">
            <input
              ref={searchInputRef}
              type="text"
              className="w-full px-3 py-2 bg-black/50 border border-gray-800 rounded-md text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
              placeholder="Search countries..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              aria-label="Search countries"
            />
          </div>
          
          <div className={`
            overflow-y-auto
            ${dropdownPosition === 'fullscreen' ? 'flex-grow' : 'max-h-60'}
          `}>
            {filteredCountries.length === 0 ? (
              <p className="text-center text-gray-400 py-4">No countries found</p>
            ) : (
              filteredCountries.map((country) => (
                <button
                  key={country.code}
                  type="button"
                  className={`w-full flex items-center gap-3 px-4 py-3 hover:bg-gray-800 ${
                    selectedCountry.code === country.code ? 'bg-gray-800' : ''
                  }`}
                  onClick={() => {
                    onSelect(country);
                    setIsOpen(false);
                  }}
                >
                  <span className="text-xl">{country.flag}</span>
                  <span className="text-white text-sm sm:text-base">{country.name}</span>
                  <span className="text-gray-400 ml-auto text-sm">{country.dialCode}</span>
                </button>
              ))
            )}
          </div>
          
          {dropdownPosition === 'fullscreen' && (
            <div className="mt-4">
              <button
                onClick={() => setIsOpen(false)}
                className="w-full bg-gray-800 text-white font-medium py-3 rounded-lg"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const detectCountry = async (): Promise<CountryCode> => {
  try {
    const response = await fetch('https://ipapi.co/json/');
    const data = await response.json();
    const country = countries.find(c => c.code === data.country_code);
    return country || countries[0]; // Default to first country if not found
  } catch (error) {
    console.error('Error detecting country:', error);
    return countries[0]; // Default to first country on error
  }
}; 