import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar';
import { SEO } from '../seo';
import { MAIN_ROUTES } from "../../constants/routes";
import dashboardImage from '../../assets/platform/dashboard.png';

interface AuthLayoutProps {
  children: React.ReactNode;
  title: string;
  subtitle: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children, title, subtitle }) => {
  // State to track screen size
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  
  // Update isMobile state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  return (
    <div className="min-h-screen bg-black text-white flex flex-col">
      <SEO 
        title={`${title} | Valuefy`}
        description={subtitle}
      />
      <Navbar />
      
      <main className="flex-grow flex flex-col lg:flex-row items-stretch">
        {/* Left side with form */}
        <div className="w-full lg:w-1/2 flex items-center justify-center py-12 px-4 relative z-10">
          <div className="w-full max-w-md">
            <div className="bg-black/50 backdrop-blur-sm rounded-lg p-8 border border-gray-800 shadow-xl">
              <h1 className="text-3xl sm:text-4xl font-bold mb-2">{title}</h1>
              <p className="text-lg text-gray-300 mb-6">{subtitle}</p>
              {children}
            </div>
          </div>
        </div>
        
        {/* Right side with dashboard image (hidden on mobile) */}
        <div className={`w-full lg:w-1/2 bg-gradient-to-br from-purple-900/20 via-black to-black ${isMobile ? 'hidden' : 'flex'} items-center justify-center p-8`}>
          <div className="max-w-2xl w-full">
            <div className="relative rounded-lg overflow-hidden shadow-2xl border border-gray-800">
              <img
                src={dashboardImage}
                alt="Valuefy Dashboard"
                className="w-full h-auto object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/70"></div>
              
              {/* Content overlay */}
              <div className="absolute bottom-0 left-0 right-0 p-6 text-white">
                <h2 className="text-2xl font-bold mb-2">Powerful Wealth Management Dashboard</h2>
                <p className="text-gray-300">Gain complete control over your investments with our comprehensive dashboard view</p>
              </div>
            </div>
            
            {/* Additional features highlights */}
            <div className="mt-8 grid grid-cols-2 gap-4">
              <div className="bg-purple-900/20 p-4 rounded-lg border border-purple-800/30">
                <h3 className="font-semibold mb-1">Real-time Analytics</h3>
                <p className="text-sm text-gray-400">Monitor your portfolio performance with live updates</p>
              </div>
              <div className="bg-purple-900/20 p-4 rounded-lg border border-purple-800/30">
                <h3 className="font-semibold mb-1">Secure Access</h3>
                <p className="text-sm text-gray-400">Enterprise-grade security for your financial data</p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default AuthLayout; 