import React from 'react';
import * as Sentry from '@sentry/react';

/**
 * Custom error fallback component that displays when an error occurs
 */
const ErrorFallback = (props: { error: any; resetError: () => void }) => {
  const { error, resetError } = props;
  
  return (
    <div className="min-h-[50vh] flex flex-col items-center justify-center p-6 bg-black text-white">
      <div className="max-w-md w-full bg-gray-900 rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold mb-4 text-red-400">Something went wrong</h2>
        <div className="bg-gray-800 p-4 rounded-md mb-4 overflow-auto max-h-40">
          <p className="font-mono text-sm text-gray-300">
            {error.toString()}
          </p>
        </div>
        <p className="mb-6 text-gray-400">
          Our team has been notified of this issue and we're working to fix it.
        </p>
        <div className="flex flex-col space-y-3">
          <button
            onClick={resetError}
            className="w-full py-2 px-4 bg-purple-600 hover:bg-purple-700 text-white font-medium rounded-md transition duration-200"
          >
            Try again
          </button>
          <button
            onClick={() => window.location.href = '/'}
            className="w-full py-2 px-4 bg-transparent border border-purple-600 hover:bg-purple-900 text-white font-medium rounded-md transition duration-200"
          >
            Go to homepage
          </button>
        </div>
      </div>
    </div>
  );
};

/**
 * Sentry error boundary that wraps components to catch errors
 */
export const SentryErrorBoundary: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary 
      fallback={(props) => <ErrorFallback error={props.error} resetError={props.resetError} />}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary; 