import React from 'react';
import { Outlet } from 'react-router-dom';
import { RouteSEO } from '../components/seo';
import Footer from '../components/Footer';

export const RootLayout: React.FC = () => {
  return (
    <div className="min-h-screen">
      <RouteSEO />
      <Outlet />
      <Footer />
    </div>
  );
}; 