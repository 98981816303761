import React from 'react';
import Navbar from './Navbar';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTES } from '../constants/routes';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-black">
      <Navbar />
      <div className="container mx-auto px-4 py-20 text-center text-white">
        <h1 className="text-6xl md:text-8xl font-bold text-purple-500 mb-6">404</h1>
        <h2 className="text-2xl md:text-4xl font-semibold mb-4">Page Not Found</h2>
        <p className="text-gray-400 mb-8 max-w-lg mx-auto">The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <button 
          onClick={() => navigate(MAIN_ROUTES.HOME.path)} 
          className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
        >
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound; 