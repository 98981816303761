import icon1 from '../assets/family-office/icon1.png';
import icon2 from '../assets/family-office/icon2.png';
import icon3 from '../assets/family-office/icon3.png';
import icon4 from '../assets/family-office/icon4.png';
import icon5 from '../assets/family-office/icon5.png';
import aggregateI from '../assets/family-office/aggregateF.png';
import analyticsI from '../assets/family-office/analyticsF.png';
import advisoryI from '../assets/family-office/advisoryF.png';
import transactionI from '../assets/family-office/transactionsF.png';
import engagementI from '../assets/family-office/engagementF.png';

export interface FeatureItem {
  title: string;
  description: string;
}

export interface FeatureSection {
  id: string;
  icon: string;
  title: string;
  subtitle: string;
  description: string;
  image: string;
  features: FeatureItem[];
}

export const FAMILY_OFFICE_FEATURES: FeatureSection[] = [
  {
    id: 'aggregate',
    icon: icon1,
    title: 'Aggregate',
    subtitle: 'Every Asset, Every Entity, One View.',
    description: 'Family wealth isn\'t simple. It spans across multiple asset classes, investment vehicles, currencies, and geographies',
    image: aggregateI,
    features: [
      {
        title: 'Global Data Aggregation',
        description: 'Automatically consolidate holdings across banks, private equity, real estate, and more.'
      },
      {
        title: 'Multi-Entity Structure Management',
        description: 'Get a clear, structured view of wealth across family members, trusts, and corporate entities.'
      },
      {
        title: 'Alternative Investment Tracking',
        description: 'Seamlessly monitor private markets, direct investments, and venture capital portfolios.'
      }
    ]
  },
  {
    id: 'analytics',
    icon: icon2,
    title: 'Analytics',
    subtitle: 'Smarter Decisions with AI-Driven Insights.',
    description: 'Wealth isn\'t just about accumulation. It\'s about intelligent growth, risk mitigation, and proactive decision-making.',
    image: analyticsI,
    features: [
      {
        title: 'Portfolio & Risk Analytics',
        description: 'Track performance across public and private investments in real time.'
      },
      {
        title: 'Scenario Planning & Forecasting',
        description: 'Model different outcomes before making investment decisions.'
      },
      {
        title: 'Estate & Succession Planning',
        description: 'Visualize generational wealth transfer and tax efficiency strategies.'
      }
    ]
  },
  {
    id: 'advisory',
    icon: icon3,
    title: 'Advisory',
    subtitle: 'Scale, Optimize & Personalize Investment Strategies.',
    description: 'Wealth management shouldn\'t be reactive. Family offices need a system that\'s predictive and proactive.',
    image: advisoryI,
    features: [
      {
        title: 'Model Portfolio Management',
        description: 'Apply custom investment strategies across multiple family portfolios.'
      },
      {
        title: 'Tax-Efficient Rebalancing',
        description: 'Optimize holdings while minimizing tax impact'
      },
      {
        title: 'Collaboration Across Advisors',
        description: 'Connect seamlessly with wealth managers, investment teams, and family members.'
      }
    ]
  },
  {
    id: 'transactions',
    icon: icon4,
    title: 'Transactions',
    subtitle: 'Secure, Compliant, and Effortless.',
    description: 'Managing transactions across entities and accounts shouldn\'t be complex. Now, it\'s seamless.',
    image: transactionI,
    features: [
      {
        title: 'Automated Order Execution',
        description: 'Buy, sell, and allocate assets effortlessly.'
      },
      {
        title: 'Pre-Trade Compliance Checks',
        description: 'Ensure every transaction meets regulatory and internal guidelines.'
      },
      {
        title: 'Revenue & Fee Tracking',
        description: 'Complete visibility over wealth distributions and advisory fees.'
      }
    ]
  },
  {
    id: 'engagement',
    icon: icon5,
    title: 'Engagement',
    subtitle: 'A Secure, Personalized, and Transparent Experience.',
    description: 'Wealth Family offices require both security and accessibility. Valuefy delivers both. shouldn\'t be reactive. Family offices need a system that\'s predictive and proactive.',
    image: engagementI,
    features: [
      {
        title: 'Military-Grade Security & User-Controlled Access',
        description: 'Keep financial data 100% private.'
      },
      {
        title: 'Mobile Access for Real-Time Decisions',
        description: 'Manage portfolios anywhere, anytime.'
      },
      {
        title: 'Customizable White-Labeled Reports',
        description: 'Present insights in a format that fits the family\'s needs.'
      }
    ]
  }
]; 