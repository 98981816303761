import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import valuefyLogo from "../assets/company/white-logo.png";
import UP from "../assets/background/up.png";
import DOWN from "../assets/background/down.png";
import CardBg from "../assets/background/nav-card-bg.png";
import Mail from "../assets/background/mail.png";
import { auth, phoneAuth } from "../utils/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { MAIN_ROUTES } from "../constants/routes";
import { 
  NAVBAR_ITEMS, 
  AUTH_ITEMS, 
  SOLUTION_ITEMS, 
  CONTACT_CARD,
  MOBILE_MENU,
  isPathActive,
  isSolutionActive,
  isPlatformActive
} from "../constants/navbar";

const Navbar: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState({
    solutions: false,
    platform: false,
  });

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [mobileDropdowns, setMobileDropdowns] = useState({
    solutions: false,
    platform: false,
  });
  const [currentUser, setCurrentUser] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  // Listen for auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(!!user);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Function to check if a path is active
  const isActive = (path: string): boolean => {
    return isPathActive(location.pathname, path);
  };

  // Function to check if a solution path is active
  const isSolutionPathActive = (): boolean => {
    return isSolutionActive(location.pathname);
  };

  // Function to check if platform path is active
  const isPlatformPathActive = (): boolean => {
    return isPlatformActive(location.pathname);
  };

  // Toggle mobile dropdowns
  const toggleMobileDropdown = (dropdown: "solutions" | "platform") => {
    setMobileDropdowns({
      ...mobileDropdowns,
      [dropdown]: !mobileDropdowns[dropdown],
    });
  };

  // Add refs for the dropdown containers
  const solutionsRef = useRef<HTMLDivElement>(null);
  const platformRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = (dropdown: "solutions" | "platform") => {
    setIsDropdownOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const handleSignOut = async () => {
    try {
      await phoneAuth.signOut();
      // No need to navigate, onAuthStateChanged will handle state update
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  // Add click outside handler
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // Close solutions dropdown if click is outside of it
      if (
        isDropdownOpen.solutions &&
        solutionsRef.current &&
        !solutionsRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen((prev) => ({
          ...prev,
          solutions: false,
        }));
      }

      // Close platform dropdown if click is outside of it
      if (
        isDropdownOpen.platform &&
        platformRef.current &&
        !platformRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen((prev) => ({
          ...prev,
          platform: false,
        }));
      }
    }

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  return (
    <nav className="bg-black px-6 xl:px-[100px] lg:py-[28px] py-3 w-full">
      <div className="flex items-center justify-between max-w-7xl mx-auto">
        {/* Logo and menu items */}
        <div className="flex items-center">
          {/* Logo */}
          <div className="text-white text-2xl z-10 font-semibold">
            <img
              src={valuefyLogo}
              className="h-10 w-30 cursor-pointer"
              onClick={() => handleNavigation(MAIN_ROUTES.HOME.path)}
            />
          </div>
          {/* Navigation Links */}
          <div className="hidden lg:flex items-center gap-3 xl:ml-28 mt-2 lg:ml-6">
            {/* Solutions Dropdown */}
            <div className="relative xl:px-8 lg:px-2" ref={solutionsRef}>
              <button
                className={`flex items-center xl:gap-2 lg:gap-1 focus:outline-none ${
                  isSolutionPathActive() ? "text-purple-600" : "text-white"
                }`}
                onClick={() => toggleDropdown("solutions")}
              >
                <span>{MOBILE_MENU.SOLUTIONS_LABEL}</span>
                <span className="text-xs ml-0.5">
                  {isDropdownOpen.solutions ? (
                    <img src={UP} alt="Arrow Up" className="h-4 w-4" />
                  ) : (
                    <img src={DOWN} alt="Arrow Down" className="h-4 w-4" />
                  )}
                </span>
              </button>

              {isDropdownOpen.solutions && (
                <div className="absolute top-full xl:left-4 lg:left-2 mt-4 w-[800px] z-50 bg-gray-100/80 backdrop-blur-md rounded-lg shadow-lg border border-gray-200/30">
                  <div className="flex">
                    {/* Column 1: Wealth Managers, RM & Advisors, Compliance Officers */}
                    <div className="w-1/3 p-6">
                      {/* Wealth Managers */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded mb-4 cursor-pointer ${
                          isActive(SOLUTION_ITEMS.WEALTH_MANAGER.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.WEALTH_MANAGER.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.WEALTH_MANAGER.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.WEALTH_MANAGER.description}
                        </p>
                      </div>

                      {/* RM & Advisors */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded mb-4 cursor-pointer ${
                          isActive(SOLUTION_ITEMS.RELATIONSHIP_MANAGER.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.RELATIONSHIP_MANAGER.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.RELATIONSHIP_MANAGER.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.RELATIONSHIP_MANAGER.description}
                        </p>
                      </div>

                      {/* Compliance Officers */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded cursor-pointer ${
                          isActive(SOLUTION_ITEMS.COMPLIANCE_OFFICER.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.COMPLIANCE_OFFICER.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.COMPLIANCE_OFFICER.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.COMPLIANCE_OFFICER.description}
                        </p>
                      </div>
                    </div>

                    {/* Column 2: Operations Teams and CIOs */}
                    <div className="w-1/3 p-6">
                      {/* Operations Teams */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded mb-4 cursor-pointer ${
                          isActive(SOLUTION_ITEMS.OPERATION_TEAM.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.OPERATION_TEAM.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.OPERATION_TEAM.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.OPERATION_TEAM.description}
                        </p>
                      </div>

                      {/* CIOs */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded cursor-pointer ${
                          isActive(SOLUTION_ITEMS.CIO.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.CIO.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.CIO.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.CIO.description}
                        </p>
                      </div>
                    </div>

                    {/* Vertical Separator Line */}
                    <div className="w-px bg-gray-300/70 h-auto my-6"></div>

                    {/* Column 3: Family Offices and Custom Solution */}
                    <div className="w-1/3 p-6">
                      {/* Family Offices */}
                      <div
                        className={`hover:bg-gray-200/90 p-4 rounded mb-4 cursor-pointer ${
                          isActive(SOLUTION_ITEMS.FAMILY_OFFICE.path) ? "bg-gray-200/90" : ""
                        }`}
                        onClick={() => handleNavigation(SOLUTION_ITEMS.FAMILY_OFFICE.path)}
                      >
                        <h3 className="font-bold text-gray-900 mb-2">
                          {SOLUTION_ITEMS.FAMILY_OFFICE.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {SOLUTION_ITEMS.FAMILY_OFFICE.description}
                        </p>
                      </div>

                      {/* Custom Solution */}
                      <div className="bg-gray-200/80 p-4 rounded relative">
                        <img
                          src={CardBg}
                          alt="Decorative"
                          className="absolute bottom-0 right-0 h-20 object-contain"
                        />
                        <h3 className="font-bold text-gray-900 mb-2">
                          {CONTACT_CARD.title}
                        </h3>
                        <p className="text-sm text-gray-700">
                          {CONTACT_CARD.description}
                        </p>
                        <button
                          onClick={() => handleNavigation(NAVBAR_ITEMS.CONTACT.path)}
                          className="mt-2 bg-black text-white px-4 py-2 rounded flex items-center text-sm z-10"
                        >
                          <img src={Mail} alt="icon" className="h-4 w-4 mr-2" />
                          Contact Us
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Platform & Features Dropdown */}
            <div className="relative" ref={platformRef}>
              <button
                className={`flex items-center space-x-1 focus:outline-none ${
                  isPlatformPathActive() ? "text-purple-500" : "text-white"
                }`}
                onClick={() => {
                  toggleDropdown("platform");
                  handleNavigation(NAVBAR_ITEMS.PLATFORM.path);
                }}
              >
                <span>{NAVBAR_ITEMS.PLATFORM.label}</span>
              </button>
            </div>

            {/* Regular Links */}
            <a
              onClick={() => handleNavigation(NAVBAR_ITEMS.BLOG.path)}
              className={`hover:opacity-80 transition-opacity cursor-pointer xl:px-4 lg:px-2 ${
                isActive(NAVBAR_ITEMS.BLOG.path) ? "text-purple-400" : "text-white"
              }`}
            >
              {NAVBAR_ITEMS.BLOG.label}
            </a>
            <button
              onClick={() => handleNavigation(NAVBAR_ITEMS.ABOUT.path)}
              className={`hover:opacity-80 transition-opacity cursor-pointer xl:px-4 lg:px-2 ${
                isActive(NAVBAR_ITEMS.ABOUT.path)
                  ? "text-purple-400"
                  : "text-white"
              }`}
            >
              {NAVBAR_ITEMS.ABOUT.label}
            </button>
            <button
              onClick={() => handleNavigation(NAVBAR_ITEMS.CONTACT.path)}
              className={`hover:opacity-80 transition-opacity cursor-pointer xl:px-4 lg:px-2 ${
                isActive(NAVBAR_ITEMS.CONTACT.path)
                  ? "text-purple-400"
                  : "text-white"
              }`}
            >
              {NAVBAR_ITEMS.CONTACT.label}
            </button>
            <a
              onClick={() => handleNavigation(NAVBAR_ITEMS.PRICING.path)}
              className={`hover:opacity-80 transition-opacity cursor-pointer xl:px-4 lg:px-2 ${
                isActive(NAVBAR_ITEMS.PRICING.path) ? "text-purple-400" : "text-white"
              }`}
            >
              {NAVBAR_ITEMS.PRICING.label}
            </a>
          </div>
        </div>

        {/* Mobile Menu Button and Auth Buttons (for small screens) */}
        <div className="flex items-center gap-3">
          {/* Auth Buttons - Mobile Version (only show login on small screens) */}
          {currentUser ? (
            <button
              className="md:hidden text-white text-sm font-medium border border-white rounded px-3 py-1"
              onClick={handleSignOut}
            >
              {AUTH_ITEMS.SIGN_OUT.label}
            </button>
          ) : (
            <button
              className="md:hidden text-white text-sm font-medium border border-white rounded px-3 py-1"
              onClick={() => handleNavigation(AUTH_ITEMS.SIGN_IN.path)}
            >
              {AUTH_ITEMS.SIGN_IN.label}
            </button>
          )}
          
          {/* Mobile Menu Button */}
          <button
            className="md:hidden text-white text-2xl"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            &#9776;
          </button>
        </div>

        {/* Auth Buttons - Desktop Version */}
        <div className="hidden md:flex items-center gap-3">
          {currentUser ? (
            <button
              className="px-5 py-2 rounded text-white bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] hover:opacity-90 transition-opacity text-sm font-medium"
              onClick={handleSignOut}
            >
              {AUTH_ITEMS.SIGN_OUT.label}
            </button>
          ) : (
            <>
              <button
                className="px-5 py-2 rounded border border-white text-white bg-black hover:opacity-90 transition-opacity text-sm font-medium"
                onClick={() => handleNavigation(AUTH_ITEMS.SIGN_IN.path)}
              >
                {AUTH_ITEMS.SIGN_IN.label}
              </button>
              <button
                className="px-5 py-2 rounded text-white bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] hover:opacity-90 transition-opacity text-sm font-medium"
                onClick={() => handleNavigation(AUTH_ITEMS.SIGN_UP.path)}
              >
                {AUTH_ITEMS.SIGN_UP.label}
              </button>
            </>
          )}
        </div>
      </div>
      
      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden fixed inset-0 bg-black/90 backdrop-blur-md z-50 overflow-y-auto pt-20">
          <div className="px-6 py-4">
            {/* Close Button */}
            <button
              className="absolute top-4 right-6 text-white text-2xl"
              onClick={() => setIsMobileMenuOpen(false)}
            >
              &times;
            </button>

            {/* Mobile Menu Items */}
            <div className="flex flex-col space-y-6">
              {/* Solutions for */}
              <div className="text-white">
                <div
                  className={`flex items-center justify-between py-2 cursor-pointer ${
                    isSolutionPathActive() ? "text-purple-400" : ""
                  }`}
                  onClick={() => toggleMobileDropdown("solutions")}
                >
                  <span className="text-lg font-medium">{MOBILE_MENU.SOLUTIONS_LABEL}</span>
                  <span>
                    {mobileDropdowns.solutions ? (
                      <img src={UP} alt="Arrow Up" className="h-4 w-4" />
                    ) : (
                      <img src={DOWN} alt="Arrow Down" className="h-4 w-4" />
                    )}
                  </span>
                </div>

                {mobileDropdowns.solutions && (
                  <div className="pl-4 space-y-3 mt-2">
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.WEALTH_MANAGER.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className={`block text-gray-300 hover:text-white py-1 cursor-pointer ${
                        isActive(SOLUTION_ITEMS.WEALTH_MANAGER.path) ? "text-purple-400" : ""
                      }`}
                    >
                      {SOLUTION_ITEMS.WEALTH_MANAGER.title}
                    </a>
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.RELATIONSHIP_MANAGER.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block text-gray-300 hover:text-white py-1 cursor-pointer"
                    >
                      {SOLUTION_ITEMS.RELATIONSHIP_MANAGER.title}
                    </a>
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.COMPLIANCE_OFFICER.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block text-gray-300 hover:text-white py-1 cursor-pointer"
                    >
                      {SOLUTION_ITEMS.COMPLIANCE_OFFICER.title}
                    </a>
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.OPERATION_TEAM.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block text-gray-300 hover:text-white py-1 cursor-pointer"
                    >
                      {SOLUTION_ITEMS.OPERATION_TEAM.title}
                    </a>
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.CIO.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block text-gray-300 hover:text-white py-1 cursor-pointer"
                    >
                      {SOLUTION_ITEMS.CIO.title}
                    </a>
                    <a
                      onClick={() => {
                        handleNavigation(SOLUTION_ITEMS.FAMILY_OFFICE.path);
                        setIsMobileMenuOpen(false);
                      }}
                      className="block text-gray-300 hover:text-white py-1 cursor-pointer"
                    >
                      {SOLUTION_ITEMS.FAMILY_OFFICE.title}
                    </a>
                  </div>
                )}
              </div>

              {/* Platform and Features */}
              <div className="text-white">
                <div
                  className={`flex items-center justify-between py-2 ${
                    isPlatformPathActive() ? "text-purple-400" : ""
                  }`}
                  onClick={() => {
                    handleNavigation(NAVBAR_ITEMS.PLATFORM.path);
                    setIsMobileMenuOpen(false);
                  }}
                >
                  <span className="text-lg font-medium">
                    {MOBILE_MENU.PLATFORM_LABEL}
                  </span>
                </div>
              </div>

              {/* Regular Links */}
              <a
                onClick={() => {
                  handleNavigation(NAVBAR_ITEMS.BLOG.path);
                  setIsMobileMenuOpen(false);
                }}
                className={`text-lg font-medium text-white py-2 cursor-pointer ${
                  isActive(NAVBAR_ITEMS.BLOG.path) ? "text-purple-400" : ""
                }`}
              >
                {NAVBAR_ITEMS.BLOG.label}
              </a>
              <a
                onClick={() => {
                  handleNavigation(NAVBAR_ITEMS.ABOUT.path);
                  setIsMobileMenuOpen(false);
                }}
                className={`text-lg font-medium text-white py-2 cursor-pointer ${
                  isActive(NAVBAR_ITEMS.ABOUT.path) ? "text-purple-400" : ""
                }`}
              >
                {NAVBAR_ITEMS.ABOUT.label}
              </a>
              <a
                onClick={() => {
                  handleNavigation(NAVBAR_ITEMS.CONTACT.path);
                  setIsMobileMenuOpen(false);
                }}
                className={`text-lg font-medium text-white py-2 cursor-pointer ${
                  isActive(NAVBAR_ITEMS.CONTACT.path) ? "text-purple-400" : ""
                }`}
              >
                {NAVBAR_ITEMS.CONTACT.label}
              </a>
              <a
                onClick={() => {
                  handleNavigation(NAVBAR_ITEMS.PRICING.path);
                  setIsMobileMenuOpen(false);
                }}
                className={`text-lg font-medium text-white py-2 cursor-pointer ${
                  isActive(NAVBAR_ITEMS.PRICING.path) ? "text-purple-400" : ""
                }`}
              >
                {NAVBAR_ITEMS.PRICING.label}
              </a>
              
              {/* Auth Buttons for Mobile Menu */}
              {!currentUser && (
                <>
                  <button
                    onClick={() => {
                      handleNavigation(AUTH_ITEMS.SIGN_IN.path);
                      setIsMobileMenuOpen(false);
                    }}
                    className="mt-4 bg-black border border-white text-white px-6 py-3 rounded hover:opacity-90 transition-opacity font-medium w-full"
                  >
                    {AUTH_ITEMS.SIGN_IN.label}
                  </button>
                  <button
                    onClick={() => {
                      handleNavigation(AUTH_ITEMS.SIGN_UP.path);
                      setIsMobileMenuOpen(false);
                    }}
                    className="mt-2 bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-6 py-3 rounded hover:opacity-90 transition-opacity font-medium w-full"
                  >
                    {AUTH_ITEMS.SIGN_UP.label}
                  </button>
                </>
              )}
              
              {currentUser && (
                <button
                  onClick={() => {
                    handleSignOut();
                    setIsMobileMenuOpen(false);
                  }}
                  className="mt-4 bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-6 py-3 rounded hover:opacity-90 transition-opacity font-medium w-full"
                >
                  {AUTH_ITEMS.SIGN_OUT.label}
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
