export interface RouteMetadata {
  path: string;
  title: string;
  description: string;
  keywords?: string;
  ogImage?: string;
  ogType?: string;
}

// Main routes
export const MAIN_ROUTES = {
  HOME: {
    path: '/',
    metadata: {
      path: '/',
      title: 'Valuefy | Wealth Management Platform',
      description: 'Valuefy is a comprehensive wealth management platform to help you manage and grow your investments.',
      keywords: 'valuefy, wealth management, investment portfolio, finance technology',
      ogType: 'website',
      ogImage: '/og-image.jpg'
    }
  },
  ABOUT: {
    path: '/about',
    metadata: {
      path: '/about',
      title: 'About Valuefy',
      description: 'Learn about Valuefy, our mission, vision, and the team behind our wealth management platform.',
      keywords: 'about valuefy, company mission, wealth management technology, fintech company'
    }
  },
  CONTACT: {
    path: '/contact',
    metadata: {
      path: '/contact',
      title: 'Contact Us',
      description: 'Get in touch with Valuefy team for inquiries, demos, or support.',
      keywords: 'contact valuefy, support, demo request, inquiries'
    }
  },
  BLOGS: {
    path: '/blogs',
    metadata: {
      path: '/blogs',
      title: 'Valuefy Blog',
      description: 'Latest insights, trends and news about wealth management, investment strategies, and financial technology.',
      keywords: 'wealth management blog, investment insights, financial technology news, fintech trends',
      ogType: 'blog'
    }
  },
  TEAM: {
    path: '/team',
    metadata: {
      path: '/team',
      title: 'Our Team | Valuefy',
      description: 'Meet the passionate team behind Valuefy. Our experts are dedicated to revolutionizing wealth management through innovative technology solutions.',
      keywords: 'Valuefy team, wealth management experts, fintech leadership, wealth tech professionals'
    }
  },
  PLATFORM: {
    path: '/platform-and-features',
    metadata: {
      path: '/platform-and-features',
      title: 'Platform & Features',
      description: 'Explore Valuefy platform capabilities and features that transform wealth management.',
      keywords: 'wealth management platform, investment features, portfolio analysis tools, financial dashboard'
    }
  },
  PRICING: {
    path: '/pricing',
    metadata: {
      path: '/pricing',
      title: 'Valuefy Pricing',
      description: 'Transparent pricing plans for Valuefy wealth management platform.',
      keywords: 'valuefy pricing, subscription plans, wealth management platform cost'
    }
  },
  SEE_LIVE: {
    path: '/signup',
    metadata: {
      path: '/signup',
      title: 'See Valuefy in Action',
      description: 'Experience our platform with a live demo and see how Valuefy can transform your wealth management workflow.',
      keywords: 'valuefy demo, live demo, wealth management platform, see in action'
    }
  },
  SIGNIN: {
    path: '/signin',
    metadata: {
      path: '/signin',
      title: 'Sign In | Valuefy',
      description: 'Sign in to your Valuefy account to access your wealth management dashboard and portfolio insights.',
      keywords: 'sign in, login, wealth management, portfolio access'
    }
  },
  SIGNUP: {
    path: '/signup',
    metadata: {
      path: '/signup',
      title: 'Create Account | Valuefy',
      description: 'Join Valuefy to start managing your wealth effectively with our comprehensive wealth management platform.',
      keywords: 'sign up, create account, wealth management platform, portfolio management'
    }
  }
};

// Solution routes
export const SOLUTION_ROUTES = {
  WEALTH_MANAGER: {
    path: '/solutions/wealth-manager',
    metadata: {
      path: '/solutions/wealth-manager',
      title: 'Wealth Manager Solutions',
      description: 'Powerful solutions for wealth managers to streamline client management and portfolio analysis.',
      keywords: 'wealth manager, portfolio management, client management, financial advisor tools'
    }
  },
  RELATIONSHIP_MANAGER: {
    path: '/solutions/relationship-manager',
    metadata: {
      path: '/solutions/relationship-manager',
      title: 'Relationship Manager Solutions',
      description: 'Relationship management tools to enhance client engagement and improve service delivery.',
      keywords: 'relationship manager, client engagement, client service, wealth management'
    }
  },
  COMPLIANCE_OFFICER: {
    path: '/solutions/compliance-officer',
    metadata: {
      path: '/solutions/compliance-officer',
      title: 'Compliance Officer Solutions',
      description: 'Compliance solutions that help financial professionals stay compliant with regulations.',
      keywords: 'compliance officer, regulatory compliance, financial regulations, risk management'
    }
  },
  OPERATION_TEAM: {
    path: '/solutions/operation-team',
    metadata: {
      path: '/solutions/operation-team',
      title: 'Operation Team Solutions',
      description: 'Operational tools to enhance efficiency and streamline wealth management workflows.',
      keywords: 'operations team, workflow optimization, process efficiency, wealth management operations'
    }
  },
  CIO: {
    path: '/solutions/cio',
    metadata: {
      path: '/solutions/cio',
      title: 'CIO Solutions',
      description: 'Strategic solutions for Chief Investment Officers to optimize investment decisions.',
      keywords: 'chief investment officer, investment strategy, portfolio optimization, asset allocation'
    }
  },
  FAMILY_OFFICE: {
    path: '/solutions/family-office',
    metadata: {
      path: '/solutions/family-office',
      title: 'Family Office Solutions',
      description: 'Comprehensive wealth management solutions designed specifically for family offices.',
      keywords: 'family office, wealth management, multi-generational wealth, family wealth'
    }
  }
};

// Legal routes
export const LEGAL_ROUTES = {
  TERMS: {
    path: '/terms',
    metadata: {
      path: '/terms',
      title: 'Terms of Service | Valuefy',
      description: 'Read the terms of service and conditions for using the Valuefy wealth management platform.',
      keywords: 'terms of service, terms and conditions, legal terms, user agreement'
    }
  },
  PRIVACY: {
    path: '/privacy',
    metadata: {
      path: '/privacy',
      title: 'Privacy Policy | Valuefy',
      description: 'Learn how Valuefy collects, uses, and protects your personal information.',
      keywords: 'privacy policy, data protection, personal information, confidentiality'
    }
  },
  PRIVACY_POLICY: {
    path: '/privacy-policy',
    metadata: {
      path: '/privacy-policy',
      title: 'Privacy Policy | Valuefy',
      description: 'Learn how Valuefy collects, uses, and protects your personal information.',
      keywords: 'privacy policy, data protection, personal information, confidentiality'
    }
  },
  COOKIE_POLICY: {
    path: '/cookie-policy',
    metadata: {
      path: '/cookie-policy',
      title: 'Cookie Policy | Valuefy',
      description: 'Information about how Valuefy uses cookies and similar technologies.',
      keywords: 'cookie policy, cookies, tracking technologies, privacy'
    }
  },
  DATA_PROTECTION: {
    path: '/data-protection',
    metadata: {
      path: '/data-protection',
      title: 'Data Protection | Valuefy',
      description: 'Learn about Valuefy\'s data protection practices and how we safeguard your information.',
      keywords: 'data protection, GDPR, data security, information security'
    }
  },
  USER_AGREEMENT: {
    path: '/user-agreement',
    metadata: {
      path: '/user-agreement',
      title: 'User Agreement | Valuefy',
      description: 'Details about the agreement between Valuefy and its users.',
      keywords: 'user agreement, terms of use, user rights, legal agreement'
    }
  },
  ACCESSIBILITY: {
    path: '/accessibility',
    metadata: {
      path: '/accessibility',
      title: 'Accessibility | Valuefy',
      description: 'Information about Valuefy\'s commitment to accessibility and inclusion.',
      keywords: 'accessibility, inclusion, web accessibility, WCAG compliance'
    }
  },
  INTELLECTUAL_PROPERTY: {
    path: '/intellectual-property',
    metadata: {
      path: '/intellectual-property',
      title: 'Intellectual Property | Valuefy',
      description: 'Information about Valuefy\'s intellectual property rights and policies.',
      keywords: 'intellectual property, copyright, trademark, IP rights'
    }
  }
};

// Support routes
export const SUPPORT_ROUTES = {
  FAQ: {
    path: '/faq',
    metadata: {
      path: '/faq',
      title: 'Frequently Asked Questions | Valuefy',
      description: 'Find answers to common questions about Valuefy and our wealth management platform.',
      keywords: 'FAQ, help, questions, answers, wealth management platform'
    }
  },
  SUPPORT: {
    path: '/support',
    metadata: {
      path: '/support',
      title: 'Support Center | Valuefy',
      description: 'Get help and support for Valuefy products and services.',
      keywords: 'customer support, help center, technical support, assistance'
    }
  },
  EMAIL_US: {
    path: '/email-us',
    metadata: {
      path: '/email-us',
      title: 'Email Support | Valuefy',
      description: 'Contact our support team via email for assistance with Valuefy products.',
      keywords: 'email support, contact support, assistance, help'
    }
  },
  CALL_US: {
    path: '/call-us',
    metadata: {
      path: '/call-us',
      title: 'Phone Support | Valuefy',
      description: 'Get in touch with our support team by phone for immediate assistance.',
      keywords: 'phone support, call support, direct assistance, help line'
    }
  },
  VISIT_US: {
    path: '/visit-us',
    metadata: {
      path: '/visit-us',
      title: 'Visit Valuefy | Office Locations',
      description: 'Find our office locations and visit us in person.',
      keywords: 'office locations, visit us, Valuefy office, company address'
    }
  },
  FEEDBACK: {
    path: '/feedback',
    metadata: {
      path: '/feedback',
      title: 'Provide Feedback | Valuefy',
      description: 'Share your feedback to help us improve our products and services.',
      keywords: 'feedback, suggestions, improvements, user experience'
    }
  },
  HELP_CENTER: {
    path: '/help-center',
    metadata: {
      path: '/help-center',
      title: 'Help Center | Valuefy',
      description: 'Comprehensive help and documentation for Valuefy products and services.',
      keywords: 'help center, support, documentation, guides, tutorials'
    }
  }
};

// Social media links
export const SOCIAL_LINKS = {
  LINKEDIN: 'https://in.linkedin.com/company/valuefy',
  TWITTER: 'https://x.com/Valuefy',
  FACEBOOK: 'https://www.facebook.com/valuefy/',
  YOUTUBE: 'https://www.youtube.com/@valuefy_official',
  WEBSITE: 'http://www.valuefy.com',
  EMAIL: 'contact@valuefy.com'
};

// Helper object for easy access to all routes and their paths
export const ROUTES = {
  ...Object.entries(MAIN_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
  SOLUTIONS: Object.entries(SOLUTION_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
  LEGAL: Object.entries(LEGAL_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
  SUPPORT: Object.entries(SUPPORT_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
  SOCIAL: SOCIAL_LINKS
};

// Helper function to get all route metadata objects
export const getAllRouteMetadata = (): { [path: string]: RouteMetadata } => {
  const allRoutes = [
    ...Object.values(MAIN_ROUTES),
    ...Object.values(SOLUTION_ROUTES),
    ...Object.values(LEGAL_ROUTES),
    ...Object.values(SUPPORT_ROUTES)
  ];
  
  return allRoutes.reduce((acc, route) => {
    return {
      ...acc,
      [route.path]: route.metadata
    };
  }, {});
}; 