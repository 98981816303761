import icon1 from '../assets/blog/icon1.png';
import icon2 from '../assets/blog/icon2.png';
import image1 from '../assets/blog/featured.png';
import blogIcon from '../assets/blog/Blog-Image-How-AI-is-Changing-the-Face-of-Portfolio.webp';
import blogIcon2 from '../assets/blog/Wealth-Advisor-Workbench-in-London.jpg'
import post30 from '../assets/blog/How-Wealth-Advisors-Can-Manage-Rising-Costs-in-UK.webp';
import post4 from '../assets/blog/post-4.jpg';
import post5_0 from '../assets/blog/post5-0.jpg';
import post6 from '../assets/blog/post6.jpg';
import post7 from '../assets/blog/post7.jpg';
import post8 from '../assets/blog/post8.jpg';
import post9 from '../assets/blog/post9.jpg';
import post10 from '../assets/blog/post10.webp';
import post11 from '../assets/blog/post11.webp';
import post12 from '../assets/blog/post12.webp';
import post13 from '../assets/blog/post13.webp';
import post14 from '../assets/blog/post14.webp';
import post15 from '../assets/blog/post15.webp';
import post16 from '../assets/blog/post16.webp';
import post17 from '../assets/blog/post17.webp';
import post18 from '../assets/blog/post18.webp';
import post19 from '../assets/blog/post19.webp';
import post20 from '../assets/blog/post20.webp';
import post21 from '../assets/blog/post21.webp';
// import postf1 from '../assets/blog/postf-1.jpg';



// Define blog post type
export interface BlogPost {
  id: string;
  title: string;
  categories: string[];  // Main categories (from your 10)
  tags?: string[];       // More specific subcategories/topics
  date: string;          // Required for time filtering
  description: string;
  imageUrl: string;
  url: string;
}

// Define blog categories for search suggestions

// Featured blog post
export const FEATURED_BLOG_POST = {
  id: 'wealth-management-fragmented-tech',
  title: "Wealth Management in a Fragmented Technology World: The Problem of Wealth Managers and How Valuefy is Solving it",
  imageUrl: image1,
  description: "Explore how Valuefy's seamless technology solution addresses the challenges of fragmented systems in wealth management.",
  articleUrl: "/blogs/wealth-management-fragmented-tech"
};

// Sample blog posts data
export const BLOG_POSTS: BlogPost[] = [
  {
    id: 'financial-moves-2025',
    title: '5 Top-End Financial Moves to Consider Before 2025',
    categories: ['Personal Finance', 'Wealth Management'],
    description: 'Our platform empowers wealth managers with comprehensive insight into client portfolios.',
    imageUrl: icon1,
    date: '2023-12-15',
    url: '/blogs/financial-moves-2025'
  },
  {
    id: 'market-reflections-2023',
    title: 'Q3 2023 Market Reflections: A Bull Market, the Fed, and Economic Stock Reactions',
    categories: ['Market Insights', 'Investment Strategy'],
    description: 'Our platform empowers wealth managers with comprehensive insight into client portfolios.',
    imageUrl: icon2,
    date: '2023-10-10',
    url: '/blogs/market-reflections-2023'
  },
  {
    id: 'ai-wealth-management',
    title: 'How AI is Transforming Wealth Management: The Future of Portfolio Management',
    categories: ['Wealth Technology', 'Investment Strategy', 'Industry Trends'],
    description: 'Discover how artificial intelligence is revolutionizing the wealth management industry through improved data analysis, automation, risk management, and customer service.',
    imageUrl: blogIcon,
    date: '2023-06-15',
    url: '/blogs/ai-wealth-management'
  },
  {
    id: 'wealth-advisor-workbench',
    title: 'Enhancing Client Engagement and Business Growth with a Wealth Advisor Workbench',
    categories: ['Wealth Management', 'Business Growth', 'Client Engagement', 'Wealth Technology'],
    description: 'Discover how a modern advisor workbench can help wealth managers increase efficiency, improve client service, and drive business growth.',
    imageUrl: blogIcon2,
    date: '2023-07-20',
    url: '/blogs/wealth-advisor-workbench'
  },
  {
    id: 'managing-rising-costs',
    title: 'How Wealth Advisors Can Manage Rising Costs, Digital Transformation and Client Expectations',
    categories: ['Wealth Management'],
    description: 'Discover how wealth advisors can leverage technology to overcome rising costs, meet client expectations, and achieve profitability and growth.',
    imageUrl: post30,
    date: '2023-05-12',
    url: '/blogs/managing-rising-costs'
  },
  {
    id: 'data-security-cloud',
    title: 'Is your Data Secure on the Cloud?',
    categories: ['Data Security'],
    description: 'Learn about the key security considerations for wealth managers storing sensitive client data in cloud environments.',
    imageUrl: post4,
    date: '2023-04-18',
    url: '/blogs/data-security-cloud'
  },
  {
    id: 'selecting-wealth-tech-provider',
    title: '8 Checkpoints for Selecting your Wealth Tech Provider',
    categories: ['Wealth Technology'],
    description: 'A comprehensive guide to evaluating and selecting the right wealth tech provider for your business needs.',
    imageUrl: post5_0,
    date: '2023-03-22',
    url: '/blogs/selecting-wealth-tech-provider'
  },
  {
    id: 'data-wealth-management',
    title: 'Fuelled by Data – Wealth Management\'s Tech Transformation',
    categories: ['Wealth Technology'],
    description: 'How big data and AI are revolutionizing wealth management through improved customer service, decision-making, accounting precision, and omnichannel capabilities.',
    imageUrl: post6,
    date: '2023-02-14',
    url: '/blogs/data-wealth-management'
  },
  {
    id: 'digitize-wealth-management',
    title: 'A Roadmap to Digitize your Wealth Management Practice',
    categories: ['Wealth Technology'],
    description: 'A practical 5-step guide to help traditional wealth management firms successfully navigate the digital transformation journey.',
    imageUrl: post7,
    date: '2023-01-30',
    url: '/blogs/digitize-wealth-management'
  },
  {
    id: 'mass-affluent-asians',
    title: '\'Mass Affluent\' Asians, the prime force shaping the Wealth Management Industry',
    categories: ['Market Insights'],
    description: 'Discover how the growing segment of "Mass Affluent" Asians is creating new opportunities for wealth management firms and how technology can help capture this market.',
    imageUrl: post8,
    date: '2022-12-05',
    url: '/blogs/mass-affluent-asians'
  },
  {
    id: 'hybrid-advisory-model',
    title: 'Hybrid Advisory Model: The Future of Wealth Management lies in Man-Machine Collaboration',
    categories: ['Wealth Technology'],
    description: 'Explore how the hybrid advisory model combines human expertise with AI capabilities to create more efficient, accessible, and personalized wealth management services.',
    imageUrl: post9,
    date: '2022-11-17',
    url: '/blogs/hybrid-advisory-model'
  },
  {
    id: 'new-normal-wealth-management',
    title: 'The New Normal in Wealth Management',
    categories: ['Wealth Management'],
    description: 'How COVID-19 has transformed wealth management and the key focus areas for success in the new landscape: digitalization, personalized advice, and risk management.',
    imageUrl: post10, 
    date: '2023-08-05',
    url: '/blogs/new-normal-wealth-management'
  },
  {
    id: 'new-clientele-wealth-management',
    title: 'The New Clientele: How Women and Millennials are shaping the future of the Wealth Management Industry',
    categories: ['Market Insights'],
    description: 'How the emerging client segments of millennials and women are transforming the wealth management industry with their unique preferences for technology, transparency, and personalized service.',
    imageUrl: post11,
    date: '2022-10-20',
    url: '/blogs/new-clientele-wealth-management'
  },
  {
    id: 'trends-wealth-management',
    title: '5 Trends Governing the Future of Wealth Management Industry',
    categories: ['Industry Trends'],
    description: 'Explore the five key trends shaping the future of wealth management: intensifying competition, demographic shifts, evolving client expectations, digital transformation, and regulatory changes.',
    imageUrl: post12,
    date: '2022-09-14',
    url: '/blogs/trends-wealth-management'
  },
  {
    id: 'valuefy-wealthtech100',
    title: 'Valuefy made it to the WealthTech100. Have you Heard it yet?',
    categories: ['Industry Trends'],
    description: `Valuefy celebrates its recognition as one of the world's most innovative WealthTech companies in the prestigious WealthTech100 list.`,
    imageUrl: post13,
    date: '2022-08-09',
    url: '/blogs/valuefy-wealthtech100'
  },
  {
    id: 'choosing-investment-management-solution',
    title: 'The Science and Art of Choosing the Right Investment Management Solution',
    categories: ['Wealth Technology'],
    description: 'Discover the key considerations for selecting the ideal investment management solution and how WealthFy addresses these needs through interactive portals, digital intelligence, and cloud technology.',
    imageUrl: post14,
    date: '2022-07-12',
    url: '/blogs/choosing-investment-management-solution'
  },
  {
    id: 'robo-advisor-investment-manager',
    title: 'Is Robo-Advisor a Good Investment Manager?',
    categories: ['Wealth Technology'],
    description: 'Explore the advantages and limitations of robo-advisors in wealth management and why the optimal approach combines automated technology with human expertise.',
    imageUrl: post15,
    date: '2022-06-21',
    url: '/blogs/robo-advisor-investment-manager'
  },
  {
    id: 'portfolio-analytics-for-all',
    title: 'Portfolio Analytics is not just for Wall Street Bankers',
    categories: ['Investment Strategy'],
    description: `How Valuefy's ValueAT makes powerful portfolio analytics accessible to all fund managers, enabling faster analysis and better investment decisions.`,
    imageUrl: post16,
    date: '2022-05-17',
    url: '/blogs/portfolio-analytics-for-all'
  },
  {
    id: 'wealth-management-mistakes',
    title: '7 Wealth Management Mistakes You Shouldn\'t Make',
    categories: ['Wealth Management'],
    description: `Avoid these common pitfalls in wealth management: from client selection to business processes, learn practical strategies to enhance your firm's efficiency and client relationships.`,
    imageUrl: post17,
    date: '2022-04-11',
    url: '/blogs/wealth-management-mistakes'
  },
  {
    id: 'wealth-management-outlook-2020',
    title: 'The Wealth Management Industry: Outlook 2020',
    categories: ['Industry Trends'],
    description: 'Explore the key trends shaping the wealth management industry in 2020, including the rise of FinTech, changing client interaction models, and increasing demand for personalized solutions.',
    imageUrl: post18,
    date: '2022-03-15',
    url: '/blogs/wealth-management-outlook-2020'
  },
  {
    id: 'technology-partner-wealth-management',
    title: '5 Reasons Wealth Managers need a Technology Partner',
    categories: ['Wealth Technology'],
    description: 'Discover why partnering with fintech companies is essential for wealth managers, from automation and data analytics to multichannel delivery and customization.',
    imageUrl: post19,
    date: '2022-02-22',
    url: '/blogs/technology-partner-wealth-management'
  },
  {
    id: 'hybrid-advisory-investment-management',
    title: 'Here\'s Why you Need Hybrid Advisory for Investment Management',
    categories: ['Wealth Technology'],
    description: 'Learn how combining human expertise with robo-advisory capabilities creates a powerful hybrid approach that improves decision-making and democratizes investment management.',
    imageUrl: post20,
    date: '2022-01-18',
    url: '/blogs/hybrid-advisory-investment-management'
  },
  {
    id: 'predictive-analytics-wealth-management',
    title: 'Predictive analytics in wealth management: The new normal',
    categories: ['Wealth Technology'],
    description: 'Discover how predictive analytics is revolutionizing wealth management through enhanced client acquisition, personalized service, intelligent research, and operational efficiencies.',
    imageUrl: post21,
    date: '2021-12-07',
    url: '/blogs/predictive-analytics-wealth-management'
  },
];

export const BLOG_CATEGORIES1 = Array.from(
  new Set(
    BLOG_POSTS.flatMap(post => post.categories)
  )
).sort();
export const BLOG_CATEGORIES = ['All Categories',...BLOG_CATEGORIES1];


