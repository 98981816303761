import React from 'react';

// You'll need to replace this with your actual image path
import heroImage from '../assets/about-us/climbing-silhouette.png';

interface AboutUsHeroProps {
  years?: number;
}

const AboutUsHero: React.FC<AboutUsHeroProps> = () => {
  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-6xl mx-auto px-4">
        {/* Heading with gradient text */}
        <div className="mb-12">
        <h1 className="text-[3rem] md:text-[4rem] lg:text-[5rem] font-semibold leading-[110%] md:leading-[120%] lg:leading-[140%]">
          Valuefy delivers <span className="text-purple-500">cutting-edge</span> <span className="text-purple-500">investment tech</span> for top financial institutions Globally.
        </h1>
        </div>

        
        {/* Image with overlay text */}
        <div className="relative rounded-lg overflow-hidden">
          <img 
            src={heroImage} 
            alt="Silhouettes of people climbing a hill at dusk" 
            // className="w-full h-auto"
            className="hidden lg:block w-full h-full object-cover"
          />
          {/* <div for responsive change to gradient text for mobile*/}
          <div
        className="lg:hidden w-full h-full flex items-center justify-center bg-gradient-to-r from-[rgba(69,42,124,0.10)] to-purple-1000 text-white text-xl font-inter p-6"
        >
        For over 15 years, we have been the driving force behind premier wealth management firms, enabling them to scale operations, enhance client engagement, and make smarter investment decisions.
        </div> 
        </div>
      </div>
    </div>
  );
};

export default AboutUsHero;