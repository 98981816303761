import aggregateI from '../assets/family-office/aggregateF.png';
import analyticsI from '../assets/family-office/analyticsF.png';
import advisoryI from '../assets/family-office/advisoryF.png';
import transactionI from '../assets/family-office/transactionsF.png';
import engagementI from '../assets/family-office/engagementF.png';
import grid from '../assets/wealth-manager/grid.png';
import icon1 from '../assets/family-office/icon1.png';
import icon2 from '../assets/family-office/icon2.png';
import icon3 from '../assets/family-office/icon3.png';
import icon4 from '../assets/family-office/icon4.png';
import icon5 from '../assets/family-office/icon5.png';
import { FAMILY_OFFICE_FEATURES } from '../constants/family-office';

export const FamilyOfficeList = () => {
  return (
    <div className="w-[100%] mx-auto text-white pt-20 bg-gradient-to-b from-[#000000] via-[#000000] to-[#140029] ">
      <div className="w-[85%] mx-auto">
        <div className="w-[80%] flex flex-col gap-4 text-center mx-auto leading-10">
          <div className="text-5xl font-bold mb-2">
            One Platform. Total Control.
          </div>
        
          <div className="mb-2 w-screen relative left-1/2 right-1/2 -mx-[50vw]">
            <img src={grid} alt="Valuefy Grid" className="w-full" />
          </div>
        </div>

        {FAMILY_OFFICE_FEATURES.map((section, index) => (
          <div key={section.id} className={`w-[90%] mx-auto ${index === 0 ? 'mt-8' : 'mt-20'} ${index === FAMILY_OFFICE_FEATURES.length - 1 ? 'pb-36' : 'mb-20'}`}>
            <div className="flex justify-between flex-col md:flex-row">
              {index % 2 === 0 ? (
                <>
                  <div className="w-[100%] md:w-[40%]">
                    <div className='w-[10%] mb-4'><img src={section.icon} alt={section.title} /></div>
                    <div className="text-3xl font-bold leading-normal mb-2">{section.title}</div>
                    <div className="text-xl font-bold leading-normal mb-2">{section.subtitle}</div>
                    <div className="leading-normal">{section.description}</div>
                    <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
                    <div className="h-[270px] overflow-y-auto scrollbar-left ">
                      {section.features.map((feature, idx) => (
                        <div key={idx} className="mb-4">
                          <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">{feature.title}</div>
                          <div className="text-[18px] text-[#94A3B8]">{feature.description}</div>
                        </div>
                      ))}
                      <div className="mb-4"></div>
                    </div>
                  </div>
                  <div className="w-[100%] md:w-[40%]">
                    <img src={section.image} alt={section.title} />   
                  </div>
                </>
              ) : (
                <>
                  <div className="w-[100%] md:w-[40%]">
                    <img src={section.image} alt={section.title} />   
                  </div>
                  <div className="w-[100%] md:w-[40%]">
                    <div className='w-[10%] mb-4'><img src={section.icon} alt={section.title} /></div>
                    <div className="text-3xl font-bold leading-normal mb-2">{section.title}</div>
                    <div className="text-xl font-bold leading-normal mb-2">{section.subtitle}</div>
                    <div className="leading-normal">{section.description}</div>
                    <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
                    <div className="h-[270px] overflow-y-auto scrollbar-left ">
                      {section.features.map((feature, idx) => (
                        <div key={idx} className="mb-4">
                          <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">{feature.title}</div>
                          <div className="text-[18px] text-[#94A3B8]">{feature.description}</div>
                        </div>
                      ))}
                      <div className="mb-4"></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FamilyOfficeList;
