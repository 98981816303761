import { checkIfUserIsSignedUp } from "@/utils/authService";
import bookDemo from "../assets/wealth-manager/book-demo.png";
import { useNavigate } from "react-router-dom";
const BookDemoRM = () => {
  const navigate = useNavigate();
  const handleClick = async () => {
    try {
      const isUserSignedUp = await checkIfUserIsSignedUp();
      
      if (isUserSignedUp) {
        // Use window.location for navigating to a different application
        window.location.href = "/demo/dashboard/";  // Note the trailing slash
      } else {
        // This stays as React Router navigation within the same app
        navigate("/signup");
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
      navigate("/signup");
    }
  };
  return (
    <section className="bg-black text-white py-16 px-6">
      <div className="max-w-6xl mx-auto">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Left side - Text and CTAs */}
          <div className="w-full md:w-1/2 mb-10 md:mb-0">
            <h1
              className="text-4xl md:text-5xl font-bold mb-2 font-inter bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent"
              style={{ lineHeight: "179%" }}
            >
              Be the RM
            </h1>
            <h2 className="text-4xl md:text-5xl font-bold mb-20 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">
              Your Clients Trust.
            </h2>

            {/* CTA Buttons */}
            <div className="space-y-4 max-w-xs">
              <button
                onClick={handleClick}
                className="w-full bg-white text-black font-medium py-3 px-6 rounded hover:bg-gray-200 transition-colors"
              >
                Book a Demo
              </button>

              <button className="w-full text-white flex items-center justify-center gap-2 font-medium py-3" onClick={handleClick}>
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z" />
                </svg>
                Talk to an Expert
              </button>
            </div>
          </div>

          {/* Right side - Image */}
          <div className="w-full md:w-1/2">
            <img
              src={bookDemo}
              alt="successful client meeting"
              className="w-full h-auto rounded-lg object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BookDemoRM;