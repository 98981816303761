import React, { useState } from 'react';

// Import the image - update path as needed
import conversationImage from '../assets/blog/newsletter.png';

const BlogNewsletterWithImage: React.FC = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your newsletter subscription logic here
    console.log('Newsletter subscription for:', email);
    // Reset form after submission
    setEmail('');
    // Add success message or API call
  };

  return (
    <div className="w-full bg-black text-white">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row">
          {/* Left side - Newsletter Form */}
          <div className="w-full md:w-1/2 p-8 md:p-16 flex flex-col justify-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">Stay in loop</h2>
            <p className="mb-1">Stay Ahead with Exclusive Insights</p>
            <p className="mb-6">Subscribe to our newsletter!</p>
            
            <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-3">
              <input
                type="email"
                placeholder="Enter your email address here"
                className="flex-grow px-4 py-2 bg-transparent border border-gray-700 rounded-md text-white focus:outline-none focus:border-blue-400"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button
                type="submit"
                className="px-6 py-2 bg-white text-black font-medium rounded-md hover:bg-gray-200 transition-colors"
              >
                Subscribe
              </button>
            </form>
          </div>
          
          {/* Right side - Image */}
          <div className="w-full md:w-1/2 bg-blue-500 relative">
            <img 
              src={conversationImage} 
              alt="Two women in conversation" 
              className="w-full h-full object-cover"
            />
            
            {/* Circular background pattern overlay */}
            <div className="absolute top-0 right-0 bottom-0 left-0 pointer-events-none">
              <svg 
                width="100%" 
                height="100%" 
                viewBox="0 0 200 200" 
                xmlns="http://www.w3.org/2000/svg"
                className="absolute top-0 right-0 w-64 h-64 text-white opacity-10"
                preserveAspectRatio="xMaxYMin meet"
              >
                <path
                  fill="currentColor"
                  d="M45,-65.9C58.2,-56.8,69,-43.4,75.6,-27.6C82.3,-11.9,84.7,6.2,81.7,23.4C78.6,40.6,70.2,56.9,57,66.5C43.8,76.1,25.8,78.8,8.5,77.9C-8.8,77,-25.5,72.4,-39.6,63.7C-53.8,55,-65.5,42.2,-72.2,26.8C-79,11.5,-80.8,-6.4,-76.2,-22.3C-71.6,-38.1,-60.7,-51.8,-47,-61.5C-33.3,-71.2,-16.7,-77,0.5,-77.7C17.7,-78.5,35.3,-74.3,45,-65.9Z"
                  transform="translate(100 100) scale(0.8)"
                />
              </svg>
              
              <svg 
                width="100%" 
                height="100%" 
                viewBox="0 0 200 200" 
                xmlns="http://www.w3.org/2000/svg"
                className="absolute bottom-0 left-0 w-64 h-64 text-white opacity-10"
                preserveAspectRatio="xMinYMax meet"
              >
                <path
                  fill="currentColor"
                  d="M38.5,-64.7C50.2,-56.9,60.5,-46.6,67.4,-33.8C74.2,-21.1,77.7,-5.8,76.8,9.9C76,25.6,70.8,41.7,60.8,52.9C50.8,64,36,70.2,20.3,74.5C4.6,78.9,-12,81.5,-24.5,75.9C-37,70.3,-45.5,56.6,-55.6,43.7C-65.7,30.9,-77.5,18.8,-80.1,4.9C-82.8,-9,-76.3,-24.9,-67.2,-38.3C-58.1,-51.7,-46.4,-62.7,-33.2,-69.9C-19.9,-77.1,-5,-80.5,7.2,-77.2C19.4,-73.9,38.9,-64,38.5,-64.7Z"
                  transform="translate(100 100) scale(0.8)"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogNewsletterWithImage;