import React from 'react';
import Navbar from './Navbar';
import OurTeamSection from './OurTeamSection';

const OurTeamPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <OurTeamSection/>
    </div>
  );
};

export default OurTeamPage;