import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY || "AIzaSyBA-zGx22GDDjM6LXF0xRwc1_-OBBp2P-w",
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN || "valuefy-home.firebaseapp.com",
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID || "valuefy-home",
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET || "valuefy-home.firebasestorage.app",
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID || "381652862458",
  appId: import.meta.env.VITE_FIREBASE_APP_ID || "1:381652862458:web:443396ed3d67c460879043",
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID || "G-L8BW6Z92GN"
};

// Check if we're using fallback values
const usingFallback = !import.meta.env.VITE_FIREBASE_API_KEY;
if (usingFallback) {
  console.warn('Using fallback Firebase configuration. This is only recommended for development.');
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 