import React from 'react';
import { Link } from 'react-router-dom';
import image from '../assets/blog/tempgrid.png';
import image2 from '../assets/blog/Vector.png';


interface FeaturedBlogPostProps {
  title: string;
  description: string;
  imageUrl: string;
  articleUrl: string;
  isDesktop?: boolean;
}

const FeaturedBlogPost: React.FC<FeaturedBlogPostProps> = ({
  title,
  description,
  imageUrl,
  articleUrl,
}) => {
  return (
    <div className="relative w-1440px h-[35rem]">
      {/* Featured Blog Post Card */}
      <div className="flex flex-col md:flex-row bg-transparent rounded-lg overflow-hidden relative">
        {/* Left: Image */}
        <div className="md:w-1/2 z-10">
          <img
            src={imageUrl}
            alt={title}
            className="w-full h-full object-cover"
          />
        </div>
        
        {/* Right: Content */}
        <div className="md:w-1/2 flex flex-col pt-5 md:pt-0 md:pl-10 pl-0">
          {/* Featured Tag - Absolute positioning to align with image */}
          <div className="w-fit">
            <span 
              className="inline-flex items-center px-3 py-1.5 rounded text-xs font-medium"
              style={{ 
                backgroundColor: '#D1FAE5',
                color: '#065F46'
              }}
            >
              <svg 
                className="w-3 h-3 mr-1.5" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
              </svg>
              Featured
            </span>
          </div>
          
          {/* Content with proper padding */}
          <div className="">
            {/* Title */}
            <h2 className="text-2xl md:text-3xl font-bold  mt-8 text-white">
              {title}
            </h2>
            
            {/* Description */}
            <p className="text-gray-300 mb-6">
              {description}
            </p>
            
            {/* Read Full Article Button */}
            <div>
              <Link 
                to={articleUrl}
                className="inline-block px-5 py-2 bg-white text-black font-medium rounded hover:bg-gray-200 transition-colors"
              >
                Read Full Article
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className='relative -top-24 hidden md:block'>
        <img className='bottom-0 w-full' src={image}/>
        <img className='bottom-0 w-full' src={image2}/>
      </div>
    </div>
  );
};

export default FeaturedBlogPost;