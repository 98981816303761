import plat1 from '../assets/plat1.png';
import plat2 from '../assets/plat2.png';
import plat3 from '../assets/plat3.png';
import plat4 from '../assets/plat4.png';
import { useNavigate } from "react-router-dom";

export const WealthSuit = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/signup");
  };
  return (
    <div className="w-[100%] mx-auto text-white pt-20 bg-gradient-to-b from-[#000000] via-[#000000] to-[#140029] ">
    <div className="w-[85%] mx-auto">
        <div className="w-[80%] flex flex-col gap-4 text-center mx-auto leading-10">
          <div className="text-5xl font-bold mb-2">
            The Valuefy WealthTech Suite
          </div>
          <div className="text-[24px] mb-10">
            An <span className="text-violet-400">end-to-end platform</span> that connects your entire wealth infrastructure—removing friction, improving collaboration, and driving impact.
          </div>
        </div>

        <div className="w-[90%] mx-auto mt-8">
    <div className="flex justify-between flex-col md:flex-row">
        <div className="w-[100%] md:w-[40%] order-1">
            <div className="text-3xl font-bold leading-normal">Portfolio Intelligence & <br/> Reporting</div>
            <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
            <div className="h-[270px] overflow-y-auto scrollbar-hide">
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">One-click Reporting</div>
                <div className="text-[18px] text-[#94A3B8]">Automate and standardize reporting across multi-asset class portfolios.</div>
              </div>
              <div className="mb-4">
                  <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Unified Client & Advisor Dashboards</div>
                  <div className="text-[18px] text-[#94A3B8]">Eliminate duplicate data and gain one true view of wealth.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Seamless Data Aggregation</div>
                <div className="text-[18px] text-[#94A3B8]">Integrates custodians, banks, and portfolio systems into a single framework.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Automated Portfolio Monitoring</div>
                <div className="text-[18px] text-[#94A3B8]">Instantly track performance, risk, and compliance across all investments.</div>
              </div>
            </div>
            <button onClick={handleClick} className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">Streamline Your Reporting</button>
        </div>
        <div className="w-[100%] md:w-[40%] order-2">
         <img src={plat1}/>   
        </div>
    </div>
</div>

<div className="w-[90%] mx-auto mt-20 mb-20">
    <div className="flex justify-between flex-col md:flex-row">
        <div className="w-[100%] md:w-[40%] order-1 md:order-2">
            <div className="text-3xl font-bold leading-normal">Order Management System<br/> (OMS)</div>
            <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
            <div className="h-[270px] overflow-y-auto scrollbar-hide ">
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">No More Fragmented Order Processing</div>
                <div className="text-[18px] text-[#94A3B8]">Execute trades, manage orders, and reconcile transactions—all in one place.</div>
              </div>
              <div className="mb-4">
                  <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Unified Client & Advisor Dashboards</div>
                  <div className="text-[18px] text-[#94A3B8]">Eliminate duplicate data and gain one true view of wealth.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Seamless Data Aggregation</div>
                <div className="text-[18px] text-[#94A3B8]">Integrates custodians, banks, and portfolio systems into a single framework.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Automated Portfolio Monitoring</div>
                <div className="text-[18px] text-[#94A3B8]">Instantly track performance, risk, and compliance across all investments.</div>
              </div>
            </div>
            <button onClick={handleClick} className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">Integrate Your Order Management</button>
        </div>
        <div className="w-[100%] md:w-[40%] order-2 md:order-1">
         <img src={plat2}/>   
        </div>
    </div>
</div>

<div className="w-[90%] mx-auto mt-8 mb-20">
    <div className="flex justify-between flex-col md:flex-row">
        <div className="w-[100%] md:w-[40%] order-1">
            <div className="text-3xl font-bold leading-normal">Wealth Operations &<br/>Compliance</div>
            <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
            <div className="h-[270px] overflow-y-auto scrollbar-hide ">
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Eliminate Manual Data Entry</div>
                <div className="text-[18px] text-[#94A3B8]">Automate and standardize reporting across multi-asset class portfolios.</div>
              </div>
              <div className="mb-4">
                  <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Unified Client & Advisor Dashboards</div>
                  <div className="text-[18px] text-[#94A3B8]">Eliminate duplicate data and gain one true view of wealth.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Seamless Data Aggregation</div>
                <div className="text-[18px] text-[#94A3B8]">Integrates custodians, banks, and portfolio systems into a single framework.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Automated Portfolio Monitoring</div>
                <div className="text-[18px] text-[#94A3B8]">Instantly track performance, risk, and compliance across all investments.</div>
              </div>
            </div>
            <button onClick={handleClick} className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">Simplify Your Wealth Operation</button>
        </div>
        <div className="w-[100%] md:w-[40%] order-2">
         <img src={plat3}/>   
        </div>
    </div>
</div>

<div className="w-[90%] mx-auto mt-20 pb-36">
    <div className="flex justify-between flex-col md:flex-row">
        <div className="w-[100%] md:w-[40%] order-1 md:order-2">
            <div className="text-3xl font-bold leading-normal">Investment Management & <br/>Mandates</div>
            <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4"/>
            <div className="h-[270px] overflow-y-auto scrollbar-hide ">
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Automated Rule-Based Investment Compliance</div>
                <div className="text-[18px] text-[#94A3B8]">Execute trades, manage orders, and reconcile transactions—all in one place.</div>
              </div>
              <div className="mb-4">
                  <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Unified Client & Advisor Dashboards</div>
                  <div className="text-[18px] text-[#94A3B8]">Eliminate duplicate data and gain one true view of wealth.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Seamless Data Aggregation</div>
                <div className="text-[18px] text-[#94A3B8]">Integrates custodians, banks, and portfolio systems into a single framework.</div>
              </div>
              <div className="mb-4">
                <div className="text-[23px] font-bold mb-1 hover:text-violet-400 cursor-pointer">Automated Portfolio Monitoring</div>
                <div className="text-[18px] text-[#94A3B8]">Instantly track performance, risk, and compliance across all investments.</div>
              </div>
            </div>
            <button onClick={handleClick} className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">Unify Your Investment Strategy</button>
        </div>
        <div className="w-[100%] md:w-[40%] order-2 md:order-1">
         <img src={plat4}/>   
        </div>
    </div>
</div>
    </div>
    </div>
  )
}
