import React, { useState, useEffect, useRef } from 'react';
import { AuthError } from '../../utils/types/auth';
import { validateOTP } from '../../utils/validation';

interface OTPVerificationProps {
  identifier: string;
  onVerify: (data: { otp: string }) => void;
  onResend: () => void;
  onBack: () => void;
  error?: string;
}

const OTPVerification: React.FC<OTPVerificationProps> = ({
  identifier,
  onVerify,
  onResend,
  onBack,
  error,
}) => {
  const [otpDigits, setOtpDigits] = useState<string[]>(Array(6).fill(''));
  const [errors, setErrors] = useState<AuthError>({});
  const [timer, setTimer] = useState<number>(30);
  const [canResend, setCanResend] = useState<boolean>(false);
  const [hiddenOtp, setHiddenOtp] = useState<string>('');
  
  // Create refs for each OTP input and a hidden input for SMS autofill
  const inputRefs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null));
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [timer]);

  useEffect(() => {
    if (error) {
      setErrors({ otp: error });
    }
  }, [error]);

  // Focus the first input when component mounts, but also focus hidden input on mobile
  useEffect(() => {
    // Delay focus to avoid mobile keyboard issues
    const timer = setTimeout(() => {
      // Focus hidden input on mobile devices for better SMS autofill
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        hiddenInputRef.current?.focus();
      } else {
        inputRefs.current[0]?.focus();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, []);

  // Handle changes to the hidden input field (used for SMS autofill)
  const handleHiddenInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHiddenOtp(value);
    
    // Extract only digits and limit to 6
    const digits = value.replace(/\D/g, '').slice(0, 6).split('');
    
    // Fill visible inputs with digits from the hidden input
    const newOtpDigits = [...otpDigits];
    digits.forEach((digit, index) => {
      if (index < 6) {
        newOtpDigits[index] = digit;
      }
    });
    
    // Fill remaining slots with empty strings if needed
    for (let i = digits.length; i < 6; i++) {
      newOtpDigits[i] = '';
    }
    
    setOtpDigits(newOtpDigits);
    
    // Auto-submit if we have all 6 digits
    if (digits.length === 6) {
      setTimeout(() => {
        onVerify({ otp: digits.join('') });
      }, 300);
    }
  };

  const handleDigitChange = (index: number, value: string) => {
    // Allow only numbers
    if (value && !/^\d$/.test(value)) return;

    // Update the otpDigits array
    const newOtpDigits = [...otpDigits];
    newOtpDigits[index] = value;
    setOtpDigits(newOtpDigits);

    // Also update the hidden input
    setHiddenOtp(newOtpDigits.join(''));

    // Clear error when typing
    if (errors.otp) {
      setErrors({});
    }

    // Auto focus on next input if a digit was entered
    if (value && index < 5) {
      inputRefs.current[index + 1]?.focus();
    }
    
    // Auto-submit if all digits are filled
    if (value && index === 5) {
      const otp = newOtpDigits.join('');
      if (otp.length === 6 && /^\d{6}$/.test(otp)) {
        setTimeout(() => {
          onVerify({ otp });
        }, 300);
      }
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    // Handle backspace key
    if (e.key === 'Backspace') {
      if (!otpDigits[index] && index > 0) {
        // If current field is empty, focus on previous field
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const digits = pastedData.replace(/\D/g, '').slice(0, 6).split('');
    
    // Fill digits array with pasted data
    const newOtpDigits = [...otpDigits];
    digits.forEach((digit, index) => {
      if (index < 6) {
        newOtpDigits[index] = digit;
      }
    });
    
    setOtpDigits(newOtpDigits);
    setHiddenOtp(newOtpDigits.join(''));
    
    // Focus on the next empty input or the last input
    const emptyIndex = newOtpDigits.findIndex(digit => !digit);
    if (emptyIndex !== -1) {
      inputRefs.current[emptyIndex]?.focus();
    } else if (newOtpDigits[5]) {
      inputRefs.current[5]?.focus();
    }
    
    // Auto-submit if we have all 6 digits from paste
    if (digits.length === 6) {
      setTimeout(() => {
        onVerify({ otp: digits.join('') });
      }, 300);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});

    const otp = otpDigits.join('');
    if (!validateOTP(otp)) {
      setErrors({ otp: 'Please enter a valid 6-digit OTP' });
      return;
    }

    onVerify({ otp });
  };

  const handleResend = () => {
    if (!canResend) return;
    onResend();
    setTimer(30);
    setCanResend(false);
  };

  // Format the identifier to show as a masked value
  const formatIdentifier = () => {
    if (identifier.includes('@')) {
      // For email: show first 3 chars + masked middle + domain
      const [username, domain] = identifier.split('@');
      if (username.length <= 3) return identifier;
      return `${username.substring(0, 3)}${'*'.repeat(username.length - 3)}@${domain}`;
    } else {
      // For phone: show only last 4 digits
      return `${'*'.repeat(Math.max(0, identifier.length - 4))}${identifier.slice(-4)}`;
    }
  };

  return (
    <div className="w-full">
      <button
        onClick={onBack}
        className="text-gray-400 hover:text-white mb-4 sm:mb-6 flex items-center gap-2 text-sm sm:text-base"
        aria-label="Go back"
      >
        <svg
          className="w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 19l-7-7 7-7"
          />
        </svg>
        Back
      </button>

      <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
        <div>
          <h2 className="text-lg sm:text-xl font-semibold mb-1 sm:mb-2">Enter Verification Code</h2>
          <p className="text-sm sm:text-base text-gray-400 mb-3 sm:mb-4">
            We've sent a verification code to {identifier.includes('@') ? 'email' : 'phone'} {formatIdentifier()}
          </p>
          <div>
            {/* Hidden input field for SMS autofill */}
            <input
              ref={hiddenInputRef}
              type="text"
              inputMode="numeric"
              autoComplete="one-time-code"
              value={hiddenOtp}
              onChange={handleHiddenInputChange}
              className="opacity-0 absolute h-0 w-0 -z-10"
              aria-hidden="true"
              maxLength={6}
            />
            
            <div className="flex justify-center gap-1 sm:gap-2 md:gap-4">
              {otpDigits.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleDigitChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={index === 0 ? handlePaste : undefined}
                  ref={el => inputRefs.current[index] = el}
                  className="w-8 h-12 sm:w-10 sm:h-14 md:w-12 md:h-16 bg-black/50 border border-gray-800 rounded-lg px-0 text-white focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-center text-xl sm:text-2xl"
                  inputMode="numeric"
                  autoComplete="off" // Changed to off since we're using the hidden input for autofill
                  aria-label={`Digit ${index + 1}`}
                  style={{ touchAction: 'manipulation' }} // Improves touch experience
                />
              ))}
            </div>
            {errors.otp && (
              <p className="mt-2 text-sm text-red-500 text-center">{errors.otp}</p>
            )}
          </div>
        </div>

        <div className="flex flex-col gap-3 sm:gap-4">
          <button
            type="submit"
            className="w-full bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white font-medium py-3 px-4 rounded-lg hover:opacity-90 transition-opacity text-sm sm:text-base"
          >
            Verify
          </button>
          <button
            type="button"
            onClick={handleResend}
            disabled={!canResend}
            className={`w-full text-center py-2 text-sm sm:text-base ${
              canResend
                ? 'text-purple-500 hover:text-purple-400'
                : 'text-gray-500 cursor-not-allowed'
            }`}
          >
            {canResend
              ? 'Resend Code'
              : `Resend code in ${timer} seconds`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default OTPVerification; 