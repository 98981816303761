import { AuthMethod, SignInFormData, SignUpFormData, AuthError } from './types/auth';

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string): boolean => {
  // Allow any sequence of 6-15 digits for international format
  const phoneRegex = /^\d{6,15}$/;
  return phoneRegex.test(phone);
};

export const validateOTP = (otp: string): boolean => {
  const otpRegex = /^\d{6}$/;
  return otpRegex.test(otp);
};

export const validateSignInForm = (data: SignInFormData): AuthError => {
  const errors: AuthError = {};

  if (!data.identifier) {
    errors.identifier = data.authMethod === AuthMethod.EMAIL 
      ? 'Email is required' 
      : 'Phone number is required';
  } else if (data.authMethod === AuthMethod.EMAIL && !validateEmail(data.identifier)) {
    errors.identifier = 'Invalid email format';
  } else if (data.authMethod === AuthMethod.PHONE && !validatePhone(data.identifier)) {
    errors.identifier = 'Invalid phone number format';
  }

  return errors;
};

export const validateSignUpForm = (data: SignUpFormData): AuthError => {
  const errors: AuthError = {};

  if (!data.firstName?.trim()) {
    errors.firstName = 'First name is required';
  }

  if (!data.lastName?.trim()) {
    errors.lastName = 'Last name is required';
  }

  if (!data.identifier) {
    errors.identifier = data.authMethod === AuthMethod.EMAIL 
      ? 'Email is required' 
      : 'Phone number is required';
  } else if (data.authMethod === AuthMethod.EMAIL && !validateEmail(data.identifier)) {
    errors.identifier = 'Invalid email format';
  } else if (data.authMethod === AuthMethod.PHONE && !validatePhone(data.identifier)) {
    errors.identifier = 'Invalid phone number format';
  }

  if (!data.acceptTerms) {
    errors.acceptTerms = 'You must accept the terms and conditions';
  }

  return errors;
}; 