import Navbar from "./Navbar";
import AboutUsHero from "./AboutUsHero";
import AboutMission from "./AboutMission";
import WhatWeDo from "./AboutUsWhatWeDo";
import WhoWeServe from "./AboutWhoWeServe";
import ValuefyEdge from "./AboutValuefyEdge";
import AboutUnlock from "./AboutUnlock";
import AboutInnovations from "./AboutInnovations";
import TrustFeatures from "./AboutTrustFeatures";

function App() {
  return (
    <div className="min-h-screen">
      <Navbar/>
      <AboutUsHero/>
      <AboutMission/>
      <div className="hidden md:block">
        <WhatWeDo />
      </div>
      <WhoWeServe/>
      <ValuefyEdge/>
      <div className="hidden md:block">
        <TrustFeatures/>
      </div>
      <AboutInnovations/>
      <AboutUnlock/>
    </div>
  );
}

export default App;
