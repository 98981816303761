import React from 'react';
import { useLocation } from 'react-router-dom';

// Import all background images
import wmBackground from '../assets/wealth-manager/wealth-manager-bg.png';
// import rmBackground from '../assets/relationship-manager/rm-bg.png';
import coBackground from '../assets/wealth-manager/compliance-officer-bg.png';
// import otBackground from '../assets/operations-teams/ot-bg.png';
// import cioBackground from '../assets/cios/cio-bg.png';
import foBackground from '../assets/wealth-manager/family-offices-bg.png';
import otBackground from '../assets/wealth-manager/ot-background.png';
import iaBackground from '../assets/wealth-manager/ia-background.png';

// Define a type for the keys of the contentMap
type ContentPath = 
  | '/solutions/wealth-manager'
  | '/solutions/relationship-manager'
  | '/solutions/compliance-officer'
  | '/solutions/operation-team'
  | '/solutions/cio'
  | '/solutions/family-office';

// Define the Content type directly in the contentMap typing
const WMHero: React.FC = () => {
  const location = useLocation();

  // Function to get content based on current path
  const getContentFromPath = () => {
    const path = location.pathname as ContentPath;
    
    const contentMap: Record<ContentPath, {
      title: string;
      backgroundImage: string;
      heading: string;
      altText: string;
    }> = {
      '/solutions/wealth-manager': {
        title: 'Wealth Managers',
        backgroundImage: wmBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl">Power<span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Decisions</span>for<br/> Smart <span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Wealth</span></span>`,
        altText: 'Wealth Manager Background'
      },
      '/solutions/relationship-manager': {
        title: 'Relationship Managers',
        backgroundImage: wmBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl"><span class="font-bold">Enhance<br/></span><span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Client Engagement</span></span>`,
        altText: 'Relationship Manager Background'
      },
      '/solutions/compliance-officer': {
        title: 'Compliance Officers',
        backgroundImage: coBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl"><span class="font-bold">Automated<br/></span><span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Risk Management</span></span>`,
        altText: 'Compliance Officer Background'
      },
      '/solutions/operation-team': {
        title: 'Operations Teams',
        backgroundImage: otBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl"><span class="font-bold">Automate Optimize<br/></span><span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Deliver With Speed</span></span>`,
        altText: 'Operations Teams Background'
      },
      '/solutions/cio': {
        title: 'Investment Advisors',
        backgroundImage: iaBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl"><span class="font-bold">Sharper Insights. Smarter <br/>Decisions.</span><span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Greater Alpha.</span></span>`,
        altText: 'CIOs Background'
      },
      '/solutions/family-office': {
        title: 'Family Offices',
        backgroundImage: foBackground,
        heading: `<span style="line-height: 130%;" class="sm:text-5xl md:text-6xl lg:text-7xl">A <span class="bg-gradient-to-r from-[rgb(128,108,234)] to-[rgb(162,101,230)] text-transparent bg-clip-text text-purple-400 font-inter italic pr-2 text-purple-400 font-inter italic">Smarter Way </span>to Manage <br/>Generational Wealth</span>`,
        altText: 'Family Offices Background'
      }
    };
    
    // Return the content for the current path or default to Wealth Manager
    return contentMap[path] || contentMap['/solutions/wealth-manager'];
  };
  
  const content = getContentFromPath();
  
  return (
    <div className="relative bg-primary-dark text-white">
      {/* Background Image with Overlay */}
      <img 
        src={content.backgroundImage} 
        alt={content.altText}
        className="absolute inset-0 w-full h-full object-cover"
      />
      
      {/* Content */}
      <div className="relative z-10 max-w-7xl mx-auto px-8 py-24 md:py-48 flex justify-center">
        <div className="w-full flex flex-col items-center text-center">
        <div className="inline-block bg-gray-800 bg-opacity-50 text-white px-16 py-2 rounded-md mb-6 text-3xl">
          Valuefy for {content.title}
        </div>
        
        <h1 
          className="text-4xl md:text-5xl lg:text-6xl inter"
          dangerouslySetInnerHTML={{ __html: content.heading }}
        />

        
        {/* Dot Indicator (Purple Circle) change opacity-0 to see the dot again*/}
        <div className="mt-8 relative">
        <div className="w-16 h-16 bg-purple-500 rounded-full flex items-center justify-center opacity-0">  
          {/* <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="white" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <polyline points="19 12 12 19 5 12"></polyline>
          </svg> */}
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default WMHero;
