import React, { useState, useEffect } from "react";

type GreetingProps = {
  greetings: string[];
};

const Greeting: React.FC<GreetingProps> = ({ greetings }) => {
  const [highlightIndex, setHighlightIndex] = useState(0);
  const firstLineGreetings = greetings.slice(0, 3);
  const secondLineGreetings = greetings.slice(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setHighlightIndex((prevIndex) => (prevIndex + 1) % greetings.length);
    }, 1500);

    return () => clearInterval(timer);
  }, [greetings.length]);

  return (
    <div className="mt-16">
      <h1 className="lg:text-8xl text-6xl font-bold">
        {firstLineGreetings.map((greeting, index) => {
          const isHighlighted =
            index === highlightIndex &&
            highlightIndex < firstLineGreetings.length;

          return (
            <React.Fragment key={greeting}>
              <span
                className={`transition-colors duration-700 ease-in-out ${
                  isHighlighted ? "text-white" : "text-gray-900"
                }`}
              >
                {greeting}
              </span>
              {index < firstLineGreetings.length - 1 && (
                <span className="text-gray-900">, </span>
              )}
              {index === firstLineGreetings.length - 1 && (
                <span className="text-gray-900">,</span>
              )}
            </React.Fragment>
          );
        })}
      </h1>

      {/* New Line */}
      <h1 className="lg:text-8xl text-6xl font-bold mt-4">
        {secondLineGreetings.map((greeting, index) => {
          const isHighlighted =
            index + firstLineGreetings.length === highlightIndex &&
            highlightIndex >= firstLineGreetings.length;

          return (
            <React.Fragment key={greeting}>
              <span
                className={`transition-colors duration-700 ease-in-out ${
                  isHighlighted ? "text-white" : "text-gray-900"
                }`}
              >
                {greeting}
              </span>
              {index < secondLineGreetings.length - 1 && (
                <span className="text-gray-900">, </span>
              )}
            </React.Fragment>
          );
        })}
      </h1>
    </div>
  );
};

export default Greeting;
