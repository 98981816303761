import React, { useEffect, useRef, useState } from "react";
import { AuroraText } from "./magicui/aurora-text";
 
// Define the NumberTicker component
const NumberTicker: React.FC<{ value: number; duration?: number }> = ({
  value,
  duration = 2000,
}) => {
  const [displayValue, setDisplayValue] = useState(0);
  const startTime = useRef<number | null>(null);
  const animationRef = useRef<number>();
 
  // Easing function - easeOutQuart: starts fast, slows down at the end
  const easeOutQuart = (x: number): number => {
    return 1 - Math.pow(1 - x, 4);
  };
 
  useEffect(() => {
    // Animation function
    const animate = (timestamp: number) => {
      if (!startTime.current) startTime.current = timestamp;
      const progress = timestamp - startTime.current;
 
      // Calculate current value based on progress with easing
      const linearPercentage = Math.min(progress / duration, 1);
      const easedPercentage = easeOutQuart(linearPercentage);
      const currentValue = Math.floor(easedPercentage * value);
 
      setDisplayValue(currentValue);
 
      // Continue animation if not completed
      if (linearPercentage < 1) {
        animationRef.current = requestAnimationFrame(animate);
      }
    };
 
    // Start animation
    animationRef.current = requestAnimationFrame(animate);
 
    // Cleanup function
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [value, duration]);
 
  return <span>{displayValue}%</span>;
};
 
// Main component for the Wealth Management UI
const WealthManagementUI: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
 
  useEffect(() => {
    // Function to check if element is in viewport
    const checkVisibility = () => {
      if (!sectionRef.current) return;
 
      const rect = sectionRef.current.getBoundingClientRect();
      const isInViewport =
        rect.top <= window.innerHeight * 0.8 && rect.bottom >= 0;
 
      if (isInViewport && !isVisible) {
        setIsVisible(true);
      }
    };
 
    // Add scroll event listener
    window.addEventListener("scroll", checkVisibility);
    // Check initial visibility
    checkVisibility();
 
    // Clean up
    return () => window.removeEventListener("scroll", checkVisibility);
  }, [isVisible]);
 
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
 
  // Effect to update window width on resize
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
 
    // Set initial width
    handleResize();
 
    // Add event listener
    window.addEventListener("resize", handleResize);
 
    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  // Define tablet breakpoint (e.g., 1024px)
  const tabletBreakpoint = 1024;
 
  return (
    <div
      ref={sectionRef}
      className="w-full min-h-screen bg-black text-white flex items-center justify-center p-4 sm:p-6 md:p-8 overflow-hidden"
      style={{
        backgroundImage:
          "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiPgogIDxwYXR0ZXJuIGlkPSJncmlkIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgcGF0dGVyblVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICA8cGF0aCBkPSJNIDEwMCAwIEwgMCAwIDAgMTAwIiBmaWxsPSJub25lIiBzdHJva2U9IiMxMTExMTEiIHN0cm9rZS13aWR0aD0iMSIvPgogIDwvcGF0dGVybj4KICA8cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIiAvPgo8L3N2Zz4=')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="max-w-7xl mx-auto w-full px-4 sm:px-6 md:px-8">
  <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 sm:gap-16 lg:gap-8">
    {/* Left column - Text content */}
    <div className="flex flex-col justify-center space-y-4 sm:space-y-5 md:space-y-6 mb-8 lg:mb-0 text-left">
      <h1 className="text-3xl sm:text-3xl md:text-4xl font-bold tracking-tight">
        Real-Time Data for{" "}
        <span className="block mt-2">
          {windowWidth <= tabletBreakpoint ? (
            // Gradient purple text for mobile and tablet
            <span className="text-3xl sm:text-3xl md:text-4xl inline-block font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-purple-900 leading-[1.5] sm:leading-[1.6]">
              Smarter Wealth Management
            </span>
          ) : (
            // Aurora text for desktop
            <AuroraText
              className="text-2xl sm:text-3xl md:text-4xl inline-block"
              colors={[
                "#d946ef",
                "#c026d3",
                "#9333ea",
                "#7c3aed",
                "#6366f1",
                "#3b82f6",
                "#0ea5e9",
              ]}
              speed={2}
            >
              {" "}
              Smarter Wealth Management
            </AuroraText>
          )}
        </span>
      </h1>

      <p className="text-base sm:text-lg text-gray-300 max-w-md mx-0">
        Our platform empowers wealth managers with comprehensive insights
        into client portfolios.
      </p>

      <p className="text-base sm:text-lg text-gray-300 max-w-md mx-0">
        Experience a seamless integration of all investment data in one
        view.
      </p>

      
    </div>

    {/* Right column - Stats */}
    <div className="flex flex-col sm:flex-row justify-start sm:justify-center items-start sm:items-center sm:space-x-4 md:space-x-8 space-y-10 sm:space-y-0">
  <div className="text-left sm:text-center bg-gray-900 bg-opacity-40 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-gray-800 w-full sm:w-1/2 transform hover:scale-105 transition-transform duration-300">
    <h2 className="text-5xl sm:text-6xl md:text-7xl font-bold bg-gradient-to-b from-white to-gray-500 text-transparent bg-clip-text mb-4">
      {isVisible ? <NumberTicker value={75} /> : "0%"}
    </h2>
    {/* <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto my-3"></div> */}
    <p className="text-sm sm:text-base md:text-lg mt-2 max-w-[180px] sm:max-w-[200px] mx-0 sm:mx-auto text-gray-300">
      Increase in client satisfaction reported by users.
    </p>
  </div>

  <div className="text-left sm:text-center bg-gray-900 bg-opacity-40 backdrop-blur-sm p-6 rounded-xl shadow-lg border border-gray-800 w-full sm:w-1/2 transform hover:scale-105 transition-transform duration-300">
    <h2 className="text-5xl sm:text-6xl md:text-7xl font-bold bg-gradient-to-b from-white to-gray-500 text-transparent bg-clip-text mb-4">
      {isVisible ? <NumberTicker value={90} /> : "0%"}
    </h2>
    {/* <div className="w-16 h-1 bg-gradient-to-r from-purple-500 to-blue-500 mx-auto my-3"></div> */}
    <p className="text-sm sm:text-base md:text-lg mt-2 max-w-[180px] sm:max-w-[200px] mx-0 sm:mx-auto text-gray-300">
      Users have streamlined their investment tracking processes.
    </p>
  </div>
</div>
  </div>
</div>
    </div>
  );
};
 
export default WealthManagementUI;