import React, { useEffect } from 'react';

interface HubspotProviderProps {
  hubspotId: string;
  children: React.ReactNode;
}

const HubspotProvider: React.FC<HubspotProviderProps> = ({ hubspotId, children }) => {
  useEffect(() => {
    // Create HubSpot script
    const script = document.createElement('script');
    script.id = 'hs-script-loader';
    script.src = `//js.hs-scripts.com/${hubspotId}.js`;
    script.async = true;
    script.defer = true;
    
    // Append to document body
    document.body.appendChild(script);
    
    // Cleanup function to remove script on unmount
    return () => {
      const existingScript = document.getElementById('hs-script-loader');
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [hubspotId]);
  
  return <>{children}</>;
};

export default HubspotProvider;