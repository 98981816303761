import mission from '../assets/about-us/mission.png'
import card1 from '../assets/about-us/card1.png';
import card2 from '../assets/about-us/card2.png';
import card3 from '../assets/about-us/card3.png';
const AboutMission = () => {
  const missionItems = [
    {
      image: card1
    },
    {
      image:card2
    },
    {
      image:card3
    }
  ];

  return (
    <div className="w-full bg-black text-white py-12"> {/* Increase py-16 to py-20 */}
  <div className="max-w-6xl mx-auto px-4"> {/* Increase max-w-5xl to max-w-6xl */}
    {/* About Section */}
    <div className="flex flex-col md:flex-row items-center gap-12 mb-24"> {/* Increase mb-20 to mb-24 */}
      {/* Logo */}
      <div className="md:w-3/4 flex md:justify-start md:-ml-8">       
        <img src={mission} className="w-full max-w-[500px] md:max-w-[600px] h-auto" /> {/* Increase image size */}
      </div>
      
      {/* About Text */}
      <div className="md:w-3/4">
        <h2 className="text-5xl md:text-5xl font-bold font-inter mb-6 text-center md:text-left">About Valuefy</h2> {/* Increase text-5xl to text-6xl */}
        <p className="text mb-6 text-gray-300 text-center text-2xl md:text-left"> {/* Increase text size with text-lg and mb-6 */}
          Valuefy is a leading wealth management technology company, delivering a seamless interconnected system that ensures your organisation focuses on growing the business.
        </p>
        <p className="text-gray-300 font-inter text-center text-2xl md:text-left"> {/* Increase text size */}
          We empower wealth managers, advisory firms, and financial professionals with automation-driven technology, advanced analytics, seamless digital experiences coupled with our CRM that ensures seamless interconnectivity thereby making the life of the entire business ecosystem of your organisation faster and better.
        </p>
      </div>
    </div>
        
        {/* Mission Section */}
        <div className="text-center">
  <h2 className="text-5xl font-bold mb-8">Our Mission</h2>
  <p className="mb-12 text-gray-300 text-3xl">To transform wealth management through...</p>
  
  {/* Mission Items */}
  <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
    {missionItems.map((item, index) => (
      <div key={index} className="relative">
        {/* Custom Image */}
        <img 
          src={item.image} 
          className="w-full h-auto rounded-lg" 
        />
        
      </div>
    ))}
  </div>
  
  {/* Footer Text */}
  <p className="mt-8 text-gray-300 text-3xl hidden md:block">And scale with Confidence</p>
</div>
      </div>
    </div>
  );
};

export default AboutMission;