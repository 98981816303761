import React from "react";

// Import images - make sure to add these to your assets folder
import valueifyCRM from "../assets/product-demo/crm1.png";
import wealth360Dashboard from "../assets/product-demo/prod-demo.png";
import portfolioInsights from "../assets/product-demo/prod-demo.png";

const AboutInnovations: React.FC = () => {
  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-6xl mx-auto px-4">
        {/* Headline */}
        <div className="text-center mb-16">
          <h2 className="text-5xl font-bold mb-8">Our Innovations</h2>
          <p className="text-3xl">
            We don't just build WealthTech.{" "}
            <span className="text-purple-500">We set the standard for it.</span>
          </p>
        </div>

        {/* Valueify CRM Section */}
        <div className="flex flex-col md:flex-row items-center mb-16 gap-8">
          <div className="md:w-1/3">
            <h3 className="text-3xl font-semibold mb-3">Valuefy CRM</h3>
            <p className="text-gray-300 text-2xl">
              The only{" "}
              <span className="text-purple-500">CRM built for wealth</span>,
              enabling better engagement & retention.
            </p>
          </div>
          <div className="md:w-2/3">
            <img
              src={valueifyCRM}
              alt="Valueify CRM Interface"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Wealth360 Dashboard Section */}
        <div className="flex flex-col-reverse md:flex-row items-center mb-16 gap-8">
          <div className="md:w-2/3">
            <img
              src={wealth360Dashboard}
              alt="Wealth360 Dashboard Interface"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
          <div className="md:w-1/3">
            <h3 className="text-3xl font-semibold mb-3">Wealth360 Dashboard</h3>
            <p className="text-gray-300 text-2xl">
              A{" "}
              <span className="text-purple-500">
                real-time wealth intelligence suite
              </span>{" "}
              designed for scale.
            </p>
          </div>
        </div>

        {/* Portfolio Insights Section */}
        <div className="flex flex-col md:flex-row items-center mb-8 gap-8">
          <div className="md:w-1/3">
            <h3 className="text-3xl font-semibold mb-3">
              Automation-Powered Portfolio Insights
            </h3>
            <p className="text-gray-300 text-2xl">
              <span className="text-purple-500">
                Actionable portfolio intelligence
              </span>{" "}
              at your fingertips.
            </p>
          </div>
          <div className="md:w-2/3">
            <img
              src={portfolioInsights}
              alt="Portfolio Insights Interface"
              className="w-full rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Features Section */}
      </div>
    </div>
  );
};

export default AboutInnovations;
