import React from 'react';
import Hero from "./components/Hero";
import ValuefyPlatform from "./components/PlatformTabs";
import Home from "./components/UnlockValuefy";
import Platform from "./components/Platform";
import TestimonialCarousel from "./components/TestimonialCarousel";
import OrbitingCards from "./components/OrbitingCards";
import { SEO } from "./components/seo";

const App: React.FC = () => {
  return (
    <>
      <SEO 
        title="Home | Valuefy"
        description="Welcome to Valuefy, your comprehensive wealth management platform."
      />
      <Hero />
      <ValuefyPlatform />
      <OrbitingCards />
      <Platform />
      <TestimonialCarousel />
      <Home />
    </>
  );
};

export default App;

/*

import { useState, useEffect } from "react";
import { supabase } from "./utils/supabase";

function Page() {
  useEffect(() => {
    async function getTodos() {
      const { data, error } = await supabase.from("leads").insert([
        {
          name: "Chaitanya1",
          mobile: "9981716388",
          city: "Pune",
          organization: "Valuefy",
          role_in_organization: "SDE",
        },
      ]);

      if (error) {
        console.error("Error inserting data:", error);
      } else {
        console.log("Data inserted successfully:", data);
      }
    }

    getTodos();
  }, []);

  return (
    <div>
      <h1>Hi</h1>
    </div>
  );
}

export default Page;


*/
