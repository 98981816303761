import Mumbai from "../assets/offices/mumbai.png";
import Dubai from "../assets/offices/dubai.png";
import Singapore from "../assets/offices/singapore.png";
import Europe from "../assets/offices/europe.png";
import London from "../assets/offices/london.png";

export default function OfficeLocations() {
  const locations = {
    international: [
      {
        city: "Mumbai",
        address:
          "M Square IT Park, 4th Floor, Rd No. 33,\nWagle Estate, Thane (W).",
        image: Mumbai,
      },
      {
        city: "UK - London",
        address: "64 North Row, 5th Floor, Mayfair,\nLondon, W1K 7DA",
        image: London,
      },
      {
        city: "Singapore",
        address: "68 Circular Road \n#02-01 Singapore 049422",
        image: Singapore,
      },
      {
        city: "UAE - Dubai",
        address: "Office 206, Gate Village 5, \nDIFC - Dubai ",
        image: Dubai,
      },
      {
        city: "Europe",
        address: "(Expansion in Progress)",
        image: Europe,
      },
    ],
  };

  // First 2 international locations for the first row
  const firstRowInternational = locations.international.slice(0, 2);
  // Next 3 elements starting from index 2
  const secondRowInternational = locations.international.slice(2, 5);

  return (
    <div className="bg-black text-white min-h-screen p-6 md:p-10">
      <div className="max-w-7xl mx-auto space-y-12">
        {/* International Offices */}
        <section>
          <h2 className="text-3xl md:text-3xl font-bold text-center mb-8 text-gray-400">
            India & International Offices
          </h2>

          {/* First row - 2 cards */}
          <div className="grid md:grid-cols-2 gap-4 mb-8">
            {firstRowInternational.map((location) => (
              <LocationCard key={location.city} location={location} />
            ))}
          </div>

          {/* Second row - 3 cards */}
          <div className="grid md:grid-cols-3 gap-4">
            {secondRowInternational.map((location) => (
              <LocationCard key={location.city} location={location} />
            ))}
          </div>
        </section>
        <GoogleMapsButton />
      </div>
    </div>
  );
}

interface LocationProps {
  location: {
    city: string;
    address: string;
    image: string;
    nativeText?: string;
  };
  showNativeText?: boolean;
}

function LocationCard({ location }: LocationProps) {
  return (
    <div className="relative overflow-hidden rounded-lg h-80 w-full group">
      <img
        src={location.image || "/placeholder.svg"}
        alt={`${location.city} office`}
        className="object-cover w-full h-full"
      />
      <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent">
        <div className="absolute bottom-7 left-7 p-5 w-full">
          <h3 className="text-2xl font-bold mb-2">{location.city}</h3>
          <p className="text-sm text-gray-300 whitespace-pre-line">
            {location.address}
          </p>
        </div>
      </div>
    </div>
  );
}

interface GoogleMapsButtonProps {
  onClick?: () => void;
}
const GoogleMapsButton: React.FC<GoogleMapsButtonProps> = ({}) => {
  return (
    <div className="flex justify-center w-full px-4 sm:px-6 md:px-8">
      <button
        className="flex items-center justify-center gap-2 bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 px-3 sm:py-3 sm:px-4 rounded-lg cursor-pointer w-full max-w-xs transition-colors duration-200"
        onClick={() => {
          window.location.href = 'https://www.google.com/maps/place/M+square+IT+park/@19.202572,72.9535781,17z/data=!3m1!4b1!4m6!3m5!1s0x3be7b9f7ae1156f1:0x99dda2991c0e5a7c!8m2!3d19.202572!4d72.956153!16s%2Fg%2F11pcf2hzwx?entry=ttu&g_ep=EgoyMDI1MDMxOS4yIKXMDSoASAFQAw%3D%3D';
        }}
        aria-label="Find us on Google Maps"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="sm:w-5 sm:h-5"
        >
          <circle cx="12" cy="10" r="3" />
          <path d="M12 2a8 8 0 0 0-8 8c0 1.892.402 3.13 1.5 4.5L12 22l6.5-7.5c1.098-1.37 1.5-2.608 1.5-4.5a8 8 0 0 0-8-8z" />
        </svg>
        <span className="text-sm sm:text-base">Find us on Google Maps</span>
      </button>
    </div>
  );
};
