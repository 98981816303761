import logo from '../assets/team/logo.png';
import I1 from '../assets/team/Sharad.png';
import I2 from '../assets/team/Vivek.png';
import I3 from '../assets/team/Srikant.png';
import I4 from '../assets/team/SM.png';
import I5 from '../assets/team/Amit.png';
import I6 from '../assets/team/Mahendranath.png';
import I7 from '../assets/team/Prasad.png';
import I8 from '../assets/team/Sameer.png';
import ISid from '../assets/team/Sid.png';

const TeamSection = () => {
  const managementTeam = [
    {
      name: "Sharad Singh",
      title: "Co-Founder, CEO",
      bio: `Full-stack entrepreneur. Passionate about finance, CX and technology.`,
      image: I1,
      linkedIn:"https://www.linkedin.com/in/singhsharad/?originalSubdomain=in"
    },
    {
      name: "Vivek Singhal",
      title: "Co-Founder, Director",
      bio: "Fintech entrepreneur focused on global market expansion.",
      image: I2,
      linkedIn:"https://www.linkedin.com/in/viveksingal/"
    },
    {
      name: "Srikant Tiwari",
      title: "Co-Founder, Senior VP Sales",
      bio: "Fintech Sales professional with a knack of market understanding.",
      image: I3,
      linkedIn:"https://www.linkedin.com/in/shrikant-tiwari-9144366/"
    },
    {
      name: "Srutaban Mukhopadhyay",
      title: "Chief Product Officer",
      bio: "Product strategist harnessing wealth management experience for global solution.",
      image: I4,
      linkedIn:"https://www.linkedin.com/in/srutaban-mukhopadhyay-b002691/"
    },
    {
      name: "Siddhartha Lahiri",
      title: "Chief Technology Officer (CTO)",
      bio: "Building Valuefy as one of the world's leading WealthTech by innovating a secure and state-of-the-art technology platform powered by a youthful and happy engineering team.",
      image: ISid,
      linkedIn:"https://www.linkedin.com/in/lahiris/"
    },
    {
      name: "Amit Kaushik",
      title: "Business Head - Asia",
      bio: "Growing presence in Asian markets and creating sustainable value for our stakeholders",
      image: I5,
      linkedIn:"https://www.linkedin.com/in/amit1506/"
    },
    {
      name: "Mahendranath Pal",
      title: "VP for Development and Expansion",
      bio: "Mahendra likes to Walk the Talk and he is passionate about FinTech & Fitness.",
      image: I6,
      linkedIn:"https://www.linkedin.com/in/mahendranath-pal-79494a45/"
    },
    {
      name: "Prasad Janardhan",
      title: "Business Head - India",
      bio: "Prasad leads India's wealth business,passionate about tech & football.".replace('football','football  '),
      image: I7,
      linkedIn:"https://www.linkedin.com/in/prasadjanardhanan/"
    },
    {
      name: "Sameer Bordiya",
      title: "Product Manager",
      bio: "A Product Manager excelling in scalable integrations and data-driven insights.",
      image: I8,
      linkedIn:"https://www.linkedin.com/in/sameerbordiya/"
    }
  ];

  return (
    <div className="bg-black text-white py-16 px-4 font-inter">
      <div className="max-w-6xl mx-auto">
  <h2 className="text-4xl font-bold text-center mb-3">Meet our Awesome Team</h2>
  <p className="text-center text-gray-300 mb-12 text-2xl">
    We are problem solvers, developing innovative productivity solutions for product and design.
  </p>

  <div className="mb-8">
    <h3 className="text-xl font-medium mb-4">Management</h3>
    <div className="h-px bg-gray-700 w-full mb-8"></div>
    
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      {managementTeam.map((member, index) => (
        <div key={index} className="rounded-lg overflow-hidden flex flex-col h-full">
          <div className="aspect-square overflow-hidden">
            <img 
              src={member.image} 
              alt={member.name} 
              className="w-full h-full object-cover"
            />
          </div>
          <div className="p-4 flex flex-col flex-grow">
            <h4 className="font-bold text-lg">{member.name}</h4>
            <p className="text-sm text-gray-400 font-inter mb-2">{member.title}</p>
            <p className="text-sm font-inter mb-3 flex-grow">{member.bio}</p>
            <div className="mt-auto">
              <a 
                href={member.linkedIn} 
                className="inline-block text-gray-400 hover:text-white transition-colors"
                aria-label={`${member.name}'s LinkedIn profile`}
              >
                <img src={logo} className="w-5 h-5"/>
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
    </div>
  );
};

export default TeamSection;