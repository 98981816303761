// Browser-compatible Redis client implementation
// This provides a compatible API with the server-side Redis implementation
// but doesn't actually connect to Redis in browser environments. Instead, it uses
// localStorage for client-side caching with a similar interface.

import { MAIN_ROUTES, SOLUTION_ROUTES, LEGAL_ROUTES, SUPPORT_ROUTES } from "../constants/routes";

// Default TTL values in seconds
const DEFAULT_TTL = 60 * 60; // 1 hour
const ROUTES_TTL = 60 * 60 * 24; // 24 hours 

// Local storage cache key prefix to prevent collisions
const LS_PREFIX = 'redis_cache:';

/**
 * Redis client for browser environment
 * This is a localStorage-based mock implementation for the browser environment
 * In a real application, Redis would be used on the server side
 */
class BrowserRedisClient {
  private storage: Storage | null;
  private prefix: string = "redis:";
  private ttls: Record<string, number> = {};

  constructor() {
    this.storage = typeof window !== 'undefined' ? window.localStorage : null;
    console.log('Browser Redis client initialized');
    
    // Clean up expired keys on initialization
    this.cleanupExpiredKeys();
    
    // Set up periodic cleanup
    if (typeof window !== 'undefined') {
      setInterval(() => this.cleanupExpiredKeys(), 60000); // Clean up every minute
    }
  }

  /**
   * Set a key-value pair with an optional expiration
   * @param key The key to set
   * @param value The value to set
   * @param expiryInSeconds Optional expiration in seconds
   */
  async set(key: string, value: any, expiryInSeconds?: number): Promise<string> {
    if (!this.storage) return 'OK';
    
    try {
      const prefixedKey = this.prefix + key;
      this.storage.setItem(prefixedKey, JSON.stringify(value));
      
      // If expiry is provided, store it
      if (expiryInSeconds) {
        const expiryTimestamp = Date.now() + (expiryInSeconds * 1000);
        this.ttls[prefixedKey] = expiryTimestamp;
        this.storage.setItem(`${this.prefix}ttls`, JSON.stringify(this.ttls));
      }
      
      return 'OK';
    } catch (error) {
      console.error('Error setting Redis key:', error);
      return 'ERROR';
    }
  }

  /**
   * Get a value by key
   * @param key The key to get
   */
  async get(key: string): Promise<any> {
    if (!this.storage) return null;
    
    try {
      const prefixedKey = this.prefix + key;
      
      // Check if key is expired
      if (this.isExpired(prefixedKey)) {
        this.del(key);
        return null;
      }
      
      const value = this.storage.getItem(prefixedKey);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Error getting Redis key:', error);
      return null;
    }
  }

  /**
   * Delete a key
   * @param key The key to delete
   */
  async del(key: string): Promise<number> {
    if (!this.storage) return 0;
    
    try {
      const prefixedKey = this.prefix + key;
      this.storage.removeItem(prefixedKey);
      
      // Also remove from TTLs if exists
      if (this.ttls[prefixedKey]) {
        delete this.ttls[prefixedKey];
        this.storage.setItem(`${this.prefix}ttls`, JSON.stringify(this.ttls));
      }
      
      return 1;
    } catch (error) {
      console.error('Error deleting Redis key:', error);
      return 0;
    }
  }

  /**
   * Check if a key has expired
   * @param prefixedKey The key to check (with prefix)
   */
  private isExpired(prefixedKey: string): boolean {
    const expiryTimestamp = this.ttls[prefixedKey];
    if (!expiryTimestamp) return false;
    
    return Date.now() > expiryTimestamp;
  }

  /**
   * Clean up expired keys
   */
  private cleanupExpiredKeys(): void {
    if (!this.storage) return;
    
    try {
      // Load stored TTLs
      const ttlsJson = this.storage.getItem(`${this.prefix}ttls`);
      if (ttlsJson) {
        this.ttls = JSON.parse(ttlsJson);
      }
      
      // Check for expired keys
      const now = Date.now();
      let hasChanges = false;
      
      for (const [prefixedKey, expiryTimestamp] of Object.entries(this.ttls)) {
        if (now > expiryTimestamp) {
          // Key has expired, remove it
          this.storage.removeItem(prefixedKey);
          delete this.ttls[prefixedKey];
          hasChanges = true;
        }
      }
      
      // Update stored TTLs if changes were made
      if (hasChanges) {
        this.storage.setItem(`${this.prefix}ttls`, JSON.stringify(this.ttls));
      }
    } catch (error) {
      console.error('Error cleaning up expired Redis keys:', error);
    }
  }
}

// Create a singleton instance
export const redisClient = new BrowserRedisClient();

// In server environment, we would use a real Redis client
// But for the browser, we use the mock implementation above
export default redisClient;

// Redis URL with fallback
export const redisUrl = import.meta.env.VITE_REDIS_URL || "redis://default:ARleqJLc3fhzeYQeZETVtiZRN3qGjqVk@redis-19431.crce182.ap-south-1-1.ec2.redns.redis-cloud.com:19431";

// Log a warning if using fallback
if (import.meta.env.VITE_REDIS_URL === undefined) {
  console.warn('Using fallback Redis URL. This is only recommended for development.');
}

/**
 * Set a value in Redis cache with TTL
 */
export async function setCache<T>(key: string, value: T, ttl: number = DEFAULT_TTL): Promise<void> {
  try {
    await redisClient.set(key, JSON.stringify(value), ttl);
  } catch (error) {
    console.error(`Browser cache set error for key ${key}:`, error);
  }
}

/**
 * Get a value from Redis cache
 */
export async function getCache<T>(key: string): Promise<T | null> {
  try {
    const data = await redisClient.get(key);
    if (!data) return null;
    return JSON.parse(data) as T;
  } catch (error) {
    console.error(`Browser cache get error for key ${key}:`, error);
    return null;
  }
}

/**
 * Delete a value from Redis cache
 */
export async function deleteCache(key: string): Promise<void> {
  try {
    await redisClient.del(key);
  } catch (error) {
    console.error(`Browser cache delete error for key ${key}:`, error);
  }
}

/**
 * Cache keys for routes
 */
export const CACHE_KEYS = {
  ALL_ROUTES: 'routes:all',
  ROUTES_BY_TYPE: (type: string) => `routes:type:${type}`,
};

/**
 * Get routes TTL value
 */
export function getRoutesTTL(): Record<string, number> {
  return {
    "/": 60 * 5, // 5 minutes
    "/about": 60 * 60, // 1 hour
    "/solutions/products": 60 * 60, // 1 hour
    "/solutions/platform": 60 * 60, // 1 hour
    "/legal/terms": 60 * 60 * 24, // 1 day
    "/legal/privacy": 60 * 60 * 24, // 1 day
    "/support/faq": 60 * 60 * 2, // 2 hours
    "/support/contact": 60 * 60 * 2, // 2 hours
  };
} 