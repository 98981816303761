import Analyze from "../assets/Analyze-1.png";

export const HeroSection = () => {
  return (
    <div className="w-[100%] mx-auto text-white pt-14 bg-gradient-to-b from-[#000000] via-[#000000] to-[#140029]">
      {/* Hero Section */}
      <div className="w-[85%] mx-auto">
        <div className="lg:text-6xl md:text-5xl xs:text-5xl xxs:text-5xl mb-8">
          <div className="font-bold mb-2.5">
            Valuefy: Building Efficiency by
          </div>
          <div className="font-bold bg-gradient-to-r from-[#8D8DFF] via-purple-400 to-purple-600 text-transparent bg-clip-text leading-[1.2] mb-2">
            Breaking Organisation Silos
          </div>
        </div>
        <h2 className="text-2xl font-semibold mb-4">
          One Platform. All Your WealthTech Needs.
        </h2>
        <p className="text-gray-400 text-lg leading-relaxed mb-16">
          Wealth management firms have a problem and no one seems to solve it.
          They operate in fragmented silos, environments that have multiple
          systems. They keep juggling between portfolio management to reporting
          to compliance, and then execution—leading to huge inefficiencies, a
          frustratingly wasted time, and an operational drag they just have
          learnt to live with.
        </p>
      </div>
      <div className="w-[85%] mx-auto pb-4">
        <img src={Analyze} alt="Analyze" className="" />
      </div>
    </div>
  );
};
