import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import { AuthError, CountryCode, ConfirmationResult } from '../../utils/types/auth';
import { validatePhone } from '../../utils/validation';
import OTPVerification from './OTPVerification';
import { CountrySelector, detectCountry } from './CountrySelector';
import { phoneAuth } from '../../utils/firebase';
import { resetScrollPosition } from '../../utils/scrollHelpers';
import { trackUserActivity } from '../../services/trackingService';
import './auth.css';
import { MAIN_ROUTES } from "../../constants/routes";

interface SignInFormData {
  phoneNumber: string;
  countryCode: string;
  rememberMe: boolean;
}

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignInFormData>({
    phoneNumber: '',
    countryCode: '+91',
    rememberMe: false,
  });
  const [errors, setErrors] = useState<AuthError>({});
  const [showOTP, setShowOTP] = useState(false);
  const [otpError, setOtpError] = useState<string | undefined>(undefined);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    code: 'IN',
    name: 'India',
    dialCode: '+91',
    flag: '🇮🇳',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);

  useEffect(() => {
    // Reset scroll position when component mounts
    resetScrollPosition();
    
    const initCountry = async () => {
      const country = await detectCountry();
      setSelectedCountry(country);
      setFormData(prev => ({ ...prev, countryCode: country.dialCode }));
    };
    initCountry();

    // Extract redirect URL from query parameters if present
    const queryParams = new URLSearchParams(window.location.search);
    const redirect = queryParams.get('redirect');
    if (redirect) {
      setRedirectUrl(redirect);
    }
  }, []);

  // Clean up reCAPTCHA when component unmounts
  useEffect(() => {
    return () => {
      phoneAuth.clearRecaptcha();
    };
  }, []);

  const validateForm = (): AuthError => {
    const errors: AuthError = {};

    if (!formData.phoneNumber.trim()) {
      errors.identifier = 'Please enter your phone number';
    } else if (!validatePhone(formData.phoneNumber)) {
      errors.identifier = 'Please enter a valid phone number';
    }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm();
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
      const response = await phoneAuth.sendOTP(fullPhoneNumber, 'sign-in-button');
      
      if (response.success && response.data?.confirmationResult) {
        setConfirmationResult(response.data.confirmationResult);
        setShowOTP(true);
        setErrors({});
      } else {
        // Handle specific Firebase errors
        if (response.error?.code === 'auth/billing-not-enabled') {
          setErrors({ 
            identifier: 'Phone authentication is currently unavailable. Please try again later or contact support.'
          });
          console.error('Firebase billing needs to be enabled for phone auth');
        } else if (response.error?.code === 'auth/invalid-phone-number') {
          setErrors({ 
            identifier: 'The phone number is invalid. Please check the country code and number.'
          });
        } else if (response.error?.code === 'auth/quota-exceeded') {
          setErrors({ 
            identifier: 'Too many requests. Please try again later.'
          });
        } else {
          setErrors({ 
            identifier: response.error?.message || 'Failed to send verification code. Please try again.' 
          });
        }
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setErrors({ identifier: 'An unexpected error occurred. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (data: { otp: string }) => {
    if (!confirmationResult) {
      setOtpError('Please request a new verification code');
      return;
    }

    setIsLoading(true);
    try {
      const response = await phoneAuth.verifyOTP(confirmationResult, data.otp);
      
      if (response.success && response.data?.user) {
        // Track the user login
        try {
          const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
          await trackUserActivity(response.data.user.uid, 'login', {
            method: 'phone_otp',
            phone: fullPhoneNumber,
            timestamp: new Date().toISOString()
          });
          
          // Generate browser fingerprint for security
          const fingerprint = generateBrowserFingerprint();
          
          // Always store user data in localStorage for authentication
          localStorage.setItem('user', JSON.stringify({
            uid: response.data.user.uid,
            phoneNumber: fullPhoneNumber,
            timestamp: new Date().toISOString()
          }));
          
          // Store fingerprint separately for security validation
          localStorage.setItem('auth_fingerprint', fingerprint);
          
          console.log('Authentication successful, redirecting...');
          
          // Handle redirection after successful login
          if (redirectUrl) {
            console.log(`Redirecting to: ${decodeURIComponent(redirectUrl)}`);
            
            // If it's a full URL with origin, use it as is
            // Otherwise add the current origin
            let redirectTarget = decodeURIComponent(redirectUrl);
            if (!redirectTarget.startsWith('http')) {
              redirectTarget = `${window.location.origin}${redirectTarget}`;
            }
            
            // Use location.replace for a clean navigation
            window.location.replace(redirectTarget);
          } else {
            navigate('/');
          }
        } catch (trackingError) {
          // Log but don't interrupt the flow if tracking fails
          console.error('Error tracking login:', trackingError);
          
          // Still redirect even if tracking fails
          if (redirectUrl) {
            let redirectTarget = decodeURIComponent(redirectUrl);
            if (!redirectTarget.startsWith('http')) {
              redirectTarget = `${window.location.origin}${redirectTarget}`;
            }
            window.location.replace(redirectTarget);
          } else {
            navigate('/');
          }
        }
      } else {
        setOtpError(response.error?.message || 'Invalid verification code');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setOtpError('Failed to verify code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  // Generate a simple browser fingerprint for security
  const generateBrowserFingerprint = () => {
    if (typeof window === 'undefined') return '';
    
    const userAgent = navigator.userAgent;
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const colorDepth = window.screen.colorDepth;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
    // Create a simple hash
    const fingerprintSource = `${userAgent}|${screenWidth}|${screenHeight}|${colorDepth}|${timezone}`;
    return btoa(fingerprintSource).substring(0, 40);
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    try {
      const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
      const response = await phoneAuth.sendOTP(fullPhoneNumber, 'sign-in-button');
      
      if (response.success && response.data?.confirmationResult) {
        setConfirmationResult(response.data.confirmationResult);
        setOtpError(undefined);
      } else {
        setOtpError(response.error?.message || 'Failed to resend code. Please try again.');
      }
    } catch (err) {
      console.error('Error resending OTP:', err);
      setOtpError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (showOTP) {
    return (
      <AuthLayout
        title="Verify Your Account"
        subtitle="Enter the verification code we sent you"
      >
        <OTPVerification
          identifier={formData.phoneNumber}
          onVerify={handleVerifyOTP}
          onResend={handleResendOTP}
          onBack={() => setShowOTP(false)}
          error={otpError}
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title="Welcome Back"
      subtitle="Sign in with your phone number"
    >
      <div className="w-full">
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6">
          <div>
            <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
              Phone Number
            </label>
            <div className="flex gap-1 sm:gap-2">
              <CountrySelector
                selectedCountry={selectedCountry}
                onSelect={(country) => {
                  setSelectedCountry(country);
                  setFormData(prev => ({ ...prev, countryCode: country.dialCode }));
                }}
                className="flex-shrink-0"
                disabled={isLoading}
              />
              <input
                type="tel"
                id="phoneNumber"
                placeholder="Enter your mobile number"
                value={formData.phoneNumber}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, ''); // Only allow digits
                  setFormData(prev => ({ ...prev, phoneNumber: value }));
                  if (errors.identifier) setErrors({});
                }}
                className="flex-grow bg-black/50 border border-gray-800 rounded-lg px-2 sm:px-4 py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                maxLength={15}
                disabled={isLoading}
                inputMode="numeric"
                autoComplete="tel"
              />
            </div>
            {errors.identifier && (
              <p className="mt-2 text-xs sm:text-sm text-red-500">{errors.identifier}</p>
            )}
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="remember"
              checked={formData.rememberMe}
              onChange={(e) => setFormData(prev => ({ ...prev, rememberMe: e.target.checked }))}
              className="h-4 w-4 rounded border-gray-800 bg-black/50 text-purple-500 focus:ring-purple-500"
              disabled={isLoading}
            />
            <label htmlFor="remember" className="ml-2 block text-xs sm:text-sm text-gray-300">
              Remember this device
            </label>
          </div>

          <button
            type="submit"
            className={`w-full bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white font-medium py-3 px-4 rounded-lg hover:opacity-90 transition-opacity text-sm sm:text-base ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
            disabled={isLoading}
          >
            {isLoading ? 'Sending...' : 'Send Verification Code'}
          </button>

          {/* reCAPTCHA container - invisible but positioned where we want it */}
          <div 
            id="sign-in-button" 
            className="recaptcha-container"
          ></div>

          <p className="text-center text-sm">
            Don't have an account?{" "}
            <Link to={MAIN_ROUTES.SIGNUP.path} className="text-purple-400 hover:text-purple-300">
              Sign up
            </Link>
          </p>
        </form>
      </div>
    </AuthLayout>
  );
};

export default SignIn; 