import scrollerImg from "../assets/scrollerImg.png";
import scrollerImg2 from '../assets/scrollerimg2.png';
import scrollerImg3 from '../assets/scrollerimg3.png';

interface StageProps {
  id?: string;
}

export const Stage: React.FC<StageProps> = ({ id }) =>  {
  return (
    <div id={id} className="w-[100%] mx-auto">
      {/* Stage 1 */}
      <div className="">
        <div className="text-3xl text-[#64748B] text-center mb-6 mt-20 font-bold">
          Stage 1
        </div>
        <div className="text-3xl text-white font-bold text-center mb-5">
          Understanding Your Technology Landscape
        </div>
        <p className="w-[80%] mx-auto text-white text-[21px] text-center mb-14">
          Our team works with you to do a structured evaluation of your existing
          systems, workflows, and data architecture in order to ensures seamless
          integration and identify the gaps you have
        </p>
        <div className="pb-10">
          <table className="w-[70%] mx-auto border-collapse text-white border border-gray-300">
            <thead>
              <tr className="">
                <th className="border border-gray-300 px-4 py-5 text-left">
                  Assessment Area
                </th>
                <th className="border border-gray-300 px-4 py-5 text-left">
                  Key Considerations
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="">
                <td className="border border-gray-300 px-4 py-5">
                  System Inventory
                </td>
                <td className="border border-gray-300 px-4 py-5">
                  Legacy software, databases, CRM tools
                </td>
              </tr>
              <tr className="">
                <td className="border border-gray-300 px-4 py-5">Pin Points</td>
                <td className="border border-gray-300 px-4 py-5">
                  Manual processes, data silos, reporting inefficiencies
                </td>
              </tr>
              <tr className="">
                <td className="border border-gray-300 px-4 py-5">
                  Data Quality
                </td>
                <td className="border border-gray-300 px-4 py-5">
                  Accuracy, completeness, consistency
                </td>
              </tr>
              <tr className="">
                <td className="border border-gray-300 px-4 py-5">
                  Integration Capabilities
                </td>
                <td className="border border-gray-300 px-4 py-5">
                  APIs, data formats, scalability
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="text-white text-center">
          <div className="text-2xl font-bold mb-6">Why It Matters</div>
          <p className="w-[70%] mx-auto text-[20px] mb-6">
            Understanding where inefficiencies exist helps us tailor an
            integration strategy that eliminates bottlenecks and enhances
            performance.
          </p>
          <button className="bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-8 py-2 rounded-md font-bold mb-20">
            Start Your Technology Audit
          </button>
        </div>
      </div>

      {/* Stage 2 */}
      <div className="">
        <div className="text-3xl text-[#64748B] text-center mb-6 mt-12 font-bold">
          Stage 2
        </div>
        <div className="text-3xl text-white font-bold text-center mb-5">
          Tailored Integration Strategy
        </div>
        <p className="w-[80%] mx-auto text-white text-[21px] text-center mb-14">
          Once the assessment is complete, we develop a customized integration
          strategy that ensures zero disruption and maximum efficiency. Cause
          lets face it, we know you have a running business and a business to
          run. We have one single goal, how to make you scale.
        </p>

        <div className="w-[80%] mx-auto mt-20 mb-20">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="w-[100%] md:w-[40%]">
              <img src={scrollerImg} />
            </div>
            <div className="w-[100%] md:w-[40%]">
              <div className="text-white text-2xl font-bold leading-normal">
                Prioritizing Critical Systems
              </div>
              <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4" />
              <div className="h-[270px] overflow-y-auto scrollbar-left ">
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    No More Fragmented Order Processing
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Execute trades, manage orders, and reconcile
                    transactions—all in one place.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Unified Client & Advisor Dashboards
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Eliminate duplicate data and gain one true view of wealth.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Seamless Data Aggregation
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Integrates custodians, banks, and portfolio systems into a
                    single framework.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Automated Portfolio Monitoring
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Instantly track performance, risk, and compliance across all
                    investments.
                  </div>
                </div>
              </div>
              <button className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">
                Streamline Your Reporting
              </button>
            </div>
          </div>
        </div>

        <div className="text-3xl text-[#64748B] text-center mb-6 mt-12 font-bold">
          Stage 3
        </div>
        <div className="text-3xl text-white font-bold text-center mb-5">
          Training & Change Management
        </div>
        <p className="w-[80%] mx-auto text-white text-[21px] text-center mb-14">
          A technology shift is only successful if people are empowered to use
          it effectively. Most companies lack the training and change
          management, they leave it to systems or business, but we prefer
          partnering. We partner in this change management.
        </p>

        <div className="w-[80%] mx-auto mt-8">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="w-[100%] md:w-[40%]">
              <div className="text-white text-2xl font-bold leading-normal">
                Role Based Training
              </div>
              <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4" />
              <div className="h-[270px] overflow-y-auto scrollbar-left ">
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    One-click Reporting
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Automate and standardize reporting across multi-asset class
                    portfolios.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Unified Client & Advisor Dashboards
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Eliminate duplicate data and gain one true view of wealth.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Seamless Data Aggregation
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Integrates custodians, banks, and portfolio systems into a
                    single framework.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Automated Portfolio Monitoring
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Instantly track performance, risk, and compliance across all
                    investments.
                  </div>
                </div>
              </div>
              <button className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">
                Streamline Your Reporting
              </button>
            </div>
            <div className="w-[100%] md:w-[40%]">
              <img src={scrollerImg2} />
            </div>
          </div>
        </div>

        <div className="text-3xl text-[#64748B] text-center mb-6 mt-20 font-bold">
          Stage 4
        </div>
        <div className="text-3xl text-white font-bold text-center mb-5">
          Ongoing Support & Optimization
        </div>
        <p className="w-[80%] mx-auto text-white text-[21px] text-center mb-14">
          Technology isn't just about implementation—it's about continuous
          enhancement to drive maximum impact.
        </p>

        <div className="w-[80%] mx-auto mt-20 md:pb-[120px]">
          <div className="flex justify-between flex-col md:flex-row">
            <div className="w-[100%] md:w-[40%]">
              <img src={scrollerImg3} />
            </div>
            <div className="w-[100%] md:w-[40%]">
              <div className="text-white text-2xl font-bold leading-normal">
                Regular System Health Checks
              </div>
              <div className="bg-gray-400 w-[80%] h-[2px] mt-4 mb-4" />
              <div className="h-[270px] overflow-y-auto scrollbar-left ">
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Performance Monitoring & Tuning
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Execute trades, manage orders, and reconcile
                    transactions—all in one place.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Unified Client & Advisor Dashboards
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Eliminate duplicate data and gain one true view of wealth.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Seamless Data Aggregation
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Integrates custodians, banks, and portfolio systems into a
                    single framework.
                  </div>
                </div>
                <div className="mb-4">
                  <div className="text-white text-[21px] font-bold mb-1 hover:text-violet-400 cursor-pointer">
                    Automated Portfolio Monitoring
                  </div>
                  <div className="text-white text-[18px] text-[#94A3B8]">
                    Instantly track performance, risk, and compliance across all
                    investments.
                  </div>
                </div>
              </div>
              <button className="bg-gradient-to-b from-[#6764EA] to-[#B17EEB] text-white px-4 py-2 rounded-md mb-8 mt-8">
                Streamline Your Reporting
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
