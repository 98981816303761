import { useState, useEffect } from "react";
import logo from "../assets/Logo.png";
import { PRICING_APPROACH, PRICING_NAV_SECTIONS } from "../../../constants/pricing";

interface ApproachProps {
  id?: string;
}

export const Approach : React.FC<ApproachProps> = ({ id }) => {
  const [activeSection, setActiveSection] = useState("approach");
  const [isSticky, setIsSticky] = useState(false);
  
  // Define valid sections array
  const validSections = PRICING_NAV_SECTIONS.map(section => section.id);

  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      // Get the element's position
      const elementPosition = element.getBoundingClientRect().top;
      // Get the current scroll position
      const offsetPosition = elementPosition + window.pageYOffset - 80; // 80px offset for navbar height
      
      // Scroll to the offset position
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  // Function to determine which section is currently in view
  const determineActiveSection = () => {
    let current = "approach";
    
    // Only process valid sections
    validSections.forEach((sectionId) => {
      const section = document.getElementById(sectionId);
      if (section) {
        const rect = section.getBoundingClientRect();
        // If the section is in the viewport (with some buffer for better UX)
        if (rect.top <= 150 && rect.bottom >= 150) {
          current = sectionId;
        }
      }
    });
    
    // Check if the id prop is one of the valid sections
    // If not, don't highlight any section
    if (id && !validSections.includes(id)) {
      return "";  // Return empty string to ensure no highlighting
    }
    
    return current;
  };

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      // Check if the approach section's nav should be sticky
      const approachElement = document.getElementById("approach");
      if (approachElement) {
        const approachRect = approachElement.getBoundingClientRect();
        const shouldBeSticky = approachRect.top <= 0;
        setIsSticky(shouldBeSticky);
      }
      
      // Update the active section
      setActiveSection(determineActiveSection());
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [id]); // Add id as a dependency

  // Set initial active section based on the id prop
  useEffect(() => {
    // If id is not in validSections, set activeSection to empty string
    if (id && !validSections.includes(id)) {
      setActiveSection("");
    }
  }, [id, validSections]);


  return (
    <div id={id} className="w-full mx-auto">
      <div 
        className={`w-full bg-black ${isSticky ? "fixed top-0 left-0 z-50 shadow-md" : ""}`}
      >
        <div className="w-[75%] mx-auto flex justify-between items-center gap-5 pt-5 pb-5 overflow-x-auto xs:overflow-x-auto">
          {PRICING_NAV_SECTIONS.map((section) => (
            <div 
              key={section.id}
              className={`text-white text-xl cursor-pointer transition-colors ${activeSection === section.id ? "text-[#B770FF]" : "hover:text-[#B770FF]"}`}
              onClick={() => scrollToSection(section.id)}
            >
              {section.label}
            </div>
          ))}
        </div>

        <div className="flex justify-between items-center relative">
          <div className="w-[80%] mx-auto h-[1px] bg-gray-700 mt-1 mb-0"></div>
        </div>
      </div>

      {/* Add spacer div when navbar becomes sticky */}
      {isSticky && <div className="h-16"></div>}

      <div className="w-[90%] mx-auto flex justify-between items-center gap-5 flex-wrap mt-10">
        <div className="lg:w-[45%] w-full text-white">
          <div className="text-4xl xxs:pt-10 font-bold mb-8">
            {PRICING_APPROACH.title}
          </div>
          <p className="text-[21px] mb-8">
            {PRICING_APPROACH.description}
          </p>
          <p className="text-[21px] mb-5 font-bold">
            {PRICING_APPROACH.tagline}
          </p>
          <button className="bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-8 py-2 rounded-md">
            {PRICING_APPROACH.ctaText}
          </button>
        </div>
        <div className="lg:w-[45%] w-full">
          <img src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};