import { Helmet } from 'react-helmet-async';
import { MAIN_ROUTES } from '../../constants/routes';
import { DYNAMIC_MAIN_ROUTES } from '../../constants/dynamicRoutes';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  ogImage?: string;
  ogType?: string;
  ogUrl?: string;
  canonical?: string;
}

// Get default values from the HOME route, prioritizing dynamic routes if available
const homeRoute = DYNAMIC_MAIN_ROUTES.HOME || MAIN_ROUTES.HOME;
const homeMetadata = homeRoute.metadata;
const defaultTitle = homeMetadata.title;
const defaultDescription = homeMetadata.description;
const defaultKeywords = homeMetadata.keywords || '';
const defaultOgImage = homeMetadata.ogImage || '/og-image.jpg';
const defaultOgType = homeMetadata.ogType || 'website';

export const SEO = ({
  title = defaultTitle,
  description = defaultDescription,
  keywords = defaultKeywords,
  ogImage = defaultOgImage,
  ogType = defaultOgType,
  ogUrl,
  canonical,
}: SEOProps) => {
  // Construct full title with brand name if custom title is provided
  const fullTitle = title !== defaultTitle ? `${title} | Valuefy` : title;
  
  // Get current URL if ogUrl or canonical isn't explicitly provided
  const currentUrl = ogUrl || canonical || (typeof window !== 'undefined' ? window.location.href : '');

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{fullTitle}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      
      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={fullTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={ogType} />
      {ogImage && <meta property="og:image" content={ogImage} />}
      {currentUrl && <meta property="og:url" content={currentUrl} />}
      
      {/* Twitter Card Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={fullTitle} />
      <meta name="twitter:description" content={description} />
      {ogImage && <meta name="twitter:image" content={ogImage} />}
      
      {/* Canonical Link */}
      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
};

export default SEO; 