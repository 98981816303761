import React from "react";
import valuefyLogo from "../assets/company/valuefy-logo.png";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTES, LEGAL_ROUTES, SUPPORT_ROUTES, SOCIAL_LINKS } from "../constants/routes";

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  return (
    <footer className="bg-black text-white px-4 sm:px-6 py-8 sm:py-10 md:py-16">
      <div className="max-w-7xl mx-auto">
        {/* Top Section with Heading and Contact */}
        <div className="flex flex-col md:flex-row justify-between mb-8 sm:mb-10 md:mb-12 pb-8 sm:pb-10 md:pb-12 border-b border-gray-800">
          {/* Left - Tagline */}
          <div className="mb-8 md:mb-0 h-auto md:h-64">
            <h2 className="text-4xl sm:text-5xl md:text-8xl font-bold mb-1 mt-4 sm:mt-8 md:mt-12 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">
              Wealth.
            </h2>
            <h2 className="text-4xl sm:text-5xl md:text-8xl font-bold bg-gradient-to-r from-gray-100 via-gray-500 to-gray-400 bg-clip-text text-transparent">
              Simplified.
            </h2>
          </div>

          {/* Right - Get in Touch */}
          <div className="max-w-xs mt-4 sm:mt-8 md:mt-12">
            <h3 className="text-3xl sm:text-4xl md:text-5xl font-semibold mb-3 sm:mb-4 md:mb-6">
              Get in Touch
            </h3>
            <p className="text-sm text-gray-400 mb-4">
              We're here to assist you with any inquiries or support you may
              need.
            </p>
            <div className="flex space-x-4">
              <a
                href={SOCIAL_LINKS.LINKEDIN}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                </svg>
              </a>
              <a
                href={SOCIAL_LINKS.TWITTER}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M17.625 3h3.833l-7.92 8.947L22 21h-7.22l-4.92-6.514L4.5 21H.667l8.46-9.548L2 3h7.313l4.42 5.926L17.625 3zm-1.046 16h2.064L6.98 4.06H4.802L16.58 19z" />
                </svg>
              </a>
              <a
                href={SOCIAL_LINKS.FACEBOOK}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              <a
                href={SOCIAL_LINKS.YOUTUBE}
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white"
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                </svg>
              </a>
            </div>
          </div>
        </div>

        {/* Links Section - Responsive grid layout */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-6 sm:gap-8 mb-8 sm:mb-10 md:mb-12">
          {/* Quick Links */}
          <div className="col-span-1">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.ABOUT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  About Us
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.HOME.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Services
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.CONTACT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Contact Us
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.BLOGS.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Blog
                </button>
              </li>
              {/* <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.CAREERS.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Careers
                </button>
              </li> */}
            </ul>
          </div>

          {/* Resources */}
          {/* <div className="col-span-1">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Resources</h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.FAQ.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  FAQ
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.PRIVACY_POLICY.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.TERMS.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Terms of Service
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.SUPPORT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Support
                </button>
              </li>
            </ul>
          </div> */}

          {/* Follow Us */}
          <div className="col-span-1">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Follow Us</h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => window.open(SOCIAL_LINKS.FACEBOOK, "_blank")}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Facebook
                </button>
              </li>
              <li>
                <button
                  onClick={() => window.open(SOCIAL_LINKS.TWITTER, "_blank")}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  X
                </button>
              </li>
              <li>
                <button
                  onClick={() => window.open(SOCIAL_LINKS.LINKEDIN, "_blank")}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  LinkedIn
                </button>
              </li>
              <li>
                <button
                  onClick={() => window.open(SOCIAL_LINKS.YOUTUBE, "_blank")}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  YouTube
                </button>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          {/* <div className="col-span-1 mt-6 sm:mt-0">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Contact Info</h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.EMAIL_US.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Email Us
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.CALL_US.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Call Us
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.VISIT_US.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Visit Us
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.FEEDBACK.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Feedback
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(SUPPORT_ROUTES.HELP_CENTER.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Help Center
                </button>
              </li>
            </ul>
          </div> */}

          {/* Legal */}
          {/* <div className="col-span-1 mt-6 sm:mt-0">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Legal</h4>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.COOKIE_POLICY.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Cookie Policy
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.DATA_PROTECTION.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Data Protection
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.USER_AGREEMENT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  User Agreement
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.ACCESSIBILITY.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Accessibility Statement
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(LEGAL_ROUTES.INTELLECTUAL_PROPERTY.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Intellectual Property
                </button>
              </li>
            </ul>
          </div> */}

          {/* Company */}
          <div className="col-span-1 mt-6 sm:mt-0">
            <h4 className="text-sm font-semibold mb-3 sm:mb-4">Company</h4>
            <ul className="space-y-2">
            <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.TEAM.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Our Team
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.ABOUT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Our Story
                </button>
              </li>
              {/* <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.TEAM.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Our Team
                </button>
              </li> */}
              {/* <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.ABOUT.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Our Values
                </button>
              </li> */}
              {/* <li>
                <button
                  onClick={() => handleNavigate(MAIN_ROUTES.CAREERS.path)}
                  className="text-xs text-gray-400 hover:text-white"
                >
                  Careers
                </button>
              </li> */}
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row items-center justify-between pt-6 sm:pt-8 border-t border-gray-800">
          {/* Logo */}
          <div className="mb-4 md:mb-0 w-32 sm:w-40">
            <a 
              href="#" 
              onClick={(e) => {
                e.preventDefault();
                handleNavigate(MAIN_ROUTES.HOME.path);
              }}
              className="flex items-center"
            >
              <img src={valuefyLogo} alt="Valuefy" className="w-full h-auto" />
            </a>
          </div>

          {/* Copyright */}
          <div className="text-xs text-gray-500 text-center md:text-left">
            © {currentYear} Valuefy. All Rights Reserved.
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleNavigate(LEGAL_ROUTES.PRIVACY_POLICY.path);
              }}
              className="text-gray-500 hover:text-gray-300 ml-1 block md:inline mt-1 md:mt-0"
            >
              Compliance & Disclosures
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
