import React from "react";
import BackgroundGradient from "../assets/background/gradient-bg.png";
import Dashboard from "../assets/platform/dashboard.png";
import AIPoweredAnalysis from "../assets/platform/ai-powered-analysis.png";
import FastExecution from "../assets/platform/execution.png";
import Collaboration from "../assets/platform/collab.png";
import Compilance from "../assets/platform/compilance.png";
import Transaction from "../assets/platform/txns.png";
import UpperGradient from "../assets/background/upper-gradient.png";
import { AuroraText } from "./magicui/aurora-text";
import { useNavigate } from "react-router-dom";

// Feature card component for reusable sections
interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  imageSrc: string;
  learnMoreLink?: string;
  imageOnRight?: boolean;
}

const shouldImageBeOnRight = (index: number): boolean => {
  return index % 2 === 0;
};

const FeatureCard: React.FC<FeatureCardProps & { index?: number }> = ({
  icon,
  title,
  description,
  imageSrc,
  learnMoreLink,
  imageOnRight = true,
  index,
}) => {
  // If index is provided, it will override the imageOnRight prop
  const finalImageOnRight =
    index !== undefined ? shouldImageBeOnRight(index) : imageOnRight;

  return (
    <div className="flex flex-col md:flex-row items-center justify-center py-8 px-4 md:py-10 md:px-12 lg:py-16 w-full lg:px-20">
      {/* Wrapper div to contain both sections with equal width */}
      <div className="flex flex-col md:flex-row w-full max-w-6xl gap-8 justify-center items-center">
        {/* Content section - will be on left or right based on finalImageOnRight */}
        <div
          className={`flex flex-col gap-4 w-full md:w-1/2 px-4 md:px-8 platform-card ${
            finalImageOnRight ? "md:order-first" : "md:order-last"
          } mb-6 md:mb-0`}
        >
          <div className="bg-white w-12 h-12 md:w-16 md:h-16 rounded-lg flex items-center justify-center">
            {icon}
          </div>
          <h3 className="text-lg md:text-xl font-semibold text-white">
            {title}
          </h3>
          <p className="text-gray-300 text-base md:text-xl">{description}</p>
          {learnMoreLink && (
            <a
              href={learnMoreLink}
              className="text-white flex items-center gap-1 text-sm mt-2 md:mt-4 group relative w-fit"
            >
              <span className="font-medium">Learn More</span>
              <svg
                className="w-3 h-3 md:w-4 md:h-4 transition-transform duration-300 group-hover:translate-x-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-white transition-all duration-300 group-hover:w-full"></span>
            </a>
          )}
        </div>

        {/* Image section */}
        <div
          className={`w-full md:w-1/2 flex justify-center items-center ${
            finalImageOnRight ? "md:order-last" : "md:order-first"
          }`}
        >
          <img
            src={imageSrc}
            alt={title}
            className="rounded-lg w-full h-auto object-cover shadow-lg"
          />
        </div>
      </div>
    </div>
  );
};

const Platform: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signup");
  };

  // State to track window width
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Effect to update window width on resize
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define tablet breakpoint (e.g., 1024px)
  const tabletBreakpoint = 1024;

  return (
    <div className="relative bg-black min-h-screen w-full text-white overflow-x-hidden">
      {/* Background image */}
      <img
        src={BackgroundGradient}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover z-0"
      />

      <div className="relative z-10">
        {/* Full-width background image */}
        <img
          src={UpperGradient}
          alt="Decorative"
          className="absolute top-0 left-0 w-full"
        />
        {/* Content container */}
        <div className="relative z-10 container mx-auto px-4 py-8 md:py-16">
          {/* Hero section */}
          <div className="text-center mb-8 md:mb-12">
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-2 md:mb-4">
              All-in-One Platform.
            </h1>
            {windowWidth <= tabletBreakpoint ? (
              // Gradient purple text for mobile and tablet
              <span className="text-3xl md:text-4xl lg:text-5xl font-semibold mb-3 md:mb-5 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-purple-900">
                Infinite Possibilities.
              </span>
            ) : (
              // Aurora text for desktop
              <AuroraText
                className="text-3xl md:text-4xl lg:text-5xl text-purple-300 font-semibold mb-3 md:mb-5"
                colors={[
                  "#d946ef",
                  "#c026d3",
                  "#9333ea",
                  "#7c3aed",
                  "#6366f1",
                  "#3b82f6",
                  "#0ea5e9",
                ]}
                speed={2}
              >
                {" "}
                Infinite Possibilities.
              </AuroraText>
            )}
            <p className="text-gray-300 text-sm md:text-base max-w-2xl mx-auto">
              Seamless Wealth orchestration - Built for Modern Wealth Management
            </p>
          </div>

          {/* Features section */}
          <div className="flex flex-col w-full gap-6 md:gap-10">
            {/* Dashboard Feature */}
            <FeatureCard
              index={0}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                >
                  <g id="grid-5">
                    <path
                      id="Vector"
                      d="M10.9974 2.66602V29.3327H10.4107C5.5574 29.3327 2.66406 26.4394 2.66406 21.586V10.4127C2.66406 5.55935 5.5574 2.66602 10.4107 2.66602H10.9974Z"
                      fill="url(#paint0_linear_1250_230)"
                    />
                    <path
                      id="Vector_2"
                      d="M29.3333 10.4127V14.9993H13V2.66602H21.5867C26.44 2.66602 29.3333 5.55935 29.3333 10.4127Z"
                      fill="url(#paint1_linear_1250_230)"
                    />
                    <path
                      id="Vector_3"
                      d="M29.3333 17V21.5867C29.3333 26.44 26.44 29.3333 21.5867 29.3333H13V17H29.3333Z"
                      fill="url(#paint2_linear_1250_230)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_230"
                      x1="6.83073"
                      y1="2.66602"
                      x2="6.83073"
                      y2="29.3327"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_230"
                      x1="21.1667"
                      y1="2.66602"
                      x2="21.1667"
                      y2="14.9993"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1250_230"
                      x1="21.1667"
                      y1="17"
                      x2="21.1667"
                      y2="29.3333"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="Advanced Dashboards"
              description="Get a 360° view of your client portfolios with real-time insights and interactive visualizations."
              imageSrc={Dashboard}
              learnMoreLink="/signup"
            />

            {/* AI Analytics Feature */}
            <FeatureCard
              index={1}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                >
                  <g id="illustrator">
                    <path
                      id="Vector"
                      d="M21.5841 2.79004H10.4107C5.5574 2.79004 2.66406 5.68337 2.66406 10.5367V21.6967C2.66406 26.55 5.5574 29.4434 10.4107 29.4434H21.5707C26.4241 29.4434 29.3174 26.55 29.3174 21.6967V10.5367C29.3307 5.68337 26.4374 2.79004 21.5841 2.79004ZM17.4107 22.3634C17.3041 22.4034 17.1974 22.4167 17.0907 22.4167C16.6774 22.4167 16.2774 22.15 16.1441 21.7367L15.1974 18.8967H11.0907L9.78406 21.83C9.5574 22.3367 8.97073 22.5634 8.46406 22.3367C7.9574 22.11 7.73073 21.5234 7.9574 21.0167L12.6641 10.4434C12.8374 10.07 13.1974 9.81669 13.6241 9.85669C14.0374 9.87002 14.3974 10.15 14.5307 10.5367L18.0507 21.11C18.2107 21.6167 17.9307 22.19 17.4107 22.3634ZM22.7974 21.4167C22.7974 21.9634 22.3441 22.4167 21.7974 22.4167C21.2507 22.4167 20.7974 21.9634 20.7974 21.4167V14.3634C20.7974 13.8167 21.2507 13.3634 21.7974 13.3634C22.3441 13.3634 22.7974 13.8167 22.7974 14.3634V21.4167ZM23.0241 11.3501C22.9574 11.5101 22.8641 11.6567 22.7307 11.7767C22.6107 11.8967 22.4641 12.0034 22.3041 12.07C22.1441 12.1367 21.9707 12.1634 21.7974 12.1634C21.4374 12.1634 21.1041 12.03 20.8507 11.7767C20.7307 11.6567 20.6241 11.5101 20.5574 11.3501C20.4907 11.1767 20.4641 11.0167 20.4641 10.83C20.4641 10.6567 20.4907 10.4834 20.5574 10.3234C20.6374 10.1634 20.7307 10.0167 20.8507 9.89669C20.9707 9.76335 21.1174 9.67002 21.2774 9.60335C21.7707 9.40335 22.3707 9.52335 22.7307 9.89669C22.8641 10.0167 22.9574 10.1634 23.0241 10.3234C23.0907 10.4834 23.1307 10.6567 23.1307 10.83C23.1307 11.0167 23.0907 11.1901 23.0241 11.3501Z"
                      fill="url(#paint0_linear_1250_2)"
                    />
                    <path
                      id="Vector_2"
                      d="M11.9766 16.8845H14.5366L13.4432 13.6045L11.9766 16.8845Z"
                      fill="url(#paint1_linear_1250_2)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_2"
                      x1="15.9908"
                      y1="2.79004"
                      x2="15.9908"
                      y2="29.4434"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#34D399" />
                      <stop offset="1" stopColor="#059669" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_2"
                      x1="13.2566"
                      y1="13.6045"
                      x2="13.2566"
                      y2="16.8845"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#34D399" />
                      <stop offset="1" stopColor="#059669" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="AI Powered Analytics"
              description="Make better investment decisions with detailed insights, risk forecasting, and trend analysis."
              imageSrc={AIPoweredAnalysis}
               learnMoreLink="/signup"
            />

            {/* Automated Transactions Feature */}
            <FeatureCard
              index={2}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                >
                  <g id="money-send">
                    <path
                      id="Vector"
                      d="M13.6641 13.498C13.6641 14.218 13.8641 14.3247 14.3174 14.4847L14.9974 14.7247V12.458H14.5974C14.0907 12.458 13.6641 12.9247 13.6641 13.498Z"
                      fill="url(#paint0_linear_1250_630)"
                    />
                    <path
                      id="Vector_2"
                      d="M17 19.7911H17.4C17.92 19.7911 18.3333 19.3244 18.3333 18.7511C18.3333 18.0311 18.1333 17.9244 17.68 17.7644L17 17.5244V19.7911Z"
                      fill="url(#paint1_linear_1250_630)"
                    />
                    <path
                      id="Vector_3"
                      d="M26.1041 7.43004L23.3707 10.1634C23.1707 10.3634 22.9174 10.4567 22.6641 10.4567C22.4107 10.4567 22.1574 10.3634 21.9574 10.1634C21.5707 9.77671 21.5707 9.13671 21.9574 8.75004L24.6907 6.01671C22.3441 4.01671 19.3174 2.79004 15.9974 2.79004C8.6374 2.79004 2.66406 8.76337 2.66406 16.1234C2.66406 23.4834 8.6374 29.4567 15.9974 29.4567C23.3574 29.4567 29.3307 23.4834 29.3307 16.1234C29.3307 12.8034 28.1041 9.77671 26.1041 7.43004ZM18.3307 15.8834C19.1841 16.19 20.3307 16.8034 20.3307 18.7634C20.3307 20.43 19.0107 21.8034 17.3974 21.8034H16.9974V22.1367C16.9974 22.6834 16.5441 23.1367 15.9974 23.1367C15.4507 23.1367 14.9974 22.6834 14.9974 22.1367V21.8034H14.8907C13.1174 21.8034 11.6641 20.31 11.6641 18.47C11.6641 17.91 12.1174 17.4567 12.6641 17.4567C13.2107 17.4567 13.6641 17.91 13.6641 18.4567C13.6641 19.19 14.2107 19.79 14.8907 19.79H14.9974V16.83L13.6641 16.3634C12.8107 16.0567 11.6641 15.4434 11.6641 13.4834C11.6641 11.8167 12.9841 10.4434 14.5974 10.4434H14.9974V10.1234C14.9974 9.57671 15.4507 9.12337 15.9974 9.12337C16.5441 9.12337 16.9974 9.57671 16.9974 10.1234V10.4567H17.1041C18.8774 10.4567 20.3307 11.95 20.3307 13.79C20.3307 14.3367 19.8774 14.79 19.3307 14.79C18.7841 14.79 18.3307 14.3367 18.3307 13.79C18.3307 13.0567 17.7841 12.4567 17.1041 12.4567H16.9974V15.4167L18.3307 15.8834Z"
                      fill="url(#paint2_linear_1250_630)"
                    />
                    <path
                      id="Vector_4"
                      d="M30.2533 2.40337C30.1467 2.16337 29.96 1.96337 29.7067 1.85671C29.5867 1.81671 29.4667 1.79004 29.3333 1.79004H24C23.4533 1.79004 23 2.24337 23 2.79004C23 3.33671 23.4533 3.79004 24 3.79004H26.92L24.6933 6.01671C25.2 6.45671 25.6667 6.92337 26.1067 7.43004L28.3333 5.20337V8.12337C28.3333 8.67004 28.7867 9.12337 29.3333 9.12337C29.88 9.12337 30.3333 8.67004 30.3333 8.12337V2.79004C30.3333 2.65671 30.3067 2.53671 30.2533 2.40337Z"
                      fill="url(#paint3_linear_1250_630)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_630"
                      x1="14.3307"
                      y1="12.458"
                      x2="14.3307"
                      y2="14.7247"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_630"
                      x1="17.6667"
                      y1="17.5244"
                      x2="17.6667"
                      y2="19.7911"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1250_630"
                      x1="15.9974"
                      y1="2.79004"
                      x2="15.9974"
                      y2="29.4567"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFEA66" />
                      <stop offset="1" stopColor="#B79100" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1250_630"
                      x1="26.6667"
                      y1="1.79004"
                      x2="26.6667"
                      y2="9.12337"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FFEA66" />
                      <stop offset="1" stopColor="#B79100" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="Automated Transactions"
              description="From trade execution to portfolio rebalancing, streamline every step with zero manual intervention."
              imageSrc={Transaction}
              learnMoreLink="/signup"
            />

            {/* Compliance & Risk Monitoring Feature */}
            <FeatureCard
              index={3}
              icon={
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6 md:w-8 md:h-8"
                >
                  <g id="judge">
                    <path
                      id="Vector"
                      d="M27.3883 25.3864C27.1883 25.5864 26.935 25.6797 26.6817 25.6797C26.4283 25.6797 26.175 25.5864 25.975 25.3864L19.375 18.7864L20.0817 18.0797L20.7883 17.373L27.3883 23.973C27.775 24.3597 27.775 24.9997 27.3883 25.3864Z"
                      fill="url(#paint0_linear_1250_525)"
                    />
                    <path
                      id="Vector_2"
                      d="M8.62323 12.3741L16.3566 20.1074C16.8766 20.6274 16.8766 21.4674 16.3566 21.9874L15.1566 23.2008C14.0766 24.2674 12.3699 24.2674 11.3032 23.2008L5.51656 17.4141C4.46323 16.3608 4.46323 14.6408 5.51656 13.5741L6.7299 12.3608C7.2499 11.8541 8.10323 11.8541 8.62323 12.3741Z"
                      fill="url(#paint1_linear_1250_525)"
                    />
                    <path
                      id="Vector_3"
                      d="M24.7864 13.5863L19.7064 18.6529C19.173 19.1863 18.3197 19.1863 17.7864 18.6529L10.0797 10.9463C9.54635 10.4129 9.54635 9.55958 10.0797 9.02625L15.1597 3.94625C16.213 2.89292 17.933 2.89292 18.9997 3.94625L24.7864 9.73292C25.8397 10.7996 25.8397 12.5063 24.7864 13.5863Z"
                      fill="url(#paint2_linear_1250_525)"
                    />
                    <path
                      id="Vector_4"
                      d="M10.6641 29H2.66406C2.1174 29 1.66406 28.5467 1.66406 28C1.66406 27.4533 2.1174 27 2.66406 27H10.6641C11.2107 27 11.6641 27.4533 11.6641 28C11.6641 28.5467 11.2107 29 10.6641 29Z"
                      fill="url(#paint3_linear_1250_525)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_525"
                      x1="23.5267"
                      y1="17.373"
                      x2="23.5267"
                      y2="25.6797"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_525"
                      x1="10.7366"
                      y1="11.9824"
                      x2="10.7366"
                      y2="24.0008"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1250_525"
                      x1="17.628"
                      y1="3.15625"
                      x2="17.628"
                      y2="19.0529"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1250_525"
                      x1="6.66406"
                      y1="27"
                      x2="6.66406"
                      y2="29"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#22D3EE" />
                      <stop offset="1" stopColor="#005BEA" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="Compliance & Risk Monitoring"
              description="Stay ahead of evolving regulations with real-time alerts, automated reporting, and built-in audit trails."
              imageSrc={Compilance}
              learnMoreLink="/signup"
            />

            {/* Seamless Collaboration Feature */}
            <FeatureCard
              index={4}
              icon={
                <svg
                  width="32"
                  height="33"
                  viewBox="0 0 32 33"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="people">
                    <path
                      id="Vector"
                      d="M23.3734 11.2363C23.2801 11.223 23.1867 11.223 23.0934 11.2363C21.0267 11.1696 19.3867 9.4763 19.3867 7.3963C19.3867 5.2763 21.1067 3.54297 23.2401 3.54297C25.3601 3.54297 27.0934 5.26297 27.0934 7.3963C27.0801 9.4763 25.4401 11.1696 23.3734 11.2363Z"
                      fill="url(#paint0_linear_1250_221)"
                    />
                    <path
                      id="Vector_2"
                      d="M27.7234 20.4765C26.2301 21.4765 24.1367 21.8498 22.2034 21.5965C22.7101 20.5032 22.9767 19.2898 22.9901 18.0098C22.9901 16.6765 22.6967 15.4098 22.1367 14.3032C24.1101 14.0365 26.2034 14.4098 27.7101 15.4098C29.8167 16.7965 29.8167 19.0765 27.7234 20.4765Z"
                      fill="url(#paint1_linear_1250_221)"
                    />
                    <path
                      id="Vector_3"
                      d="M8.58557 11.2363C8.67891 11.223 8.77224 11.223 8.86557 11.2363C10.9322 11.1696 12.5722 9.4763 12.5722 7.3963C12.5722 5.26297 10.8522 3.54297 8.71891 3.54297C6.59891 3.54297 4.87891 5.26297 4.87891 7.3963C4.87891 9.4763 6.51891 11.1696 8.58557 11.2363Z"
                      fill="url(#paint2_linear_1250_221)"
                    />
                    <path
                      id="Vector_4"
                      d="M8.73349 18.0091C8.73349 19.3025 9.01349 20.5291 9.52016 21.6358C7.64016 21.8358 5.68016 21.4358 4.24016 20.4891C2.13349 19.0891 2.13349 16.8091 4.24016 15.4091C5.66682 14.4491 7.68016 14.0625 9.57349 14.2758C9.02682 15.3958 8.73349 16.6625 8.73349 18.0091Z"
                      fill="url(#paint3_linear_1250_221)"
                    />
                    <path
                      id="Vector_5"
                      d="M16.1623 22.036C16.0557 22.0226 15.9357 22.0226 15.8157 22.036C13.3623 21.956 11.4023 19.9426 11.4023 17.4626C11.4157 14.9293 13.4557 12.876 16.0023 12.876C18.5357 12.876 20.589 14.9293 20.589 17.4626C20.5757 19.9426 18.629 21.956 16.1623 22.036Z"
                      fill="url(#paint4_linear_1250_221)"
                    />
                    <path
                      id="Vector_6"
                      d="M11.8264 24.7955C9.81307 26.1422 9.81307 28.3555 11.8264 29.6888C14.1197 31.2222 17.8797 31.2222 20.1731 29.6888C22.1864 28.3422 22.1864 26.1288 20.1731 24.7955C17.8931 23.2622 14.1331 23.2622 11.8264 24.7955Z"
                      fill="url(#paint5_linear_1250_221)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_221"
                      x1="23.2401"
                      y1="3.54297"
                      x2="23.2401"
                      y2="11.2363"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_221"
                      x1="25.7142"
                      y1="14.2197"
                      x2="25.7142"
                      y2="21.6733"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1250_221"
                      x1="8.72557"
                      y1="3.54297"
                      x2="8.72557"
                      y2="11.2363"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1250_221"
                      x1="6.11682"
                      y1="14.2188"
                      x2="6.11682"
                      y2="21.686"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_1250_221"
                      x1="15.9957"
                      y1="12.876"
                      x2="15.9957"
                      y2="22.036"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_1250_221"
                      x1="15.9997"
                      y1="23.6455"
                      x2="15.9997"
                      y2="30.8388"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FDA4AF" />
                      <stop offset="1" stop-color="#F43F5E" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="Seamless Collaboration"
              description="Connect RMs, Investment Teams, and Compliance Officers on a single, integrated platform."
              imageSrc={Collaboration}
              learnMoreLink="/signup"
            />

            {/* Fast Execution Feature */}
            <FeatureCard
              index={5}
              icon={
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="flash">
                    <path
                      id="Vector"
                      d="M12.424 17.7066H16.544V27.3066C16.544 28.72 18.304 29.3866 19.2373 28.32L29.3306 16.8533C30.2106 15.8533 29.504 14.2933 28.1706 14.2933H24.0506V4.69329C24.0506 3.27996 22.2906 2.61329 21.3573 3.67996L11.264 15.1466C10.3973 16.1466 11.104 17.7066 12.424 17.7066Z"
                      fill="url(#paint0_linear_1250_258)"
                    />
                    <path
                      id="Vector_2"
                      d="M11.3333 6.33301H2C1.45333 6.33301 1 5.87967 1 5.33301C1 4.78634 1.45333 4.33301 2 4.33301H11.3333C11.88 4.33301 12.3333 4.78634 12.3333 5.33301C12.3333 5.87967 11.88 6.33301 11.3333 6.33301Z"
                      fill="url(#paint1_linear_1250_258)"
                    />
                    <path
                      id="Vector_3"
                      d="M10 27.667H2C1.45333 27.667 1 27.2137 1 26.667C1 26.1203 1.45333 25.667 2 25.667H10C10.5467 25.667 11 26.1203 11 26.667C11 27.2137 10.5467 27.667 10 27.667Z"
                      fill="url(#paint2_linear_1250_258)"
                    />
                    <path
                      id="Vector_4"
                      d="M6 17.001H2C1.45333 17.001 1 16.5476 1 16.001C1 15.4543 1.45333 15.001 2 15.001H6C6.54667 15.001 7 15.4543 7 16.001C7 16.5476 6.54667 17.001 6 17.001Z"
                      fill="url(#paint3_linear_1250_258)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_1250_258"
                      x1="20.3015"
                      y1="3.1543"
                      x2="20.3015"
                      y2="28.8456"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFEA66" />
                      <stop offset="1" stop-color="#B79100" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_1250_258"
                      x1="6.66667"
                      y1="4.33301"
                      x2="6.66667"
                      y2="6.33301"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFEA66" />
                      <stop offset="1" stop-color="#B79100" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_1250_258"
                      x1="6"
                      y1="25.667"
                      x2="6"
                      y2="27.667"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFEA66" />
                      <stop offset="1" stop-color="#B79100" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_1250_258"
                      x1="4"
                      y1="15.001"
                      x2="4"
                      y2="17.001"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#FFEA66" />
                      <stop offset="1" stop-color="#B79100" />
                    </linearGradient>
                  </defs>
                </svg>
              }
              title="Fast Execution, Smart Advisory"
              description="Optimize performance, reduce risk, and deliver personalized wealth solutions effortlessly."
              imageSrc={FastExecution}
              learnMoreLink="/signup"
            />
          </div>

          {/* Final CTA section */}
          <div className="text-center mt-8 sm:mt-12 md:mt-16 mb-4 sm:mb-5 md:mb-6 px-4">
            <div className="mb-6 sm:mb-8 md:mb-10">
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold">
                Everything you need.
              </h2>
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-400">
                All in one place.
              </h2>
            </div>
            <button
              onClick={handleClick}
              className="bg-purple-600 hover:bg-purple-700 hover:text-black text-white px-4 sm:px-5 md:px-6 py-2 sm:py-2.5 md:py-3 rounded-lg flex items-center gap-2 mx-auto text-sm sm:text-base"
            >
              {/* Watch the Platform in Action */}
              Book a Demo
              <svg
                className="w-3 h-3 sm:w-4 sm:h-4"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path d="M8 5v14l11-7z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
