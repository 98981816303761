import topology from "../assets/Topology-1.png";
import frame from "../assets/frame.png";

interface SignUpProps {
  id?: string;
}

export const SignUp: React.FC<SignUpProps> = ({ id }) => {
  return (
    <div id={id} className="w-full bg-gradient-to-b from-[#000000] to-[#1f1e1e]">
      <div className="relative">
        {/* <img src={topology} alt="" className="" /> */}
        <div className="w-[80%] mx-auto flex justify-between mt-[100px] flex-wrap gap-10">
          <div className="text-left lg:w-[50%] w-full">
            <div className="text-7xl font-bold mb-8 bg-gradient-to-r from-[#F8FAFC] to-[#94A3B8] text-transparent bg-clip-text">
              Unlock your <br /> wealth potential
            </div>
            <div className="text-xl text-[#F8FAFC]">
              Experience seamless investment management with our free trial or
              request a personalised demo today.
            </div>
            <button className=" bg-white text-black px-10 py-2 rounded-xl mt-10 mb-20 text-[16px] ">
              Sign Up
            </button>
          </div>
          <div className="lg:w-[40%] w-full">
            <img src={frame} alt="profile" className="w-[100%]" />
          </div>
        </div>
        <img src={topology} alt="" className="absolute top-[-300px] left-0 " />
      </div>
    </div>
  );
};
