import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BLOG_CATEGORIES, BLOG_POSTS, BlogPost } from './blogs.constants';

const BlogListing: React.FC = () => {
  // State for selected filter with "All Categories" as default
  const [filter, setFilter] = useState('All Categories');
  // State for filtered blog posts
  const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>([]);
  // State to track how many posts to display
  const [visiblePostsCount, setVisiblePostsCount] = useState(12);
  // Track the initial count to return to when "View Less" is clicked
  const initialPostCount = 12;

  // Effect to filter posts when filter changes
  useEffect(() => {
    // First filter by category
    let postsToFilter = [...BLOG_POSTS];
    
    // Sort posts by date (newest first)
    postsToFilter.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });
    
    // Then apply category filter if needed
    if (filter === 'All Categories') {
      setFilteredPosts(postsToFilter);
    } else {
      const filtered = postsToFilter.filter(post => post.categories.includes(filter));
      setFilteredPosts(filtered);
    }
    
    // Reset visible posts count when filter changes
    setVisiblePostsCount(initialPostCount);
  }, [filter]);

  // Reset filter to default when component re-renders
  useEffect(() => {
    // This ensures the filter is reset to default when the component is mounted or remounted
    setFilter('All Categories');
  }, []);

  // Get posts to display based on current visible count
  const postsToDisplay = filteredPosts.slice(0, visiblePostsCount);

  // Function to handle "View More" click
  const handleViewMoreClick = () => {
    // Increase visible posts count by 6 (2 rows of 3)
    setVisiblePostsCount(prevCount => prevCount + 6);
  };

  // Function to handle "View Less" click
  const handleViewLessClick = () => {
    // Reset to initial count
    setVisiblePostsCount(initialPostCount);
    
    // Scroll back up to the top of the blog section
    const blogSection = document.querySelector('.blog-listing-section');
    if (blogSection) {
      blogSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Get unique categories for dropdown options
  const categories = BLOG_CATEGORIES;

  const handleBlogClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="w-full bg-black text-white py-16 blog-listing-section">
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold mb-2">Latest Blogs</h2>
        </div>
        
        {/* Filter Dropdown */}
        <div className="max-w-xs mx-auto mb-12">
          <div className="relative">
            <select 
              className="block appearance-none w-full bg-black border border-gray-700 rounded py-3 px-4 pr-8 leading-tight focus:outline-none focus:border-purple-500"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              {categories.map(category => (
                <option key={category}>{category}</option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
        </div>
        
        {/* Blog Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {postsToDisplay.length > 0 ? (
            postsToDisplay.map((post) => (
              <div key={post.id} className="bg-gray-900 rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                <Link to={post.url} onClick={handleBlogClick}>
                  <div className="h-48 overflow-hidden">
                    <img 
                      src={post.imageUrl} 
                      alt={post.title} 
                      className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                    />
                  </div>
                  <div className="p-4">
                    <h3 className="text-lg font-bold mb-2 line-clamp-2">{post.title}</h3>
                    <p className="text-sm text-gray-400 line-clamp-3">{post.description}</p>
                    <div className="mt-2">
                      {post.categories.map((category, index) => (
                        <span key={index} className="category-tag">
                          {category}{index < post.categories.length - 1 ? ', ' : ''}
                        </span>
                      ))}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="col-span-3 text-center py-10">
              <p className="text-gray-400">No blog posts found for this category.</p>
            </div>
          )}
        </div>
        
        {/* View More/Less Buttons */}
        <div className="text-center mt-10 flex justify-center space-x-4">
          {/* View More Button - Only show if there are more posts to display */}
          {visiblePostsCount < filteredPosts.length && (
            <button 
              onClick={handleViewMoreClick}
              className="inline-block px-6 py-3 border border-purple-500 text-purple-500 rounded hover:bg-purple-500 hover:text-white transition-colors"
            >
              View More
            </button>
          )}
          
          {/* View Less Button - Now styled the same as View More */}
          {visiblePostsCount > initialPostCount && filteredPosts.length > initialPostCount && (
            <button 
              onClick={handleViewLessClick}
              className="inline-block px-6 py-3 border border-purple-500 text-purple-500 rounded hover:bg-purple-500 hover:text-white transition-colors"
            >
              View Less
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default BlogListing;