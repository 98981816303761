import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Analytics } from "@vercel/analytics/react";
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import App from "./App";
import "./index.css";
import WealthManagerPage from "./components/WealthManagerPage";
import RMPage from "./components/RelationshipManagerPage";
import CompliancePage from "./components/ComplianceOfficerPage";
import FamilyOfficesPage from "./components/FamilyOfficesPage";
import OperationTeamPage from "./components/OperationTeamPage";
import CIOPage from "./components/CIOPage";
import ContactUs from "./components/ContactUs";
import AboutUsPage from "./components/AboutUsPage";
import BlogPage from "./components/BlogPage";
import BlogDetailPage from "./components/BlogDetailPage";
import { PlatformAndFeatures } from "./components/platform-and-features";
import { Pricing } from "./components/platform-and-features/PricingIndex";
import OurTeamPage from "./components/OurTeamPage";
import NotFound from "./components/NotFound";
import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import { RootLayout } from "./layouts/RootLayout";
import { initGA, trackPageView } from "./utils/analytics";
import { MAIN_ROUTES, SOLUTION_ROUTES, LEGAL_ROUTES } from "./constants/routes";
import { initSentry } from "./utils/sentry";
import SentryErrorBoundary from "./components/ErrorBoundary";
import { resetScrollPosition } from "./utils/scrollHelpers";
import HubspotProvider from "./utils/HubspotProvider";
import { trackHubspotPageView } from "./utils/hubspot";

if (typeof window !== "undefined") {
  // Reset scroll position when loading a page
  window.history.scrollRestoration = "manual";
  
  // Initialize Google Analytics
  initGA();

  // Initialize Sentry for error tracking
  initSentry();
}

// ScrollToTop component to reset scroll position on route changes
function ScrollToTop() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    // Reset scroll position when route changes
    resetScrollPosition();
    // Track page view in HubSpot
    trackHubspotPageView(pathname);
  }, [pathname]);
  
  return null;
}

// Create a context for the Helmet
const helmetContext = {};

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <SentryErrorBoundary>
      <HelmetProvider context={helmetContext}>
      <HubspotProvider hubspotId="5833386">
        <Analytics />
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route element={<RootLayout />}>
              {/* Auth routes now inside RootLayout since duplicate footer was removed */}
              <Route path={MAIN_ROUTES.SIGNIN.path} element={<SignIn />} />
              <Route path={MAIN_ROUTES.SIGNUP.path} element={<SignUp />} />
              
              <Route path={MAIN_ROUTES.HOME.path} element={<App />} />
              <Route path={SOLUTION_ROUTES.WEALTH_MANAGER.path} element={<WealthManagerPage />} />
              <Route path={SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path} element={<RMPage />} />
              <Route path={SOLUTION_ROUTES.COMPLIANCE_OFFICER.path} element={<CompliancePage />} />
              <Route path={SOLUTION_ROUTES.OPERATION_TEAM.path} element={<OperationTeamPage />} />
              <Route path={SOLUTION_ROUTES.CIO.path} element={<CIOPage />} />
              <Route path={SOLUTION_ROUTES.FAMILY_OFFICE.path} element={<FamilyOfficesPage />} />
              <Route path={MAIN_ROUTES.CONTACT.path} element={<ContactUs />} />
              <Route path={MAIN_ROUTES.ABOUT.path} element={<AboutUsPage />} />
              <Route path={MAIN_ROUTES.BLOGS.path} element={<BlogPage />} />
              <Route path={`${MAIN_ROUTES.BLOGS.path}/:slug`} element={<BlogDetailPage />} />
              <Route path={MAIN_ROUTES.PLATFORM.path} element={<PlatformAndFeatures />} />
              <Route path={MAIN_ROUTES.PRICING.path} element={<Pricing />} />
              <Route path={MAIN_ROUTES.SEE_LIVE.path} element={<SignUp />} />
              <Route path={MAIN_ROUTES.TEAM.path} element={<OurTeamPage />} />
              <Route path={LEGAL_ROUTES.TERMS.path} element={<NotFound />} />
              <Route path={LEGAL_ROUTES.PRIVACY.path} element={<NotFound />} />
              <Route path={LEGAL_ROUTES.PRIVACY_POLICY.path} element={<NotFound />} />
            </Route>
            
            {/* Catch all */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
        </HubspotProvider>
      </HelmetProvider>
    </SentryErrorBoundary>
  </StrictMode>
);
