// src/utils/hubspot.ts
export const trackHubspotPageView = (path: string) => {
    if (typeof window !== 'undefined' && (window as any)._hsq) {
      (window as any)._hsq.push(['setPath', path]);
      (window as any)._hsq.push(['trackPageView']);
    }
  };
  
  export const trackHubspotEvent = (eventName: string, properties?: Record<string, any>) => {
    if (typeof window !== 'undefined' && (window as any)._hsq) {
      (window as any)._hsq.push(['trackEvent', {
        id: eventName,
        value: properties
      }]);
    }
  };