import React, { useState } from 'react';
import Navbar from './Navbar';
import FeaturedBlogPost from './FeaturedBlogPost';
import BlogTopArticles from './BlogTopArticles';
import BlogListing from './BlogListing';
import BlogContactForm from './BlogContactForm';
import { Link } from 'react-router-dom';
import ValuefyInAction from './ValuefyInAction';
import { BLOG_POSTS, BLOG_CATEGORIES, FEATURED_BLOG_POST, BlogPost } from './blogs.constants';
import { MAIN_ROUTES } from "../constants/routes";
import { SEO } from './seo';

const BlogPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState<BlogPost[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  
  // Filter categories based on search term
  const filteredCategories = searchTerm.trim() !== '' 
    ? BLOG_CATEGORIES.filter(category => 
        category.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  // Filter titles based on search term
  const filteredTitles = searchTerm.trim() !== ''
    ? BLOG_POSTS.filter(post =>
        post.title.toLowerCase().includes(searchTerm.toLowerCase())
      ).slice(0, 5) // Limit to 5 title suggestions
    : [];

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    setShowSuggestions(true);
    
    if (value.trim() === '') {
      setIsSearching(false);
    } else {
      setIsSearching(true);
      const results = BLOG_POSTS.filter(post => 
        post.title.toLowerCase().includes(value.toLowerCase()) || 
        post.description.toLowerCase().includes(value.toLowerCase()) ||
        post.categories.some(cat => cat.toLowerCase().includes(value.toLowerCase()))
      );
      setSearchResults(results);
    }
  };

  const handleCategoryClick = (category: string) => {
    setSearchTerm(category);
    setShowSuggestions(false);
    
    // Filter results based on the selected category
    const results = BLOG_POSTS.filter(post => 
      post.categories.some(cat => cat.toLowerCase().includes(category.toLowerCase()))
    );
    setSearchResults(results);
    setIsSearching(true);
  };

  const handleTitleClick = (post: BlogPost) => {
    setSearchTerm(post.title);
    setShowSuggestions(false);
    setSearchResults([post]);
    setIsSearching(true);
  };

  const clearSearch = () => {
    setSearchTerm('');
    setIsSearching(false);
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <SEO
        title="Blog | Valuefy"
        description="Read the latest articles and insights on wealth management, investment strategies, and financial technology."
      />
      <p className="sticky top-0 z-50 bg-white shadow-md">
        <Navbar />
      </p>
      <div className="container mx-auto px-4 py-8 md:py-12">
        {/* Responsive Breadcrumb and Search Container */}
        <div className="flex flex-col md:flex-row md:justify-between md:items-center gap-4 mb-6">
          {/* Breadcrumb Navigation - Hidden on very small screens */}
          <div className="hidden sm:flex items-center text-sm text-gray-400">
            <Link to={MAIN_ROUTES.HOME.path} className="hover:text-white">Home</Link>
            <span className="mx-2">/</span>
            <Link to={MAIN_ROUTES.BLOGS.path} className="hover:text-white">Blogs</Link>
          </div>
          
          {/* Search Bar */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search by topic or keyword"
              className="bg-black border border-gray-700 rounded-md px-10 py-2 w-72 focus:outline-none focus:border-purple-500"
              value={searchTerm}
              onChange={handleSearchChange}
              onFocus={() => setShowSuggestions(true)}
              onBlur={() => setTimeout(() => setShowSuggestions(false), 200)}
            />
            <svg
              className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
            
            {/* Suggestions Dropdown */}
            {showSuggestions && (filteredCategories.length > 0 || filteredTitles.length > 0) && (
              <div className="absolute z-50 w-full bg-gray-900 border border-gray-700 rounded-md mt-1 shadow-lg max-h-80 overflow-y-auto">
                {/* Title Suggestions */}
                {filteredTitles.length > 0 && (
                  <div className="p-2 border-b border-gray-700">
                    <h4 className="text-xs font-semibold text-gray-400 mb-2 px-2">Articles</h4>
                    <ul>
                      {filteredTitles.map((post) => (
                        <li 
                          key={post.id}
                          className="px-2 py-1.5 text-sm hover:bg-gray-800 rounded cursor-pointer"
                          onClick={() => handleTitleClick(post)}
                        >
                          <div className="flex items-center">
                            <div className="w-8 h-8 rounded overflow-hidden mr-2 flex-shrink-0">
                              <img 
                                src={post.imageUrl} 
                                alt={post.title} 
                                className="w-full h-full object-cover"
                              />
                            </div>
                            <span className="line-clamp-1">{post.title}</span>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                
                {/* Category Suggestions */}
                {filteredCategories.length > 0 && (
                  <div className="p-2">
                    <h4 className="text-xs font-semibold text-gray-400 mb-2 px-2">Categories</h4>
                    <ul>
                      {filteredCategories.map((category, index) => (
                        <li 
                          key={index}
                          className="px-2 py-1.5 text-sm hover:bg-gray-800 rounded cursor-pointer"
                          onClick={() => handleCategoryClick(category)}
                        >
                          <div className="flex items-center">
                            <svg className="w-4 h-4 mr-2 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z" />
                            </svg>
                            {category}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            )}
            
            {searchTerm && (
              <button
                className="absolute right-3 top-2.5 text-gray-400 hover:text-white"
                onClick={clearSearch}
              >
                <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            )}
          </div>
        </div>
        
        {!isSearching ? (
          <>
            {/* Regular content when not searching */}
            <FeaturedBlogPost 
              title={FEATURED_BLOG_POST.title}
              imageUrl={FEATURED_BLOG_POST.imageUrl}
              description={FEATURED_BLOG_POST.description}
              articleUrl={FEATURED_BLOG_POST.articleUrl}
            />
            <BlogTopArticles />
            <BlogListing />
            <ValuefyInAction />
            <BlogContactForm/>
          </>
        ) : (
          <>
            {/* Search results */}
            <div className="py-8">
              <h2 className="text-2xl font-bold mb-6">Search Results for "{searchTerm}"</h2>
              
              {searchResults.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {searchResults.map((post) => (
                    <div key={post.id} className="bg-gray-900 rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                      <Link to={post.url}>
                        <div className="h-48 overflow-hidden">
                          <img 
                            src={post.imageUrl} 
                            alt={post.title} 
                            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                          />
                        </div>
                        <div className="p-4">
                          <span className="text-xs text-purple-400">{post.categories.join(', ')}</span>
                          <h3 className="text-lg font-bold mb-2 line-clamp-2">{post.title}</h3>
                          <p className="text-sm text-gray-400 line-clamp-3">{post.description}</p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-16">
                  <p className="text-xl text-gray-400 mb-4">No results found for "{searchTerm}"</p>
                  <p className="text-gray-500">Try different keywords or browse our blog categories</p>
                  <button 
                    onClick={clearSearch}
                    className="mt-6 px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700 transition-colors"
                  >
                    Clear Search
                  </button>
                </div>
              )}
            </div>
            <ValuefyInAction />
            <BlogContactForm />
          </>
        )}
      </div>
    </div>
  );
};

export default BlogPage;