import React, { useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { MAIN_ROUTES } from "../constants/routes";
import valefyLogo from '../assets/blog/valuefy-logo-blog.svg'; // Default author image

// Types for blog post data
interface BlogPost {
  id: string;
  title: string;
  subtitle?: string;
  author?: {
    name: string;
    image?: string;
  };
  date: string;
  readTime: string;
  mainImage: string;
  content: string;
  sections: BlogSection[];
}

interface BlogSection {
  id: string;
  title: string;
}

const BlogDetail: React.FC<{ post: BlogPost }> = ({ post }) => {
  const location = useLocation();
  const params = useParams();
  console.log('Rendering blog post:', post);

  // Scroll to section on hash change or initial load
  useEffect(() => {
    // Get the hash from the URL (e.g., #introduction)
    const hash = location.hash;
    if (hash) {
      // Remove the # character
      const sectionId = hash.substring(1);
      const element = document.getElementById(sectionId);
      if (element) {
        // Add a slight delay to ensure the page is fully loaded
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  const blogPost: BlogPost = post;
  
  // Set default values for missing properties
  const authorName = blogPost.author?.name || 'Valuefy';
  const authorImage = blogPost.author?.image || valefyLogo;
  
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Navigation and Search Bar */}
      <header className="text-white">
        <div className="container mx-auto px-4 py-4 flex items-center justify-between">
          <div className="flex items-center space-x-4">
            {/* Breadcrumb */}
            <div className="flex items-center space-x-2 text-sm text-gray-500 mb-4">
              <Link to={MAIN_ROUTES.HOME.path} className="flex items-center space-x-1">
                <svg className="w-3.5 h-3.5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                </svg>
                <span>Home</span>
              </Link>
              <span>/</span>
              <Link to={MAIN_ROUTES.BLOGS.path} className="text-sm">Blogs</Link>
              <span>/</span>
              <span className="text-gray-400">{blogPost.title || 'Blog Post'}</span>
            </div>

            <span>/</span>
            <Link to="/blogs" className="text-sm">Blogs</Link>
            <span>/</span>
            <span className="text-sm truncate max-w-[150px]">{blogPost.title}</span>
          </div>

          <div className="relative hidden md:block">
            <input
              type="text"
              placeholder="Search by topic or keyword"
              className="pl-8 pr-4 py-2 border-[0.1px] bg-black border-slate-400 rounded-sm text-xs w-[397px] focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
            />
            <svg
              className="absolute left-2 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
      </header>

      {/* Blog Title Section */}
      <div className="container mx-auto px-4 pt-10 pb-6">
        <h1 className="text-2xl sm:text-3xl md:text-[64px] leading-normal md:leading-[85px] font-bold mb-6">{blogPost.title}</h1>

        {/* Author and Date Info */}
        <div className="flex items-center mb-6">
          <img
            src={authorImage}
            alt={authorName}
            className="w-10 h-10 rounded-full mr-3"
          />
          <div>
            <p className="font-medium">{authorName}</p>
            <p className="text-sm">{blogPost.date} · {blogPost.readTime}</p>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="container mx-auto px-4 pb-16">
        <div className="flex flex-col lg:flex-row gap-10">
          {/* Main Content */}
          <div className="w-full">
            {/* Main Image */}
            <div className="mb-8">
              <img
                src={blogPost.mainImage}
                alt={blogPost.title}
                className="w-full h-auto rounded-lg"
              />
            </div>

            {/* Blog Content with responsive sidebar */}
            <div className="flex flex-col lg:flex-row">
              {/* Main content - full width on mobile, 2/3 on desktop */}
              <div
                className="w-full lg:w-2/3 prose prose-lg max-w-none"
                dangerouslySetInnerHTML={{ __html: blogPost.content }}
              ></div>

              {/* Sidebar - hidden on mobile, visible on desktop */}
              <div className="hidden lg:block lg:w-1/3 pl-10">
                <div className="sticky top-6 rounded-lg p-2">
                  <h3 className="text-2xl font-medium text-gray-400 mb-[15px]">Contents</h3>
                  <nav className="space-y-[15px]">
                    {blogPost.sections && blogPost.sections.map((section) => (
                      <Link
                        key={section.id}
                        to={`#${section.id}`}
                        className="block text-gray-600 hover:text-white transition-colors"
                      >
                        {section.title}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;