import bgripple from '../assets/family-office/background-ripple.png';
const FamilyOfficeStats = () => {
  const stats = [
    {
      value: "100+",
      description: "Family Offices trust Valuefy for its real-time wealth intelligence."
    },
    {
      value: "2X",
      description: "Faster Decision-Making with AI-driven analytics."
    },
    {
      value: "98%",
      description: "Client Retention due to seamless reporting, security, and personalization."
    }
  ];

  return (
    <div className="w-full bg-black text-white py-32 bg-cover bg-center relative"  style={{ backgroundImage: `url(${bgripple})` }}>
      {/* Optional: Add a dark overlay to the background image for better readability */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="max-w-6xl mx-auto px-4 relative z-10">
        <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">Why Family Offices Choose Valuefy?</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center px-4 py-6 bg-black/70 rounded-lg shadow-lg relative group">
              {/* Add dividers between columns except for the last one */}
              {index < stats.length - 1 && (
                <div className="hidden md:block absolute right-0 top-1/4 bottom-1/4 w-px bg-gray-700"></div>
              )}
              
              <h3 className="text-6xl font-bold mb-8 transform transition-transform duration-300 group-hover:scale-110">
                {stat.value}
              </h3>
              <p className="text-gray-300">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FamilyOfficeStats;
