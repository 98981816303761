import { 
  collection, 
  getDocs, 
  query, 
  where, 
  orderBy,
  addDoc,
  writeBatch,
  doc,
  serverTimestamp
} from 'firebase/firestore';
import { db } from './config';
import { RouteData, RouteType } from '../../types/routes';

// Constants
export const ROUTES_COLLECTION = 'routes';

// Static route imports (fallback)
import { MAIN_ROUTES, SOLUTION_ROUTES, LEGAL_ROUTES, SUPPORT_ROUTES, RouteMetadata } from "../../constants/routes";

/**
 * Fetch all active routes from Firestore
 * Falls back to static routes if Firestore fetch fails
 */
export async function fetchAllRoutes(): Promise<RouteData[]> {
  try {
    const routesRef = collection(db, ROUTES_COLLECTION);
    const routesQuery = query(
      routesRef,
      where('is_active', '==', true),
      orderBy('sort_order', 'asc')
    );
    
    const querySnapshot = await getDocs(routesQuery);
    
    if (querySnapshot.empty) {
      console.log('No routes found in Firestore, using static routes');
      return transformStaticRoutesToDynamic();
    }
    
    // Map the query results to RouteData objects
    const routes: RouteData[] = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        route_key: data.route_key,
        path: data.path,
        label: data.label,
        title: data.title,
        description: data.description || '',
        is_active: data.is_active,
        parent_route_key: data.parent_route_key,
        route_type: data.route_type,
        icon_url: data.icon_url,
        sort_order: data.sort_order || 0,
        requires_auth: data.requires_auth || false,
        last_updated: data.last_updated ? data.last_updated.toDate().toISOString() : new Date().toISOString(),
        metadata: data.metadata || {}
      };
    });
    
    return routes;
  } catch (error) {
    console.error('Error fetching routes from Firestore:', error);
    // Fall back to static routes
    return transformStaticRoutesToDynamic();
  }
}

/**
 * Fetch routes by type from Firestore
 * Falls back to static routes if Firestore fetch fails
 */
export async function fetchRoutesByType(routeType: RouteType): Promise<RouteData[]> {
  try {
    const routesRef = collection(db, ROUTES_COLLECTION);
    const routesQuery = query(
      routesRef,
      where('route_type', '==', routeType),
      where('is_active', '==', true),
      orderBy('sort_order', 'asc')
    );
    
    const querySnapshot = await getDocs(routesQuery);
    
    if (querySnapshot.empty) {
      console.log(`No ${routeType} routes found in Firestore, using static routes`);
      return transformStaticRoutesToDynamic().filter(route => route.route_type === routeType);
    }
    
    // Map the query results to RouteData objects
    const routes: RouteData[] = querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        route_key: data.route_key,
        path: data.path,
        label: data.label,
        title: data.title,
        description: data.description || '',
        is_active: data.is_active,
        parent_route_key: data.parent_route_key,
        route_type: data.route_type,
        icon_url: data.icon_url,
        sort_order: data.sort_order || 0,
        requires_auth: data.requires_auth || false,
        last_updated: data.last_updated ? data.last_updated.toDate().toISOString() : new Date().toISOString(),
        metadata: data.metadata || {}
      };
    });
    
    return routes;
  } catch (error) {
    console.error(`Error fetching ${routeType} routes from Firestore:`, error);
    // Fall back to static routes
    return transformStaticRoutesToDynamic().filter(route => route.route_type === routeType);
  }
}

/**
 * Import routes from CSV data into Firestore
 * This function should be called only once to initialize the routes collection
 */
export async function importRoutesFromCSV(routesData: any[]): Promise<void> {
  try {
    const batch = writeBatch(db);
    const routesRef = collection(db, ROUTES_COLLECTION);
    
    // First check if routes already exist
    const existingRoutes = await getDocs(routesRef);
    if (!existingRoutes.empty) {
      console.log('Routes already exist in Firestore. Skipping import.');
      return;
    }
    
    // Process each route from CSV data
    for (const routeRow of routesData) {
      // Create a document with document ID matching the route ID from CSV
      const routeDocRef = doc(db, ROUTES_COLLECTION, routeRow.id);
      
      // Parse metadata from JSON string if needed
      let metadata = {};
      try {
        if (typeof routeRow.metadata === 'string') {
          metadata = JSON.parse(routeRow.metadata);
        } else if (typeof routeRow.metadata === 'object') {
          metadata = routeRow.metadata;
        }
      } catch (e) {
        console.warn(`Failed to parse metadata for route ${routeRow.route_key}:`, e);
      }
      
      // Format the route document
      const routeDoc = {
        route_key: routeRow.route_key,
        path: routeRow.path,
        label: routeRow.label,
        title: routeRow.title,
        description: routeRow.description || '',
        is_active: routeRow.is_active === 'true' || routeRow.is_active === true,
        parent_route_key: routeRow.parent_route_key || null,
        route_type: routeRow.route_type,
        icon_url: routeRow.icon_url || null,
        sort_order: parseInt(routeRow.sort_order || '0', 10),
        requires_auth: routeRow.requires_auth === 'true' || routeRow.requires_auth === true,
        last_updated: serverTimestamp(),
        metadata: metadata
      };
      
      // Add to batch
      batch.set(routeDocRef, routeDoc);
    }
    
    // Commit the batch
    await batch.commit();
    console.log(`Successfully imported ${routesData.length} routes to Firestore`);
  } catch (error) {
    console.error('Error importing routes to Firestore:', error);
    throw error;
  }
}

/**
 * Group routes by their route_type for easier consumption in the app
 */
export function groupRoutesByType(routes: RouteData[]): Record<string, Record<string, RouteData>> {
  return routes.reduce((grouped, route) => {
    // Initialize the group if it doesn't exist
    if (!grouped[route.route_type]) {
      grouped[route.route_type] = {};
    }
    
    // Add the route to its type group, using route_key as the key
    grouped[route.route_type][route.route_key] = route;
    
    return grouped;
  }, {} as Record<string, Record<string, RouteData>>);
}

/**
 * Transform static routes into dynamic route format
 * This is used as fallback when Firestore isn't available
 */
export function transformStaticRoutesToDynamic(): RouteData[] {
  const routes: RouteData[] = [];
  
  // Add main routes
  Object.entries(MAIN_ROUTES).forEach(([key, value], index) => {
    // Extract metadata with type safety
    const routeValue = value as { path: string; metadata: RouteMetadata };
    const metadata = routeValue.metadata;
    
    routes.push({
      id: `static-main-${index}`,
      route_key: key,
      path: routeValue.path,
      label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      title: metadata.title,
      description: metadata.description,
      is_active: true,
      parent_route_key: null,
      route_type: RouteType.MAIN,
      icon_url: null,
      sort_order: index,
      requires_auth: false,
      last_updated: new Date().toISOString(),
      metadata: {
        keywords: metadata.keywords || '',
        ...(metadata.ogImage && { ogImage: metadata.ogImage }),
        ...(metadata.ogType && { ogType: metadata.ogType })
      }
    });
  });
  
  // Add solution routes
  Object.entries(SOLUTION_ROUTES).forEach(([key, value], index) => {
    // Extract metadata with type safety
    const routeValue = value as { path: string; metadata: RouteMetadata };
    const metadata = routeValue.metadata;
    
    routes.push({
      id: `static-solution-${index}`,
      route_key: key,
      path: routeValue.path,
      label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      title: metadata.title,
      description: metadata.description,
      is_active: true,
      parent_route_key: null,
      route_type: RouteType.SOLUTION,
      icon_url: null,
      sort_order: index,
      requires_auth: false,
      last_updated: new Date().toISOString(),
      metadata: {
        keywords: metadata.keywords || ''
      }
    });
  });
  
  // Add legal routes
  Object.entries(LEGAL_ROUTES).forEach(([key, value], index) => {
    // Extract metadata with type safety
    const routeValue = value as { path: string; metadata: RouteMetadata };
    const metadata = routeValue.metadata;
    
    routes.push({
      id: `static-legal-${index}`,
      route_key: key,
      path: routeValue.path,
      label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      title: metadata.title,
      description: metadata.description,
      is_active: true,
      parent_route_key: null,
      route_type: RouteType.LEGAL,
      icon_url: null,
      sort_order: index,
      requires_auth: false,
      last_updated: new Date().toISOString(),
      metadata: {
        keywords: metadata.keywords || ''
      }
    });
  });
  
  // Add support routes
  Object.entries(SUPPORT_ROUTES).forEach(([key, value], index) => {
    // Extract metadata with type safety
    const routeValue = value as { path: string; metadata: RouteMetadata };
    const metadata = routeValue.metadata;
    
    routes.push({
      id: `static-support-${index}`,
      route_key: key,
      path: routeValue.path,
      label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
      title: metadata.title,
      description: metadata.description,
      is_active: true,
      parent_route_key: null,
      route_type: RouteType.SUPPORT,
      icon_url: null,
      sort_order: index,
      requires_auth: false,
      last_updated: new Date().toISOString(),
      metadata: {
        keywords: metadata.keywords || ''
      }
    });
  });
  
  return routes;
} 