import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import ContactUsBgImg from "../assets/background/contact-us-bg.png";
import Map from "../assets/background/gradient-map.png";
import OfficeLocations from "./Offices";
import HelpCenter from "./HelpCentre";
import LandingHero from "./LandingHero";
import { AuroraText } from "./magicui/aurora-text";
import Greeting from "./Greeting";
import { useNavigate } from "react-router-dom";

// Main Heading Component
// Modified MainHeading Component
const MainHeading: React.FC = () => {
  // State to track window width
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );

  // Effect to update window width on resize
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Set initial width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Define tablet breakpoint (e.g., 1024px)
  const tabletBreakpoint = 1024;

  return (
    <div className="mt-8">
      <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center md:text-left">
        {windowWidth <= tabletBreakpoint ? (
          // Purple text for mobile and tablet
          <span className="text-2xl md:text-3xl lg:text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-purple-900">
            Let's Build the{" "}
            Future of Wealth
            Together
          </span>
        ) : (
          // Aurora text for desktop
          <AuroraText
            className="text-2xl md:text-3xl lg:text-4xl left-1"
            colors={[
              "#d946ef",
              "#c026d3",
              "#9333ea",
              "#7c3aed",
              "#6366f1",
              "#3b82f6",
              "#0ea5e9",
            ]}
            speed={7}
          >
            {" "}
            Let's Build the{" "}
            Future of Wealth
            Together
          </AuroraText>
        )}{" "}
      </h2>
    </div>
  );
};

// Description Component
const Description: React.FC = () => (
  <div className="mt-6 max-w-3xl mx-auto md:mx-0">
    <p className="text-white text-base md:text-lg text-center md:text-left">
      We're here to empower your success with automation-first wealth management
      technology. Whether you're a client, a financial professional, a partner,
      or a media representative, we'd love to hear from you.
    </p>
  </div>
);

interface ContactCardProps {
  handleClick: () => void;
}
// Contact Card Component
const ContactCard: React.FC<ContactCardProps> = ({ handleClick }) => (
  <div className="mt-8 md:mt-12 relative">
    <div className="w-full h-full rounded-lg overflow-hidden">
      <img
        src={ContactUsBgImg}
        alt="Two people in a business meeting with laptops"
        className="w-full h-full object-cover hidden md:block"
      />

      <div className="md:absolute md:top-12 md:left-12 bg-white p-4 md:p-6 lg:p-8 rounded-lg shadow-lg w-full md:w-64 lg:w-72 md:mt-24">
        <h3 className="text-black text-2xl md:text-3xl font-bold mb-2 md:mb-4 text-center md:text-left">
          Let's talk.
        </h3>
        <p className="text-gray-700 text-center mb-4 md:mb-6 text-sm md:text-base">
          The more we know, the faster we can connect you with the right expert.
        </p>

        <button
          onClick={handleClick}
          className="w-full bg-purple-500 hover:bg-purple-600 text-white py-2 md:py-3 rounded-md mb-3 md:mb-4 text-sm md:text-base"
        >
          Book a Demo
        </button>

        <button className="w-full flex items-center justify-center text-gray-800 text-sm md:text-base">
          <span className="mr-2">
            <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
              <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2z" />
            </svg>
          </span>
          Request a Call
        </button>
      </div>
    </div>
  </div>
);

// Main App Component
const ContactUs: React.FC = () => {
  // Data
  const greetings = [
    "Hello",
    "Ni Hao",
    "Marhaba",
    "Bonjour",
    "Hallo",
    "Namaste",
  ];

  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/signup");
  };

  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Map - hidden on small screens, responsive positioning on larger screens */}
      <div className="hidden md:block absolute top-16 left-1/4 lg:left-[500px]">
        <img src={Map} alt="Background Element" className="w-auto" />
      </div>

      <Navbar />
      <div className="container mx-auto px-4 py-4 md:py-6">
        {/* Hero Section with Background */}
        <div className="relative">
          {/* World map background would go here */}
          <div className="absolute inset-0 opacity-20 z-0">
            <div className="w-full h-full bg-purple-900 bg-opacity-20 rounded-full blur-3xl"></div>
          </div>

          <div className="min-h-screen relative w-full">
            <div className="flex flex-col items-center md:items-start">
              <Greeting greetings={greetings} />
              <MainHeading />
              <Description />
            </div>
            <ContactCard handleClick={handleClick} />
            <HelpCenter />
            <OfficeLocations />
            {/* <div className="flex flex-col items-center justify-center space-y-4 md:space-y-8 py-8 md:py-16 px-4 w-full h-auto md:h-60">
              <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center text-white">
                Work with Us
              </h1>

              <p className="text-base md:text-xl text-center">
                We're always looking for brilliant minds to shape the future of
                WealthTech.
              </p>

              <RainbowButton className="bg-purple-500 hover:bg-purple-600 text-white py-3 md:py-6 px-6 md:px-8 rounded-md text-base md:text-lg font-medium">
                Explore careers
              </RainbowButton>
            </div> */}
          </div>
        </div>
      </div>
      <LandingHero
        headline="Let's Talk WealthTech"
        subheading="Get in touch with us today. Whether it's a partnership, a product inquiry, or a client request—we're here to help."
        signUpButtonText="Book a Demo"
        talkToExpert={true}
      />
    </div>
  );
};

export default ContactUs;
