import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SEO from './SEO';
import { getSeoMetadataForRoute } from './seoConfig';

const RouteSEO = () => {
  const location = useLocation();
  const [pathname, setPathname] = useState(location.pathname);

  useEffect(() => {
    setPathname(location.pathname);
    console.log('RouteSEO: Path changed to', location.pathname);
  }, [location]);

  // Get metadata for the current route
  const metadata = getSeoMetadataForRoute(pathname);
  
  // Attempt to find parent route for dynamic routes (e.g., /blogs/some-slug)
  let parentMetadata;
  if (!metadata && pathname.includes('/')) {
    // Try to find a parent route
    const pathParts = pathname.split('/');
    // Remove the last part (the dynamic segment)
    pathParts.pop();
    const parentPath = pathParts.join('/') || '/';
    
    parentMetadata = getSeoMetadataForRoute(parentPath);
    console.log('RouteSEO: Trying parent path', parentPath, parentMetadata);
  }
  
  console.log('RouteSEO: Metadata for path', pathname, metadata || parentMetadata);

  // If no specific metadata is found for this route or its parent, use default values in SEO component
  if (!metadata && !parentMetadata) {
    console.log('RouteSEO: No metadata found, using defaults');
    return <SEO />;
  }

  // Use parent metadata for dynamic routes if direct metadata is not available
  const seoData = metadata || parentMetadata;
  
  return (
    <SEO
      title={seoData?.title}
      description={seoData?.description}
      keywords={seoData?.keywords}
      ogImage={seoData?.ogImage}
      ogType={seoData?.ogType}
    />
  );
};

export default RouteSEO; 