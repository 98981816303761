import React from 'react'
import Navbar from '../Navbar'
import { Approach } from './components/Approach'
import { Stage } from './components/Stage'
import { Choose } from './components/Choose'
import { Action } from './components/Action'
import { SignUp } from './components/SignUp'
import { PricingHeroSection } from './components/PricingHeroSection'

export const Pricing = () => {
  return (
    <div className='bg-[#000000]'>
      <Navbar />
      <PricingHeroSection/>
      <Approach id="approach"/>
      <Stage id="stage"/>
      <Choose id="choose"/>
      <Action id="go-live"/>
      <SignUp id="connect" />
    </div>
  )
}
