// src/utils/authService.ts
import { getAuth, onAuthStateChanged } from 'firebase/auth';

export function checkIfUserIsSignedUp(): Promise<boolean> {
  const auth = getAuth();
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        resolve(true);
      } else {
        // No user is signed in
        resolve(false);
      }
    }, (error) => {
      // Handle errors here
      reject(error);
    });
  });
}