import React, { useState } from "react";

const BlogContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    companyEmail: "",
    phoneNumber: "",
    message: "",
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add your form submission logic here
    console.log("Form submitted:", formData);
    // Reset form after submission
    setFormData({
      firstName: "",
      lastName: "",
      companyEmail: "",
      phoneNumber: "",
      message: "",
    });
    // Add success message or API call
  };

  return (
    <div className="w-full bg-black py-16 px-4 relative overflow-hidden">
      {/* Wavy lines background effect on the left */}
      {/* <div className="absolute left-0 inset-y-0 w-1/3 z-0 opacity-20">
        for adding svg eg curly line
      </div> */}

      {/* Main content container */}
      <div className="max-w-6xl mx-auto relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {/* Left column - Text content */}
          <div>
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold text-white mb-1">
  Don't Let This Be a
</h2>

<h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-8 bg-gradient-to-r from-purple-500 to-black bg-clip-text text-transparent animate-gradient">
  One-Sided Relationship!
</h1>
            

            <p className="text-lg text-white mb-1">
              Got feedback, ideas, or just want to say hi? Shoot us
            </p>
            <p className="text-lg text-white mb-1">a message.</p>
            <p className="text-lg text-white flex items-center">
              we're listening (and typing)!
              <span className="ml-2" role="img" aria-label="rocket">
                🚀
              </span>
            </p>
          </div>

          {/* Right column - Contact Form */}
          <div>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  className="w-full px-4 py-3 bg-transparent border border-blue-900 rounded-md text-white focus:outline-none focus:border-blue-500"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  className="w-full px-4 py-3 bg-transparent border border-blue-900 rounded-md text-white focus:outline-none focus:border-blue-500"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <input
                  type="email"
                  name="companyEmail"
                  placeholder="Company Email"
                  className="w-full px-4 py-3 bg-transparent border border-blue-900 rounded-md text-white focus:outline-none focus:border-blue-500"
                  value={formData.companyEmail}
                  onChange={handleInputChange}
                  required
                />
                <input
                  type="tel"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="w-full px-4 py-3 bg-transparent border border-blue-900 rounded-md text-white focus:outline-none focus:border-blue-500"
                  value={formData.phoneNumber}
                  onChange={handleInputChange}
                />
              </div>

              <textarea
                name="message"
                placeholder="What would you like to discuss?"
                rows={5}
                className="w-full px-4 py-3 bg-transparent border border-blue-900 rounded-md text-white focus:outline-none focus:border-blue-500"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>

              <div>
                <button
                  type="submit"
                  className="px-8 py-3 bg-white text-black font-medium rounded-md hover:bg-gray-200 transition-colors"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogContactForm;
