import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthLayout from './AuthLayout';
import { AuthError, CountryCode, ConfirmationResult } from '../../utils/types/auth';
import { validatePhone } from '../../utils/validation';
import OTPVerification from './OTPVerification';
import { CountrySelector, detectCountry } from './CountrySelector';
import { phoneAuth } from '../../utils/firebase';
import { collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { db } from '../../utils/firebase/config';
import { resetScrollPosition } from '../../utils/scrollHelpers';
import { trackSignup } from '../../services/trackingService';
import { saveUserProfile, checkPhoneNumberExists } from '../../services/userService';
import './auth.css';
import { MAIN_ROUTES, LEGAL_ROUTES } from "../../constants/routes";

interface SignUpFormData { 
  firstName: string;
  lastName: string;
  phoneNumber: string;
  countryCode: string;
  organizationName: string;
  designation: string;
  // location: string;
  acceptTerms: boolean;
}

// Designation options
const designationOptions = [
  "CEO/Founder",
  "CTO",
  "CFO",
  "COO",
  "Director",
  "VP",
  "Manager",
  "Analyst",
  "Associate",
  "Other"
];

// Location options (common cities in India)
const locationOptions = [
  "Mumbai",
  "Delhi",
  "Bangalore",
  "Hyderabad",
  "Chennai",
  "Kolkata",
  "Pune",
  "Ahmedabad",
  "Jaipur",
  "Surat",
  "Other"
];

const SignUp: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<SignUpFormData>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    countryCode: '+91',
    organizationName: '',
    designation: '',
    // location: '',
    acceptTerms: false,
  });
  const [errors, setErrors] = useState<AuthError>({});
  const [showOTP, setShowOTP] = useState(false);
  const [otpError, setOtpError] = useState<string | undefined>(undefined);
  const [selectedCountry, setSelectedCountry] = useState<CountryCode>({
    code: 'IN',
    name: 'India',
    dialCode: '+91',
    flag: '🇮🇳',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);

  useEffect(() => {
    // Reset scroll position when component mounts
    resetScrollPosition();
    
    const initCountry = async () => {
      const country = await detectCountry();
      setSelectedCountry(country);
      setFormData(prev => ({ ...prev, countryCode: country.dialCode }));
    };
    initCountry();
  }, []);

  // Clean up reCAPTCHA when component unmounts
  useEffect(() => {
    return () => {
      phoneAuth.clearRecaptcha();
    };
  }, []);

  const validateForm = (): AuthError => {
    const errors: AuthError = {};

    if (!formData.firstName?.trim()) {
      errors.firstName = 'Please enter your first name';
    }

    if (!formData.lastName?.trim()) {
      errors.lastName = 'Please enter your last name';
    }

    if (!formData.phoneNumber.trim()) {
      errors.identifier = 'Please enter your phone number';
    } else if (!validatePhone(formData.phoneNumber)) {
      errors.identifier = 'Please enter a valid phone number';
    }
    
    if (!formData.organizationName?.trim()) {
      errors.organizationName = 'Please enter your organization name';
    }
    
    if (!formData.designation) {
      errors.designation = 'Please select your designation';
    }
    
    // if (!formData.location) {
    //   errors.location = 'Please select your location';
    // }

    if (!formData.acceptTerms) {
      errors.acceptTerms = 'Please accept the terms and conditions';
    }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm();
    
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);
    try {
      const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
      
      // Check if phone number already exists in Firestore
      const phoneExistsCheck = await checkPhoneNumberExists(fullPhoneNumber);
      
      if (phoneExistsCheck.exists) {
        setErrors({ 
          identifier: 'This phone number is already registered. Please sign in instead.' 
        });
        setIsLoading(false);
        return;
      }
      
      // Continue with OTP flow if phone is not already registered
      const response = await phoneAuth.sendOTP(fullPhoneNumber, 'sign-up-button');
      
      if (response.success && response.data?.confirmationResult) {
        setConfirmationResult(response.data.confirmationResult);
        setShowOTP(true);
        setErrors({});
      } else {
        // Handle specific Firebase errors
        if (response.error?.code === 'auth/billing-not-enabled') {
          setErrors({ 
            identifier: 'Phone authentication is currently unavailable. Please try again later or contact support.'
          });
          console.error('Firebase billing needs to be enabled for phone auth');
        } else if (response.error?.code === 'auth/invalid-phone-number') {
          setErrors({ 
            identifier: 'The phone number is invalid. Please check the country code and number.'
          });
        } else if (response.error?.code === 'auth/quota-exceeded') {
          setErrors({ 
            identifier: 'Too many requests. Please try again later.'
          });
        } else {
          setErrors({ 
            identifier: response.error?.message || 'Failed to send verification code. Please try again.' 
          });
        }
      }
    } catch (err) {
      console.error('Error sending OTP:', err);
      setErrors({ identifier: 'An unexpected error occurred. Please try again.' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyOTP = async (data: { otp: string }) => {
    if (!confirmationResult) {
      setOtpError('Please request a new verification code');
      return;
    }

    setIsLoading(true);
    try {
      const response = await phoneAuth.verifyOTP(confirmationResult, data.otp);
      
      if (response.success && response.data?.user) {
        const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
        
        // Double check if phone number already exists (for race conditions)
        // This handles the case where someone might register the same number 
        // between our initial check and the OTP verification
        const phoneExistsCheck = await checkPhoneNumberExists(fullPhoneNumber);
        
        if (phoneExistsCheck.exists && phoneExistsCheck.userData?.uid !== response.data.user.uid) {
          setOtpError('This phone number is already registered with another account. Please use a different number.');
          setIsLoading(false);
          return;
        }
        
        // Create lead data for Firestore
        const leadData = {
          name: `${formData.firstName} ${formData.lastName}`,
          mobile: formData.phoneNumber,
          firebase_uid: response.data.user.uid,
          phone_with_country_code: fullPhoneNumber,
          signup_date: new Date().toISOString(),
          source: 'signup_form',
          organization_name: formData.organizationName,
          designation: formData.designation,
          // location: formData.location
        };
        
        // Insert lead to Firestore 'leads' collection
        try {
          await addDoc(collection(db, 'leads'), leadData);
        } catch (err) {
          console.error('Exception inserting lead data:', err);
          // Still proceed with signup flow
        }
        
        // Store user profile in Firestore
        try {
          await saveUserProfile(response.data.user.uid, {
            uid: response.data.user.uid,
            phoneNumber: fullPhoneNumber,
            firstName: formData.firstName,
            lastName: formData.lastName,
            displayName: `${formData.firstName} ${formData.lastName}`,
            // Additional metadata
            organizationName: formData.organizationName,
            designation: formData.designation,
            // location: formData.location
          });
          console.log('User profile saved to Firestore successfully');
        } catch (firestoreError) {
          console.error('Error saving user profile to Firestore:', firestoreError);
          // Continue with signup process even if Firestore save fails
        }
        
        // Store user data in localStorage for reference
        localStorage.setItem('user', JSON.stringify({
          uid: response.data.user.uid,
          phoneNumber: fullPhoneNumber,
          name: `${formData.firstName} ${formData.lastName}`,
          organizationName: formData.organizationName,
          designation: formData.designation,
          // location: formData.location
        }));

        // Save lead information to the signed_up_users collection also (for redundancy)
        try {
          await setDoc(doc(db, 'signed_up_users', response.data.user.uid), {
            user_id: response.data.user.uid,
            phone: fullPhoneNumber,
            first_name: formData.firstName,
            last_name: formData.lastName,
            organization: formData.organizationName,
            designation: formData.designation,
            // location: formData.location,
            created_at: new Date().toISOString()
          });
        } catch (err) {
          console.error('Exception inserting user data:', err);
          // Still proceed with signup flow
        }
        
        // Track the user signup
        try {
          // Get source from URL parameters if available
          const urlParams = new URLSearchParams(window.location.search);
          const source = urlParams.get('source') || 'website';
          
          await trackSignup(response.data.user.uid, fullPhoneNumber);
        } catch (trackingError) {
          // Log but don't interrupt the flow if tracking fails
          console.error('Error tracking signup:', trackingError);
        }
        
        // Navigate to home page
        navigate('/');
      } else {
        setOtpError(response.error?.message || 'Invalid verification code');
      }
    } catch (err) {
      console.error('Error verifying OTP:', err);
      setOtpError('Failed to verify code. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendOTP = async () => {
    setIsLoading(true);
    try {
      const fullPhoneNumber = `${formData.countryCode}${formData.phoneNumber}`;
      const response = await phoneAuth.sendOTP(fullPhoneNumber, 'sign-up-button');
      
      if (response.success && response.data?.confirmationResult) {
        setConfirmationResult(response.data.confirmationResult);
        setOtpError(undefined);
      } else {
        setOtpError(response.error?.message || 'Failed to resend code. Please try again.');
      }
    } catch (err) {
      console.error('Error resending OTP:', err);
      setOtpError('An unexpected error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (showOTP) {
    return (
      <AuthLayout
        title="Verify Your Account"
        subtitle="Enter the verification code we sent you"
      >
        <OTPVerification
          identifier={formData.phoneNumber}
          onVerify={handleVerifyOTP}
          onResend={handleResendOTP}
          onBack={() => setShowOTP(false)}
          error={otpError}
        />
      </AuthLayout>
    );
  }

  return (
    <AuthLayout
      title="Create Your Account"
      subtitle="Sign up to get started"
    >
      <div className="w-full">
        {showOTP ? (
          <OTPVerification
            identifier={formData.phoneNumber}
            onVerify={handleVerifyOTP}
            onResend={handleResendOTP}
            onBack={() => setShowOTP(false)}
            error={otpError}
          />
        ) : (
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  placeholder="John"
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, firstName: e.target.value }));
                    if (errors.firstName) setErrors({ ...errors, firstName: undefined });
                  }}
                  className="w-full bg-black/50 border border-gray-800 rounded-lg px-3 py-2.5 sm:py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                  disabled={isLoading}
                />
                {errors.firstName && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.firstName}</p>
                )}
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  placeholder="Doe"
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, lastName: e.target.value }));
                    if (errors.lastName) setErrors({ ...errors, lastName: undefined });
                  }}
                  className="w-full bg-black/50 border border-gray-800 rounded-lg px-3 py-2.5 sm:py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                  disabled={isLoading}
                />
                {errors.lastName && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.lastName}</p>
                )}
              </div>
            </div>
            
            <div>
              <label htmlFor="organizationName" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
                Organization Name
              </label>
              <input
                type="text"
                id="organizationName"
                placeholder="Your company name"
                value={formData.organizationName}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, organizationName: e.target.value }));
                  if (errors.organizationName) setErrors({ ...errors, organizationName: undefined });
                }}
                className="w-full bg-black/50 border border-gray-800 rounded-lg px-3 py-2.5 sm:py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                disabled={isLoading}
              />
              {errors.organizationName && (
                <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.organizationName}</p>
              )}
            </div>
            
            <div className="w-full">
              <label htmlFor="designation" className="block text-sm md:text-base font-medium text-gray-300 mb-1 sm:mb-2">
                Designation
              </label>
              <div className="relative">
                <select
                  id="designation"
                  value={formData.designation}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, designation: e.target.value }));
                    if (errors.designation) setErrors({ ...errors, designation: undefined });
                  }}
                  className="w-full bg-black/50 border border-gray-800 rounded-lg px-3 py-2.5 sm:py-3 text-white 
                  placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent 
                  text-sm sm:text-base"
                  disabled={isLoading}
                  aria-label="Select your designation"
                >
                  <option value="" disabled>Select your designation</option>
                  {designationOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
              </div>
              {errors.designation && (
                <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.designation}</p>
              )}
            </div>

              {/* <div>
                <label htmlFor="location" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
                  Location
                </label>
                <select
                  id="location"
                  value={formData.location}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, location: e.target.value }));
                    if (errors.location) setErrors({ ...errors, location: undefined });
                  }}
                  className="w-full bg-black/50 border border-gray-800 rounded-lg px-3 py-2.5 sm:py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                  disabled={isLoading}
                  aria-label="Select your location"
                >
                  <option value="" disabled>Select your location</option>
                  {locationOptions.map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </select>
                {errors.location && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.location}</p>
                )}
              </div> */}
            
            <div>
              <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-300 mb-1 sm:mb-2">
                Phone Number
              </label>
              <div className="flex gap-1 sm:gap-2">
                <CountrySelector
                  selectedCountry={selectedCountry}
                  onSelect={(country) => {
                    setSelectedCountry(country);
                    setFormData(prev => ({ ...prev, countryCode: country.dialCode }));
                  }}
                  className="flex-shrink-0"
                  disabled={isLoading}
                />
                <input
                  type="tel"
                  id="phoneNumber"
                  placeholder="Enter your mobile number"
                  value={formData.phoneNumber}
                  onChange={(e) => {
                    const value = e.target.value.replace(/\D/g, ''); // Only allow digits
                    setFormData(prev => ({ ...prev, phoneNumber: value }));
                    if (errors.identifier) setErrors({ ...errors, identifier: undefined });
                  }}
                  className="flex-grow bg-black/50 border border-gray-800 rounded-lg px-2 sm:px-4 py-2.5 sm:py-3 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent text-sm sm:text-base"
                  maxLength={15}
                  disabled={isLoading}
                  inputMode="numeric"
                  autoComplete="tel"
                />
              </div>
              {errors.identifier && (
                <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.identifier}</p>
              )}
            </div>

            <div className="flex items-start sm:items-center">
              <div className="flex items-center h-5">
                <input
                  id="acceptTerms"
                  type="checkbox"
                  checked={formData.acceptTerms}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, acceptTerms: e.target.checked }));
                    if (errors.acceptTerms) setErrors({ ...errors, acceptTerms: undefined });
                  }}
                  className="h-4 w-4 rounded border-gray-800 bg-black/50 text-purple-500 focus:ring-purple-500"
                  disabled={isLoading}
                />
              </div>
              <div className="ml-2 text-xs sm:text-sm">
                <label htmlFor="acceptTerms" className="text-gray-300">
                  I agree to the{' '}
                  <Link to={LEGAL_ROUTES.TERMS.path} className="text-purple-500 hover:text-purple-400 font-medium">
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link to={LEGAL_ROUTES.PRIVACY.path} className="text-purple-500 hover:text-purple-400 font-medium">
                    Privacy Policy
                  </Link>
                </label>
                {errors.acceptTerms && (
                  <p className="mt-1 text-xs sm:text-sm text-red-500">{errors.acceptTerms}</p>
                )}
              </div>
            </div>

            <button
              type="submit"
              className={`w-full bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white font-medium py-3 px-4 rounded-lg hover:opacity-90 transition-opacity ${isLoading ? 'opacity-70 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Sending...' : 'Send Verification Code'}
            </button>

            {/* reCAPTCHA container */}
            <div 
              id="sign-up-button" 
              className="recaptcha-container"
            ></div>

            <p className="text-center text-sm">
              Already have an account?{' '}
              <Link to={MAIN_ROUTES.SIGNIN.path} className="text-purple-400 hover:text-purple-300">
                Sign in
              </Link>
            </p>
            
            {errors.identifier && errors.identifier.includes('already registered') && (
              <div className="mt-2 text-center">
                <Link 
                  to={MAIN_ROUTES.SIGNIN.path} 
                  className="text-purple-400 hover:text-purple-300 font-medium"
                >
                  Go to Sign In
                </Link>
              </div>
            )}
          </form>
        )}
      </div>
    </AuthLayout>
  );
};

export default SignUp; 