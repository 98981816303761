import React from 'react'
import Navbar from "../Navbar";
import { HeroSection } from "./components/HeroSection";
import { ImagineSection } from "./components/ImagineSection";
import { WealthSuit } from "./components/WealthSuit";
import { GlobalFirm } from "./components/GlobalFirm";

export const PlatformAndFeatures = () => {
  return (
    <div className="w-full bg-black">
      <Navbar />
      <HeroSection />
      <ImagineSection />
      <WealthSuit />
      <GlobalFirm />
    </div>
  );
};
