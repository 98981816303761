// Pricing sections and content
export interface PricingSection {
  id: string;
  title: string;
  content: string;
}

export const PRICING_HERO = {
  title: "Pricing",
  description1: "We believe in transparent pricing that aligns with your business goals.",
  description2: "Our flexible plans ensure you only pay for what you need as your firm grows.",
  conclusion: {
    title: "No hidden fees, no surprises",
    content: "Just straightforward value for your investment"
  }
};

export const PRICING_APPROACH = {
  title: "Hence Our Approach",
  description: "Before implementing Valuefy's automation-first wealth management solution, we conduct a thorough assessment of your current technology infrastructure to ensure a smooth transition, optimal performance, and long-term success.",
  tagline: "A Solution Tailored to Your Systems. Optimized for Efficiency. Built for Scale",
  ctaText: "Request an Assessment"
};

export const PRICING_NAV_SECTIONS = [
  {
    id: "approach",
    label: "Our Approach"
  },
  {
    id: "stage",
    label: "Stage 1-4"
  },
  {
    id: "go-live",
    label: "Go Live Strategy"
  },
  {
    id: "connect",
    label: "Let's Connect"
  }
];

export const PRICING_STAGES = {
  title: "Stages of Implementation",
  description: "Our proven 4-stage approach ensures a smooth transition to Valuefy's platform, minimizing disruption while maximizing ROI.",
  stages: [
    {
      title: "Stage 1: Discovery & Assessment",
      description: "We thoroughly analyze your current systems, identify integration points, and understand your specific requirements."
    },
    {
      title: "Stage 2: Design & Configuration",
      description: "Our team configures the platform to match your workflow, integrating with existing systems and customizing features."
    },
    {
      title: "Stage 3: Testing & Training",
      description: "We conduct rigorous testing and comprehensive training sessions for your team to ensure a smooth adoption."
    },
    {
      title: "Stage 4: Deployment & Support",
      description: "Go-live implementation with continuous monitoring and dedicated support to address any challenges."
    }
  ]
};

export const PRICING_GO_LIVE = {
  title: "Go Live Strategy",
  description: "Our comprehensive go-live strategy ensures your team is fully prepared and supported throughout the transition to Valuefy's platform. We focus on minimizing disruption while maximizing adoption through:",
  strategies: [
    {
      title: "Phased Rollout",
      description: "We implement the platform in carefully planned phases, allowing your team to adapt gradually while maintaining operational continuity."
    },
    {
      title: "Dedicated Support Team",
      description: "Our specialists provide on-site and remote support during the critical go-live period, ensuring any issues are addressed immediately."
    },
    {
      title: "Comprehensive Training",
      description: "We provide role-specific training for all users, ensuring everyone is confident and proficient with the new platform."
    },
    {
      title: "Data Migration & Validation",
      description: "Rigorous data migration protocols with multiple validation checkpoints ensure all your critical information transfers accurately."
    }
  ],
  ctaText: "Schedule a Strategy Session"
};

export const PRICING_CONNECT = {
  title: "Let's Connect",
  description: "Ready to explore how Valuefy can transform your wealth management operations? Reach out to our team for a personalized consultation.",
  form: {
    namePlaceholder: "Your Name",
    emailPlaceholder: "Email Address",
    companyPlaceholder: "Company",
    messagePlaceholder: "Your Message",
    submitText: "Send Message",
    submittingText: "Sending..."
  },
  success: {
    title: "Message Received!",
    message: "Thank you for reaching out. One of our experts will contact you within 24 hours to discuss your needs.",
    buttonText: "Send Another Message"
  }
}; 