import { getAllRouteMetadata } from '../../constants/routes';
import { getDynamicRouteMetadata } from '../../constants/dynamicRoutes';
import { RouteMetadata } from '../../types/routes';

// Get route metadata from centralized routes configuration
// First try to get from dynamic routes, fall back to static routes
export const routeSeoMetadata = () => {
  const dynamicMetadata = getDynamicRouteMetadata();
  return Object.keys(dynamicMetadata).length > 0 
    ? dynamicMetadata 
    : getAllRouteMetadata();
};

// Function to get SEO metadata for a given route path
export const getSeoMetadataForRoute = (pathname: string): RouteMetadata | undefined => {
  // Safety check for undefined pathname or metadata
  if (!pathname) return undefined;
  
  const metadata = routeSeoMetadata();
  return metadata ? metadata[pathname] : undefined;
}; 