import { Mail, HelpCircle, ArrowRight, Volume2 } from "lucide-react";
import CardBg from "../assets/background/card-bg.png";

const HelpCenter = () => {
  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center px-4 py-16">
      {/* Main Title */}
      <h1 className="text-5xl md:text-6xl font-bold mb-16 text-center">
        How Can We Help?
      </h1>

      {/* Cards Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full max-w-5xl relative z-10 mx-auto">
        {/* General Inquiries Card */}
        <div className="relative bg-[#0a0110] border-gray-800 rounded-lg p-8">
          <div className="rounded-full border border-gray-700 w-12 h-12 flex items-center justify-center mb-6">
            <Mail size={24} />
          </div>
          <h2 className="text-3xl font-bold mb-4">General Inquiries</h2>
          <p className="text-xl mb-24">
            Got a question? Let's chat. Reach us at:
          </p>
          <div className="flex items-center gap-3">
            <Mail size={18} />
            <span className="font-bold">contact@valuefy.com</span>{" "}
            {/* Bold text */}
          </div>

          <img
            src={CardBg}
            alt="Decorative"
            className="absolute bottom-0 right-0 w-50 h-50 scale-200"
          />
        </div>

        {/* Client Support Card */}
        <div className="relative bg-[#0a0110] border-gray-800 rounded-lg p-8">
          <div className="rounded-full bg-black border  border-gray-700 w-12 h-12 flex items-center justify-center mb-6">
            <HelpCircle size={24} />
          </div>
          <h2 className="text-3xl font-bold mb-4">Client Support</h2>
          <p className="text-xl mb-8">
            Already a Valuefy client? Our team is here for you.
          </p>
          <button className="mt-16 bg-white font-bold text-black px-6 py-3 rounded-md flex items-center gap-2 hover:bg-gray-200 transition-colors">
            <span>Log in to Help Center</span>
            <ArrowRight size={18} />
          </button>

          <img
            src={CardBg}
            alt="Decorative"
            className="absolute bottom-0 right-0 w-50 h-50 scale-200"
          />
        </div>

        {/* Press and Media Card */}
        <div className="relative bg-[#0a0110] border-gray-800 rounded-lg p-8">
          <div className="rounded-full bg-black border border-gray-700 w-12 h-12 flex items-center justify-center mb-6">
            <HelpCircle size={24} />
          </div>
          <h2 className="text-3xl font-bold mb-4">Press and Media Inquiries</h2>
          <p className="text-xl mb-8">
            For interviews, press releases and media requests:
          </p>
          <div className="flex items-center gap-3">
            <Mail size={18} />
            <span className="font-bold">pr@valuefy.com</span> {/* Bold text */}
          </div>

          <img
            src={CardBg}
            alt="Decorative"
            className="absolute bottom-0 right-0 w-50 h-50 scale-200"
          />
        </div>

        {/* Marketing Card */}
        <div className="relative bg-[#0a0110] border-gray-800 rounded-lg p-8">
          <div className=" border-gray-700 w-12 h-12 flex items-center justify-center mb-6">
            <Volume2 size={24} />
          </div>
          <h2 className="text-3xl font-bold mb-4">Marketing & Partnerships</h2>
          <p className="text-xl mb-8">
            For collaborations, sponsorships, and marketing opportunities:
          </p>
          <button className="mt-16 bg-white font-bold text-black px-6 py-3 rounded-md flex items-center gap-2 hover:bg-gray-200 transition-colors">
            <span>Log in to Help Center</span>
            <ArrowRight size={18} />
          </button>

          <img
            src={CardBg}
            alt="Decorative"
            className="absolute bottom-0 right-0 w-50 h-50 scale-200"
          />
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;
