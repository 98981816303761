import React from "react";

import LandingHero from "./LandingHero";
import demo from "../assets/product-demo/prod-demo.png";
import WealthManagementUI from "./MagicNumbers";
import { RainbowButton } from "./magicui/rainbow-button";
import { useNavigate } from "react-router-dom";
import { checkIfUserIsSignedUp } from "@/utils/authService";

interface HomeProps {
  title?: string;
  navbarLogo?: string;
  navLinks?: Array<{ label: string; href: string }>;
  heroHeadline?: string;
  heroSubheading?: string;
  heroImageSrc?: string;
}

const Home = ({
  title = "Valuefy",
  heroHeadline = "Unlock your wealth potential",
  heroSubheading = "Experience seamless investment management with our free trial or request a personalised demo today.",
  heroImageSrc = "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?w=800&q=80",
}: HomeProps) => {
  // Handle navigation actions
  const handleSignUp = () => {
    console.log("Sign up clicked");
    // Implement sign up logic
  };

  React.useEffect(() => {
    // Set page title
    document.title = title;
  }, [title]);

  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const isUserSignedUp = await checkIfUserIsSignedUp();
      
      if (isUserSignedUp) {
        // Use window.location for navigating to a different application
        window.location.href = "/demo/dashboard/";  // Note the trailing slash
      } else {
        // This stays as React Router navigation within the same app
        navigate("/signup");
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
      navigate("/signup");
    }
  };

  return (
    <div className="min-h-screen bg-black">
      {/* WealthManagementUI component - already responsive from previous edit */}
      <WealthManagementUI />

      {/* Background decorative elements */}
      <div className="absolute bottom-0 left-0 opacity-30 w-16 sm:w-24 md:w-32 lg:w-auto">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60" cy="60" r="50" stroke="#444" strokeWidth="2" />
          <circle cx="60" cy="60" r="40" stroke="#444" strokeWidth="2" />
          <circle cx="60" cy="60" r="30" stroke="#444" strokeWidth="2" />
        </svg>
      </div>

      {/* Demo section */}
      <div className="hidden md:block max-w-5xl mx-auto px-4 sm:px-6 md:px-8">
        {/* Heading */}
        <h2 className="text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-8 md:mb-10 text-white">
          See Valuefy in Action
        </h2>

        <div className="relative rounded-lg overflow-hidden mb-8 md:mb-12 shadow-xl">
          <img
            src={demo}
            alt="Valuefy Platform Dashboard"
            className="w-full h-auto object-cover rounded-lg"
            style={{
              filter: "blur(1.5px)",
              opacity: 0.7,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />

          {/* Purple Gradient Button Overlay */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-0">
            <button
              className="text-white font-medium py-2.5 md:py-3 px-6 md:px-8 rounded-md shadow-lg transition-all duration-300 text-sm md:text-base"
              style={{
                background:
                  "linear-gradient(90deg, rgb(128,108,234), rgb(162,101,230))",
                boxShadow: "0 4px 15px rgba(128, 108, 234, 0.4)",
              }}
              onClick={handleClick}
            >
              Experience the Product
            </button>
          </div>
        </div>
      </div>

      {/* Call-to-action section */}
      <div className="flex flex-col items-center justify-center space-y-6 sm:space-y-8 py-10 sm:py-12 md:py-16 px-4 sm:px-6 w-full">
        <h1 className="hidden md:block text-2xl sm:text-3xl md:text-4xl text-center text-white max-w-4xl">
          Experience a smarter, faster, and more efficient way to manage wealth.
        </h1>

        {/* Wrapper to center both buttons */}
        <div className="hidden md:block flex flex-col items-center space-y-4">
          <RainbowButton onClick={handleClick} className="bg-purple-500 hover:bg-purple-600 text-white py-3 sm:py-4 md:py-6 px-6 sm:px-7 md:px-8 rounded-md text-base sm:text-lg font-medium w-full sm:w-auto max-w-xs sm:max-w-none">
            Request A Demo
          </RainbowButton>

          {/* <div className="flex items-center space-x-2 mt-2 sm:mt-4 cursor-pointer ml-6 group">
            <a
              href="#"
              className="text-white flex items-center gap-1 text-sm sm:text-base group relative w-fit"
            >
              <span className="font-medium">Explore Solutions</span>
              <svg
                className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-white transition-all duration-300 group-hover:w-full"></span>
            </a>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="transition-transform duration-300 group-hover:translate-x-1"
            >
              <circle
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="2"
              />
              <path
                d="M12 8L16 12L12 16"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div> */}
        </div>
      </div>

      {/* Main hero section */}
      <LandingHero
        headline={heroHeadline}
        subheading={heroSubheading}
        imageSrc={heroImageSrc}
        onSignUp={handleSignUp}
      />
    </div>
  );
};

export default Home;