import folio from "../assets/folio.png"
import arrowRight from "../assets/arrow-right.png"
import goLive from '../assets/go-live.png'

interface ActionProps {
  id?: string;
}

export const Action: React.FC<ActionProps> = ({ id }) => {
  return (
    <div id={id} className="w-[80%] mx-auto">
        <img src={goLive} alt="Go Live Table" className="mb-12 w-full"/>
        <div className="text-white text-center text-4xl font-bold mb-12 mt-10">
            See Valuefy in Action
        </div>
        <img src={folio} alt="" className="mb-12"/>
        <div className="text-white text-center text-2xl font-bold mb-10">Experience a smarter, faster, and more efficient way to manage wealth.</div>
        <div className="flex justify-center items-center">
            <button className="bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-white px-8 py-2 rounded-md  mx-auto">Request a Demo</button>
        </div>
        
        <div className="flex justify-center items-center gap-1">
        <div className="text-white text-center text-[14px] font-bold mt-4 pb-6">Explore Solutions </div>
        <img src={arrowRight} alt="" className="w-4 h-4 mb-2"/>
        </div>
        
    </div>
  )
}