import { RouteData, RouteType, RouteMetadata } from '../types/routes';
import { AppRouteGroups, groupRoutesForApp, createFlatRoutes, getAllRouteMetadataFromGroups } from '../utils/routesAdapter';
import { getAllRoutes, getGroupedRoutes } from '../services/routesService';

// Static fallback data in case the dynamic routes fail to load
import { MAIN_ROUTES, SOLUTION_ROUTES, LEGAL_ROUTES, SUPPORT_ROUTES, SOCIAL_LINKS, ROUTES } from './routes';

// Default values for dynamic routes while they're loading
let dynamicMainRoutes: typeof MAIN_ROUTES = MAIN_ROUTES;
let dynamicSolutionRoutes: typeof SOLUTION_ROUTES = SOLUTION_ROUTES;
let dynamicLegalRoutes: typeof LEGAL_ROUTES = LEGAL_ROUTES;
let dynamicSupportRoutes: typeof SUPPORT_ROUTES = SUPPORT_ROUTES;
let dynamicSocialLinks: typeof SOCIAL_LINKS = SOCIAL_LINKS;
let dynamicFlatRoutes: typeof ROUTES = ROUTES;
let dynamicRouteMetadata: Record<string, RouteMetadata> = {};

let isInitialized = false;
let isLoading = false;

/**
 * Initialize dynamic routes by loading them from the database
 */
export async function initializeDynamicRoutes(): Promise<void> {
  // Prevent multiple simultaneous initializations
  if (isLoading) return;
  isLoading = true;
  
  try {
    // Fetch all routes from the database/cache
    const routes = await getAllRoutes();
    
    // Group and convert routes to the format expected by the application
    const groupedRoutes = groupRoutesForApp(routes);
    
    // Update the dynamic route objects with type assertion to ensure compatibility
    dynamicMainRoutes = groupedRoutes.MAIN_ROUTES as typeof MAIN_ROUTES;
    dynamicSolutionRoutes = groupedRoutes.SOLUTION_ROUTES as typeof SOLUTION_ROUTES;
    dynamicLegalRoutes = groupedRoutes.LEGAL_ROUTES as typeof LEGAL_ROUTES;
    dynamicSupportRoutes = groupedRoutes.SUPPORT_ROUTES as typeof SUPPORT_ROUTES;
    dynamicSocialLinks = groupedRoutes.SOCIAL_LINKS as typeof SOCIAL_LINKS;
    
    // Create flat routes for backward compatibility
    dynamicFlatRoutes = createFlatRoutes(groupedRoutes) as typeof ROUTES;
    
    // Create route metadata for SEO
    dynamicRouteMetadata = getAllRouteMetadataFromGroups(groupedRoutes);
    
    isInitialized = true;
    console.log('Dynamic routes initialized successfully');
  } catch (error) {
    console.error('Failed to initialize dynamic routes, using static fallbacks:', error);
    // Keep using the static fallbacks in case of error
  } finally {
    isLoading = false;
  }
}

// Export dynamic routes (with fallback to static routes)
export const DYNAMIC_MAIN_ROUTES = dynamicMainRoutes;
export const DYNAMIC_SOLUTION_ROUTES = dynamicSolutionRoutes;
export const DYNAMIC_LEGAL_ROUTES = dynamicLegalRoutes;
export const DYNAMIC_SUPPORT_ROUTES = dynamicSupportRoutes;
export const DYNAMIC_SOCIAL_LINKS = dynamicSocialLinks;
export const DYNAMIC_ROUTES = dynamicFlatRoutes;

/**
 * Get all route metadata for SEO purposes
 */
export function getDynamicRouteMetadata(): Record<string, RouteMetadata> {
  return dynamicRouteMetadata;
}

// Automatically initialize routes when this module is imported
initializeDynamicRoutes().catch(error => {
  console.error('Error during automatic route initialization:', error);
}); 