import Image from '../assets/imagineImg.png'
import map from "../assets/Map.png";
import orbitCircle from "../assets/orbitCircles.png";
import box1 from "../assets/box1.png";
import box2 from "../assets/box2.png";
import { useNavigate } from "react-router-dom";
// import box3 from "../assets/box3.png";

export const ImagineSection = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/signup");
  };
  return (
    <div className="w-[100%] mx-auto text-white pt-20 bg-gradient-to-b from-[#000000] via-[#000000] to-[#140029]">
      <div className="w-[85%] mx-auto flex justify-between items-center flex-wrap gap-10">
        <div className="lg:w-[60%] md:w-[80%] xs:w-[100%]">
          <div className="text-5xl font-bold mb-8">
            Valuefy eliminates these silos.
          </div>
          <div className="text-3xl font-semibold mb-8">
            We hate it, We fight it like our life depends on it - all for you
          </div>
          <div className="text-2xl">
            We bring all your wealth systems together into a seamlessly
            connected ecosystem—empowering firms to focus on clients, drive
            efficiency, and scale with confidence.
          </div>
        </div>
        <div className="lg:w-[35%] md:w-[80%] xs:w-[100%]">
          <img src={Image} alt="" className="w-[100%] " />
        </div>
      </div>

      <div className="hidden md:block relative">
        <img src={map} alt="" className="w-[100%] lg:h-[600px] md:h-[400px] xs:h-[300px] xxs:h-[250px]  object-cover" />
        <img
          src={orbitCircle}
          alt=""
          className="w-[55%] absolute top-[15%] left-[22%] "
        />
        <img
          src={box1}
          alt=""
          className="absolute top-[20%] left-[60%] lg:w-[450px] md:w-[200px] xs:w-[190px] xxs:w-[150px] "
        />
        <img
          src={box2}
          alt=""
          className="absolute top-[15%] left-[8%] lg:w-[450px] md:w-[200px] xs:w-[190px] xxs:w-[150px] "
        />
        <div className="w-[100%] bg-gradient-to-b from-[#140029]  to-[#000000]">   
        <div className="w-[100%]  flex justify-center items-center">
          <div className="w-[45%] text-2xl text-center">
            {" "}
            All so that you can focus on doing what you are best at Wealth
            Management
          </div>
        </div>
        <div className="w-[100%] flex justify-center items-center mt-10">
            <button onClick={handleClick} className="lg:w-[17%] md:w-[20%] xs:w-[30%] xxs:w-[40%] bg-white px-4 py-2 rounded-md bg-gradient-to-b from-[#6764EA]  to-[#B17EEB] mb-10">See How It Works</button>
        </div>
        </div>
      </div>
    </div>
  );
};

