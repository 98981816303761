import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import WealthManagers from "../assets/platform/wealth-managers.png";
import RMAdvisors from "../assets/platform/rm-advisors.png";
import CIOs from "../assets/platform/cios.png";
import FamilyOffices from "../assets/platform/family-offices.png";
import OperationsTeam from "../assets/platform/operations-team.png";
import CompilanceOfficers from "../assets/platform/compilance-officers.png";
import { ShinyButton } from "./magicui/shiny-button";
import { SOLUTION_ROUTES } from "../constants/routes";

interface TabContent {
  title: string;
  heading: string;
  description: string;
  buttonText: string;
  image: React.ReactNode;
}

const ValuefyPlatform: React.FC = () => {
  const tabs = [
    "Wealth Managers",
    "RM & Advisors",
    "Compliance Officers",
    "Operations Teams",
    "CIOs",
    "Family Offices",
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate(); // Hook for navigating to other pages

  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
  };

  const tabContents: Record<string, TabContent> = {
    "Wealth Managers": {
      title:
        "Unlock deep insights, optimize portfolios, and deliver exceptional service.",
      heading:
        "Unlock deep insights, optimize portfolios, and deliver exceptional service.",
      description:
        "Unlock deep insights, optimize portfolios, and deliver exceptional service.",
      buttonText: "Find solutions to your problems",
      image: (
        <img
          src={WealthManagers}
          alt="Portfolio dashboard showing $12.4M value with a growth chart"
          className="rounded-lg"
        />
      ),
    },
    "RM & Advisors": {
      title: "Automate client interactions and enhance relationship-building.",
      heading:
        "Automate client interactions and enhance relationship-building.",
      description:
        "We have put independent advice and boutique wealth managers at the core of our product design while crafting a front-to-back digital wealth management product that helps you scale and focus on your client.",
      buttonText: "Find solutions to your problems",
      image: (
        <img
          src={RMAdvisors}
          alt="Client interaction dashboard showing communication interface"
          className="rounded-lg"
        />
      ),
    },
    "Compliance Officers": {
      title: "Ensure regulatory readiness with AI-driven compliance tracking.",
      heading:
        "Ensure regulatory readiness with AI-driven compliance tracking.",
      description:
        "We have put independent advice and boutique wealth managers at the core of our product design while crafting a front-to-back digital wealth management product that helps you scale and focus on your client.",
      buttonText: "Find solutions to your problems",
      image: (
        <img
          src={CompilanceOfficers}
          alt="Compliance dashboard showing regulatory reports"
          className="rounded-lg"
        />
      ),
    },
    "Operations Teams": {
      title:
        "Eliminate inefficiencies with automated workflows and data aggregation.",
      heading:
        "Eliminate inefficiencies with automated workflows and data aggregation.",
      description:
        "We have put independent advice and boutique wealth managers at the core of our product design while crafting a front-to-back digital wealth management product that helps you scale and focus on your client.",
      buttonText: "Find solutions to your problems",
      image: (
        <img
          src={OperationsTeam}
          alt="Operations workflow dashboard"
          className="rounded-lg"
        />
      ),
    },
    CIOs: {
      title:
        "Stay ahead of market shifts with intelligent analytics and forecasting.",
      heading:
        "Stay ahead of market shifts with intelligent analytics and forecasting.",
      description:
        "We have put independent advice and boutique wealth managers at the core of our product design while crafting a front-to-back digital wealth management product that helps you scale and focus on your client.",
      buttonText: "Find solutions to your problems",
      image: (
        <img src={CIOs} alt="CIO analytics dashboard" className="rounded-lg" />
      ),
    },
    "Family Offices": {
      title:
        "Preserve and Grow Generational Wealth with Confidence \nWe understand the unique needs of Family Offices",
      heading:
        "Preserve and Grow Generational Wealth with Confidence \nWe understand the unique needs of Family Offices",
      description:
        "Balancing wealth preservation, multi-generational planning, and strategic growth. Our platform is designed to empower your investment decisions with cutting-edge analytics and seamless automation.",
      buttonText: "Find solutions to your problems",
      image: (
        <img
          src={FamilyOffices}
          alt="Family office wealth management dashboard"
          className="rounded-lg"
        />
      ),
    },
  };

  const activeContent = tabContents[activeTab];

  const handleSolutionClick = (index: number) => {
    setActiveTab(tabs[index]);
    // Navigate to corresponding solution page
    if (index === 0) {
      navigate(SOLUTION_ROUTES.WEALTH_MANAGER.path, { state: { scrollToTop: true } });
    } else if (index === 1) {
      navigate(SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path, { state: { scrollToTop: true } });
    } else if (index === 2) {
      navigate(SOLUTION_ROUTES.COMPLIANCE_OFFICER.path, { state: { scrollToTop: true } });
    } else if (index === 3) {
      navigate(SOLUTION_ROUTES.OPERATION_TEAM.path, { state: { scrollToTop: true } });
    } else if (index === 4) {
      navigate(SOLUTION_ROUTES.CIO.path, { state: { scrollToTop: true } });
    } else if (index === 5) {
      navigate(SOLUTION_ROUTES.FAMILY_OFFICE.path, { state: { scrollToTop: true } });
    }
  };

  return (
    <div className="min-h-screen bg-black text-white p-6 pt-10">
      {/* Header */}
      <div className="max-w-8xl mx-auto">
        <h1 className="text-4xl md:text-7xl font-bold text-center mb-4">
          WHO IS VALUEFY FOR?
        </h1>
        <p className="text-xl text-center mb-6">
          A platform built for every stakeholder in wealth management
        </p>

        {/* Tabs */}
        <div className="lg:px-4">
          <div className="flex mb-8 min-w-64 md:justify-center relative overflow-x-auto">
            {tabs.map((tab) => (
              <div
                key={tab}
                className={`px-7 pb-6 cursor-pointer text-center text-xl border-b border-gray-700 ${
                  activeTab === tab
                    ? "border-b-4 border-white font-bold pb-6"
                    : "text-gray-400 font-normal"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </div>
            ))}
            {/* Gray line below all the tabs */}
          </div>
        </div>

        {/* Content */}
        <div className="flex flex-col gap-4 items-center text-center md:items-start md:text-left md:flex-row tab-content">
          {/* Left side - Image */}
          <div className="md:w-1/2 mb-8 md:mb-0">{activeContent.image}</div>

          {/* Right side - Text content */}
          <div className="md:w-1/2">
            <h2 className="text-sm md:text-3xl font-bold mb-6">
              {activeContent.title}
            </h2>
            <p className="text-sm md:text-lg mb-6">
              {activeContent.description}
            </p>
            {/* <button
              className="border border-blue-500 text-white hover:bg-white hover:text-black px-6 py-3 rounded-lg transition-all duration-300"
              onClick={handleButtonClick}
              onClick={handleButtonClick}
            >
              {activeContent.buttonText}
            </button> */}

            <ShinyButton
              className="border border-blue-500 text-white hover:bg-white hover:text-black px-6 py-3 rounded-lg transition-all duration-300"
              onClick={() => handleSolutionClick(tabs.indexOf(activeTab))}
            >
              {" "}
              {activeContent.buttonText}
            </ShinyButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValuefyPlatform;
