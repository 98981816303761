import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BLOG_POSTS } from './blogs.constants';

// Import only the type, not the variable, to avoid naming conflicts
import type { BlogPost as ImportedBlogPost } from './blogs.constants';

interface RelatedArticlesProps {
  currentPostId: string;
  numberOfPosts?: number; // Initial number of related posts to display
}

const BlogRelatedArticles: React.FC<RelatedArticlesProps> = ({ 
  currentPostId, 
  numberOfPosts = 3 
}) => {
  const [visibleCount, setVisibleCount] = useState(numberOfPosts);
  const [relatedPosts, setRelatedPosts] = useState<ImportedBlogPost[]>([]);
  
  useEffect(() => {
    // Find the current post to get its categories
    const currentPost = BLOG_POSTS.find(post => post.id === currentPostId);
    
    if (currentPost) {
      // Get posts that share at least one category with the current post
      const related = BLOG_POSTS
        .filter(post => {
          // Skip the current post
          if (post.id === currentPostId) return false;
          
          // Check if any category matches
          return post.categories.some(category => 
            currentPost.categories.includes(category)
          );
        })
        // Sort by relevance (number of matching categories)
        .sort((a, b) => {
          const aMatches = a.categories.filter(cat => 
            currentPost.categories.includes(cat)
          ).length;
          
          const bMatches = b.categories.filter(cat => 
            currentPost.categories.includes(cat)
          ).length;
          
          return bMatches - aMatches;
        });
      
      setRelatedPosts(related);
    } else {
      // Fallback to random posts if current post not found
      const otherPosts = BLOG_POSTS.filter(post => post.id !== currentPostId);
      setRelatedPosts(otherPosts);
    }
    
    // Reset visible count when the current post changes
    setVisibleCount(numberOfPosts);
  }, [currentPostId, numberOfPosts]);

  // Handle View More click
  const handleViewMore = () => {
    setVisibleCount(prev => prev + 3);
  };
  
  // Get the posts to display
  const postsToDisplay = relatedPosts.slice(0, visibleCount);
  
  // Function to handle scrolling to top when clicking links
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-black text-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl font-bold mb-8 text-center">Related Articles</h2>
        
        {relatedPosts.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {postsToDisplay.map((post) => (
                <div key={post.id} className="bg-gray-900 rounded-lg overflow-hidden hover:shadow-lg transition-shadow">
                  <Link to={post.url} onClick={handleLinkClick}>
                    <div className="h-48 overflow-hidden">
                      <img 
                        src={post.imageUrl} 
                        alt={post.title} 
                        className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                      />
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-bold mb-2 line-clamp-2">{post.title}</h3>
                      <p className="text-sm text-gray-400 line-clamp-2">{post.description}</p>
                      <div className="mt-2">
                        {post.categories.map((category: string, index: number) => (
                          <span key={index} className="text-xs text-purple-400">
                            {category}{index < post.categories.length - 1 ? ', ' : ''}
                          </span>
                        ))}
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
            
            {/* View More button - only show if there are more related posts to display */}
            {visibleCount < relatedPosts.length && (
              <div className="text-center mt-8">
                <button 
                  onClick={handleViewMore}
                  className="inline-block px-6 py-3 border border-purple-500 text-purple-500 rounded hover:bg-purple-500 hover:text-white transition-colors"
                >
                  View More Related Articles
                </button>
              </div>
            )}
          </>
        ) : (
          <p className="text-center text-gray-400">No related articles found.</p>
        )}
      </div>
    </section>
  );
};

export default BlogRelatedArticles;