import { fetchAllRoutes, fetchRoutesByType, groupRoutesByType } from '../utils/firebase/routes';
import { getCache, setCache, CACHE_KEYS, getRoutesTTL } from '../utils/redis';
import { RouteData, RouteType } from '../types/routes';

/**
 * Get all routes with Redis caching support
 */
export async function getAllRoutes(): Promise<RouteData[]> {
  try {
    // Try to get from cache first
    const cachedRoutes = await getCache<RouteData[]>(CACHE_KEYS.ALL_ROUTES);
    
    // If found in cache, return cached data
    if (cachedRoutes) {
      console.log('Routes fetched from cache');
      return cachedRoutes;
    }
    
    // If not in cache, fetch from Firebase
    const routes = await fetchAllRoutes();
    
    // Store in cache for future requests - using default TTL
    await setCache(CACHE_KEYS.ALL_ROUTES, routes);
    
    console.log('Routes fetched from Firebase and cached');
    return routes;
  } catch (error) {
    console.error('Error getting all routes:', error);
    throw error;
  }
}

/**
 * Get routes by type with Redis caching support
 */
export async function getRoutesByType(routeType: RouteType): Promise<RouteData[]> {
  try {
    // Try to get from cache first
    const cacheKey = CACHE_KEYS.ROUTES_BY_TYPE(routeType);
    const cachedRoutes = await getCache<RouteData[]>(cacheKey);
    
    // If found in cache, return cached data
    if (cachedRoutes) {
      console.log(`${routeType} routes fetched from cache`);
      return cachedRoutes;
    }
    
    // If not in cache, fetch from Firebase
    const routes = await fetchRoutesByType(routeType);
    
    // Store in cache for future requests - using default TTL
    await setCache(cacheKey, routes);
    
    console.log(`${routeType} routes fetched from Firebase and cached`);
    return routes;
  } catch (error) {
    console.error(`Error getting ${routeType} routes:`, error);
    throw error;
  }
}

/**
 * Get grouped routes with caching support
 */
export async function getGroupedRoutes(): Promise<Record<string, Record<string, RouteData>>> {
  try {
    // Get all routes (this function already has caching)
    const routes = await getAllRoutes();
    
    // Group routes by type
    return groupRoutesByType(routes);
  } catch (error) {
    console.error('Error getting grouped routes:', error);
    throw error;
  }
}

/**
 * Get main navigation routes (only MAIN type)
 */
export async function getMainNavRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.MAIN);
}

/**
 * Get solution routes (only SOLUTION type)
 */
export async function getSolutionRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.SOLUTION);
}

/**
 * Get legal routes (only LEGAL type)
 */
export async function getLegalRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.LEGAL);
}

/**
 * Get support routes (only SUPPORT type)
 */
export async function getSupportRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.SUPPORT);
}

/**
 * Get social routes (only SOCIAL type)
 */
export async function getSocialRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.SOCIAL);
}

/**
 * Get auth routes (only AUTH type)
 */
export async function getAuthRoutes(): Promise<RouteData[]> {
  return getRoutesByType(RouteType.AUTH);
} 