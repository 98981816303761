import grid from '../assets/grid.png';
import { PRICING_HERO } from '../../../constants/pricing';

export const PricingHeroSection = () => {
  return (
    <div className="w-full bg-gradient-to-b from-[#000000] via-[#160d21] to-[#000000]">
        <div className="w-[90%] mx-auto">
            <h1 className="text-white text-6xl font-bold pt-10 leading-tight">Seamless Integration
                <br/> <span className="bg-gradient-to-r from-[#8D8DFF] to-[#B770FF] text-transparent bg-clip-text">Maximum Impact</span>
            </h1>
            <p className="text-white text-[22px] pt-10 lg:w-[70%] w-[100%] leading-relaxed">
                {PRICING_HERO.description1}
            </p>
            <p className="text-white text-[22px] pt-10 lg:w-[70%] w-[100%] leading-relaxed">
                {PRICING_HERO.description2}
            </p>
            <div className="w-[100%] text-center text-white flex flex-col justify-center items-center gap-5 mt-[120px] pb-8">
                <div className="text-4xl font-bold">{PRICING_HERO.conclusion.title}</div>
                <div className="md:w-[75%] w-[100%] text-[22px]">{PRICING_HERO.conclusion.content}
                </div>
            </div>
        </div>
        <div className="w-full mx-0 mb-2">
            <img src={grid} alt="Valuefy Grid" className="w-full" />
            </div>
    </div>          
  )
}
