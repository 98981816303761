import { useNavigate } from "react-router-dom";
import demo from "../assets/product-demo/prod-demo.png";

const ValuefyInAction = () => {
  const navigate = useNavigate();
  const handleNavigation = (path: string) => {
    navigate(path);
  };
  return (
    <section className="bg-black text-white py-8 md:py-16 px-4 md:px-6 relative">
      <div className="absolute bottom-0 left-0 opacity-30 hidden md:block">
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="60" cy="60" r="50" stroke="#444" strokeWidth="2" />
          <circle cx="60" cy="60" r="40" stroke="#444" strokeWidth="2" />
          <circle cx="60" cy="60" r="30" stroke="#444" strokeWidth="2" />
        </svg>
      </div>

      <div className="max-w-5xl mx-auto">
        {/* Heading */}
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-6 md:mb-10">
          See Valuefy in Action
        </h2>

        {/* Dashboard Image Container */}
        <div className="relative rounded-lg overflow-hidden mb-8 md:mb-12 shadow-xl">
          <img
            src={demo}
            alt="Valuefy Platform Dashboard"
            className="w-full h-auto object-cover rounded-lg"
            style={{
              filter: "blur(1.5px)",
              opacity: 0.7,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          />

          {/* Purple Gradient Button Overlay */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-0">
            <button
              className="text-white font-medium py-2.5 md:py-3 px-6 md:px-8 rounded-md shadow-lg transition-all duration-300 text-sm md:text-base"
              style={{
                background:
                  "linear-gradient(90deg, rgb(128,108,234), rgb(162,101,230))",
                boxShadow: "0 4px 15px rgba(128, 108, 234, 0.4)",
              }}
              onClick={() => {
                handleNavigation("/signup");
              }}
            >
              Experience the Product
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center space-y-6 md:space-y-8 py-8 md:py-16 px-2 md:px-4 w-full">
          <h1 className="text-xl md:text-2xl lg:text-4xl text-center text-white md:whitespace-nowrap px-4 md:px-0">
            Experience a smarter, faster, and more efficient way to manage
            wealth.
          </h1>

          <button
            className="text-white font-medium py-2.5 md:py-3 px-6 md:px-8 rounded-md shadow-lg transition-all duration-300 text-sm md:text-base"
            style={{
              background:
                "linear-gradient(90deg, rgb(128,108,234), rgb(162,101,230))",
              boxShadow: "0 4px 15px rgba(128, 108, 234, 0.4)",
            }}
            onClick={()=>{navigate("/signup")}}
          >
            Request A Demo
          </button>

          {/* <div className="flex items-center mt-2 md:mt-4 cursor-pointer group">
            <a className="text-white flex items-center gap-1 md:gap-2 text-xs md:text-sm group relative w-fit"  onClick={()=>{navigate("/signup")}}>
              <span className="font-medium">Explore Solutions</span>
              <svg
                className="w-4 h-4 md:w-5 md:h-5 transition-transform duration-300 group-hover:translate-x-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12h16m0 0l-6-6m6 6l-6 6"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-white transition-all duration-300 group-hover:w-full"></span>
            </a>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default ValuefyInAction;
