import { 
  RecaptchaVerifier, 
  signInWithPhoneNumber,
  ApplicationVerifier,
  User,
  ConfirmationResult as FirebaseConfirmationResult
} from 'firebase/auth';
import { auth } from './config';
import { ConfirmationResult } from '../types/auth';

export interface PhoneAuthResponse {
  success: boolean;
  data?: {
    confirmationResult?: ConfirmationResult;
    user?: User;
    message?: string;
  };
  error?: {
    message: string;
    code?: string;
  };
}

export class PhoneAuthService {
  private recaptchaVerifier: ApplicationVerifier | null = null;
  private recaptchaContainer: string | null = null;

  // Initialize reCAPTCHA verifier
  private initRecaptcha(containerId: string) {
    // Clear any existing recaptcha
    if (this.recaptchaVerifier) {
      this.recaptchaVerifier = null;
    }

    // Use the container ID if provided, otherwise use the button ID
    const container = containerId || 'recaptcha-container';
    this.recaptchaContainer = container;
    
    const containerElement = document.getElementById(container);
    if (!containerElement) {
      console.warn(`reCAPTCHA container #${container} not found, reCAPTCHA may appear in unexpected location`);
    }

    try {
      this.recaptchaVerifier = new RecaptchaVerifier(auth, container, {
        size: 'invisible',
        callback: () => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log('reCAPTCHA verification successful');
        },
        'expired-callback': () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          console.warn('reCAPTCHA verification expired');
          this.recaptchaVerifier = null;
        },
        'error-callback': (error: Error) => {
          // Handle reCAPTCHA errors
          console.error('reCAPTCHA error:', error);
          this.recaptchaVerifier = null;
        }
      });
      
      return this.recaptchaVerifier;
    } catch (error) {
      console.error('Error initializing reCAPTCHA:', error);
      throw error;
    }
  }

  // Send OTP
  async sendOTP(phoneNumber: string, containerId: string): Promise<PhoneAuthResponse> {
    try {
      // Clear any existing reCAPTCHA first to avoid conflicts
      this.clearRecaptcha();
      
      // Initialize a new reCAPTCHA verifier
      const verifier = this.initRecaptcha(containerId);
      
      // Format phone number to ensure it has a + prefix (E.164 format)
      const formattedPhoneNumber = phoneNumber.startsWith('+') ? phoneNumber : `+${phoneNumber}`;
      
      console.log(`Sending OTP to: ${formattedPhoneNumber}`);
      
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        formattedPhoneNumber,
        verifier
      ) as FirebaseConfirmationResult;
      
      console.log('OTP sent successfully');
      
      return {
        success: true,
        data: {
          confirmationResult: {
            verificationId: confirmationResult.verificationId,
            confirm: confirmationResult.confirm.bind(confirmationResult)
          },
          message: 'OTP sent successfully'
        }
      };
    } catch (error: unknown) {
      // Reset recaptcha verifier on error
      this.clearRecaptcha();
      
      console.error('Error sending OTP:', error);
      
      // Improved error handling
      const errorObj = error as { code?: string; message?: string };
      const errorCode = errorObj?.code || '';
      const errorMessage = errorObj?.message || 'Failed to send OTP';
      
      // Provide more specific user-friendly messages based on the error code
      let userFriendlyMessage = errorMessage;
      
      if (errorCode === 'auth/invalid-app-credential') {
        userFriendlyMessage = 'Verification service temporarily unavailable. Please try again later.';
        // The reCAPTCHA token wasn't accepted, which could be due to configuration issues
        console.error('Firebase reCAPTCHA verification failed. Check Firebase project settings.');
        
        // We'll try to re-initialize the reCAPTCHA after a short delay
        setTimeout(() => {
          try {
            this.initRecaptcha(containerId);
          } catch (e) {
            console.error('Failed to re-initialize reCAPTCHA:', e);
          }
        }, 1000);
      } else if (errorCode === 'auth/quota-exceeded') {
        userFriendlyMessage = 'We\'ve reached our verification limit. Please try again later.';
      } else if (errorCode === 'auth/invalid-phone-number') {
        userFriendlyMessage = 'Please enter a valid phone number with country code.';
      } else if (errorCode === 'auth/captcha-check-failed') {
        userFriendlyMessage = 'reCAPTCHA verification failed. Please refresh the page and try again.';
      } else if (errorCode === 'auth/missing-app-credential') {
        userFriendlyMessage = 'Authentication configuration error. Please contact support.';
      }
      
      return {
        success: false,
        error: {
          message: userFriendlyMessage,
          code: errorCode
        }
      };
    }
  }

  // Clear reCAPTCHA
  clearRecaptcha() {
    try {
      this.recaptchaVerifier = null;
      
      // Clear the DOM element
      if (this.recaptchaContainer) {
        const container = document.getElementById(this.recaptchaContainer);
        if (container) {
          container.innerHTML = '';
        }
      }
      
      // Attempt to clean up any reCAPTCHA artifacts that might be in the DOM
      const grecaptchaElements = document.querySelectorAll('[class^="grecaptcha-badge"]');
      grecaptchaElements.forEach(element => {
        try {
          element.parentNode?.removeChild(element);
        } catch (e) {
          console.warn('Failed to remove reCAPTCHA element:', e);
        }
      });
      
      // Clear potential iframe elements added by reCAPTCHA
      const iframes = document.querySelectorAll('iframe[src*="recaptcha"]');
      iframes.forEach(iframe => {
        try {
          iframe.parentNode?.removeChild(iframe);
        } catch (e) {
          console.warn('Failed to remove reCAPTCHA iframe:', e);
        }
      });
    } catch (e) {
      console.warn('Error while clearing reCAPTCHA:', e);
    }
  }

  // Verify OTP
  async verifyOTP(confirmationResult: ConfirmationResult, otp: string): Promise<PhoneAuthResponse> {
    try {
      console.log('Verifying OTP...');
      const result = await confirmationResult.confirm(otp);
      console.log('OTP verified successfully');
      
      return {
        success: true,
        data: {
          user: result.user,
          message: 'OTP verified successfully'
        }
      };
    } catch (error: unknown) {
      console.error('Error verifying OTP:', error);
      
      const errorObj = error as { code?: string; message?: string };
      const errorCode = errorObj?.code || '';
      const errorMessage = errorObj?.message || 'Invalid OTP';
      
      // Provide user-friendly messages for verification errors
      let userFriendlyMessage = errorMessage;
      
      if (errorCode === 'auth/invalid-verification-code') {
        userFriendlyMessage = 'The verification code you entered is incorrect. Please try again.';
      } else if (errorCode === 'auth/code-expired') {
        userFriendlyMessage = 'The verification code has expired. Please request a new code.';
      }
      
      return {
        success: false,
        error: {
          message: userFriendlyMessage,
          code: errorCode
        }
      };
    }
  }

  // Sign out
  async signOut(): Promise<PhoneAuthResponse> {
    try {
      await auth.signOut();
      return {
        success: true,
        data: {
          message: 'Signed out successfully'
        }
      };
    } catch (error: unknown) {
      const errorMessage = error instanceof Error 
        ? error.message 
        : 'Failed to sign out';
      
      return {
        success: false,
        error: {
          message: errorMessage,
          code: error instanceof Error && 'code' in error 
            ? (error as { code: string }).code 
            : undefined
        }
      };
    }
  }

  // Get current user
  getCurrentUser(): User | null {
    return auth.currentUser;
  }
}

export const phoneAuth = new PhoneAuthService(); 