import React from 'react';
import Navbar from './Navbar';
import WMHero from './WMHero';
import FamilyOfficesReality from './FamilyOfficesReality';
import FamilyOfficeFeatures from './FamilyOfficeFeatures';
import FamilyOfficeList from './FamilyOfficeList';
import FamilyOfficeStats from './FamilyOfficeStats';
import BookDemoFO from './BookDemoFO';

const FamilyOfficesPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <FamilyOfficesReality/>
      <FamilyOfficeFeatures/>
      <div className="hidden md:block">
        <FamilyOfficeList />
      </div>
      <FamilyOfficeStats/>
      <BookDemoFO/>
    </div>
  );
};

export default FamilyOfficesPage;