import React from 'react';
import Navbar from './Navbar';

// You can create these components later
import WMHero from './WMHero';
import ValuefyFeatures from './ValuefyFeatures';
import ComplianceShowcase from './ComplianceShowcase';
import BookDemoCompliance from './BookDemoCompliance';
import ComplianceReality from './ComplianceReality';
// Footer removed to avoid duplication with RootLayout footer
// import ComplianceFooter from './ComplianceOfficerFooter';

const CompliancePage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <ComplianceReality/>
      <ValuefyFeatures/>
      <ComplianceShowcase/>
      <BookDemoCompliance/>
      {/* ComplianceFooter removed to avoid duplication */}
    </div>
  );
};

export default CompliancePage;