import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = () => {
  // GA measurement ID from environment with fallback
  const TRACKING_ID = import.meta.env.VITE_GA_MEASUREMENT_ID || "G-EJS0JJTKT1";
  
  // Always initialize with either environment variable or fallback value
  ReactGA.initialize(TRACKING_ID);
  
  if (import.meta.env.VITE_GA_MEASUREMENT_ID === undefined) {
    console.warn('Using fallback Google Analytics Measurement ID. This is only recommended for development.');
  } else {
    console.log('Google Analytics initialized successfully');
  }
};

// Track page views
export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path });
};

// Track events (clicks, form submissions, etc.)
export interface EventParams {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
}

export const trackEvent = ({ category, action, label, value, nonInteraction }: EventParams) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction
  });
};

// Common event tracking functions
export const trackCTAClick = (ctaName: string, location: string) => {
  trackEvent({
    category: 'CTA',
    action: 'Click',
    label: `${ctaName} - ${location}`
  });
};

export const trackSectionView = (sectionName: string) => {
  trackEvent({
    category: 'Section',
    action: 'View',
    label: sectionName,
    nonInteraction: true
  });
};

export const trackFormSubmit = (formName: string, success: boolean = true) => {
  trackEvent({
    category: 'Form',
    action: success ? 'Submit Success' : 'Submit Failure',
    label: formName
  });
};

export const trackDownload = (fileName: string) => {
  trackEvent({
    category: 'Download',
    action: 'Click',
    label: fileName
  });
};

export const trackExternalLinkClick = (url: string, linkText: string) => {
  trackEvent({
    category: 'External Link',
    action: 'Click',
    label: `${linkText} - ${url}`
  });
};

// Set user properties for authenticated users
export const setUserProperties = (user: {
  uid: string;
  email?: string;
  displayName?: string;
  role?: string;
}) => {
  ReactGA.set({
    userId: user.uid,
    userProperties: {
      email: user.email,
      displayName: user.displayName,
      role: user.role
    }
  });
};

// Clear user properties on logout
export const clearUserProperties = () => {
  ReactGA.set({
    userId: null,
    userProperties: null
  });
}; 