import wave from "../assets/wave.png";
import bank from "../assets/bank.png";
import light from "../assets/light.png";
import line from "../assets/Line.png";
import { MessageCircle } from "lucide-react";
import profile from "../assets/profile.png";
import { useNavigate } from "react-router-dom";
export const GlobalFirm = () => {
  const navigate=useNavigate();
  return (
    <div className="w-full mx-auto text-white pt-14 bg-gradient-to-b from-[#000000] via-[#000000] to-[#1e1c20]">
      <div className="w-[85%] mx-auto text-center pb-20">
        <div className="text-4xl font-bold mb-20">Global Firms Rely on Valuefy</div>
        <div className="text-2xl flex flex-wrap justify-center md:justify-between gap-10 md:gap-0">
          <div className="flex flex-col items-center justify-end gap-10">
            <img src={bank} alt="bank icon" className="w-15 md:w-auto" />
            <div className="text-xl text-center">Top Financial Institutions <br /> in India, UAE & Europe</div>
          </div>
          <img src={line} alt="line" className="hidden md:block" />
          <div className="flex flex-col items-center justify-end gap-5">
            <img src={wave} alt="wave icon" className="w-20 md:w-auto" />
            <div className="text-xl text-center">Wealth Managers Scaling <br /> with Integrated Technology</div>
          </div>
          <img src={line} alt="line" className="hidden md:block" />
          <div className="flex flex-col items-center justify-end gap-5">
            <img src={light} alt="light icon" className="w-20 md:w-auto" />
            <div className="text-xl text-center">Multi-Billion AUM Firms <br /> Eliminating Operational Drag</div>
          </div>
        </div>
        <button className="text-white bg-gradient-to-r from-[#6764EA] to-[#B17EEB] px-8 py-3 rounded-xl mt-24 text-[20px]">Join the Leaders in WealthTech</button>
        <div className="flex justify-between mt-[100px] flex-wrap gap-10">
            <div className="text-left lg:w-[50%] w-full">
              <div className="text-6xl font-bold mb-8 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">Bring Everything Together <br/> with Valuefy</div>
              <div className="text-xl">Mumbai | Singapore | Dubai | London | Europe | USA</div>
              <button className=" bg-white text-black px-20 py-2 rounded-xl mt-10 text-[20px]" onClick={()=>{navigate("/signup")}}>Book a Demo</button>
              <div className="flex items-center gap-2 mt-8 mb-5 text-center"><MessageCircle /> Talk to an Expert</div>
            </div>
            <div className="lg:w-[40%] w-full">
                <img src={profile} alt="profile" className="w-[100%]"/>
            </div>
        </div>
      </div>
    </div>
  );
};