import { RouteData, RouteType, RouteMetadata } from '../types/routes';
import { SOCIAL_LINKS } from '../constants/routes';

/**
 * Interface for application route object
 */
export interface AppRoute {
  path: string;
  metadata: RouteMetadata;
}

/**
 * Interface for grouped application routes
 */
export interface AppRouteGroups {
  MAIN_ROUTES: Record<string, AppRoute>;
  SOLUTION_ROUTES: Record<string, AppRoute>;
  LEGAL_ROUTES: Record<string, AppRoute>;
  SUPPORT_ROUTES: Record<string, AppRoute>;
  SOCIAL_LINKS: typeof SOCIAL_LINKS;
}

/**
 * Convert database routes to application route format
 */
function convertToAppRoute(route: RouteData): AppRoute {
  // Extract metadata fields from database route
  const { keywords, ogImage, ogType } = route.metadata || {};
  
  return {
    path: route.path,
    metadata: {
      title: route.title,
      description: route.description,
      ...(keywords ? { keywords } : {}),
      ...(ogImage ? { ogImage } : {}),
      ...(ogType ? { ogType } : {})
    }
  };
}

/**
 * Group routes by type for application use
 */
export function groupRoutesForApp(routes: RouteData[]): AppRouteGroups {
  const mainRoutes: Record<string, AppRoute> = {};
  const solutionRoutes: Record<string, AppRoute> = {};
  const legalRoutes: Record<string, AppRoute> = {};
  const supportRoutes: Record<string, AppRoute> = {};
  const socialLinks: Record<string, string> = {...SOCIAL_LINKS};

  // Process routes by type
  routes.forEach(route => {
    const appRoute = convertToAppRoute(route);
    
    switch (route.route_type) {
      case RouteType.MAIN:
        mainRoutes[route.route_key] = appRoute;
        break;
      case RouteType.SOLUTION:
        solutionRoutes[route.route_key] = appRoute;
        break;
      case RouteType.LEGAL:
        legalRoutes[route.route_key] = appRoute;
        break;
      case RouteType.SUPPORT:
        supportRoutes[route.route_key] = appRoute;
        break;
      case RouteType.SOCIAL:
        // For social links, we just store the URL as a string
        socialLinks[route.route_key] = route.path;
        break;
    }
  });

  return {
    MAIN_ROUTES: mainRoutes,
    SOLUTION_ROUTES: solutionRoutes,
    LEGAL_ROUTES: legalRoutes,
    SUPPORT_ROUTES: supportRoutes,
    SOCIAL_LINKS: socialLinks as typeof SOCIAL_LINKS
  };
}

/**
 * Create flat routes object for backward compatibility
 */
export function createFlatRoutes(groups: AppRouteGroups): Record<string, any> {
  // Create flat ROUTES object similar to the static one
  return {
    ...Object.entries(groups.MAIN_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
    SOLUTIONS: Object.entries(groups.SOLUTION_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
    LEGAL: Object.entries(groups.LEGAL_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
    SUPPORT: Object.entries(groups.SUPPORT_ROUTES).reduce((acc, [key, value]) => ({ ...acc, [key]: value.path }), {}),
    SOCIAL: groups.SOCIAL_LINKS
  };
}

/**
 * Get all route metadata from grouped routes
 */
export function getAllRouteMetadataFromGroups(groups: AppRouteGroups): Record<string, RouteMetadata> {
  const metadataByPath: Record<string, RouteMetadata> = {};
  
  // Process each route category
  [
    Object.values(groups.MAIN_ROUTES),
    Object.values(groups.SOLUTION_ROUTES),
    Object.values(groups.LEGAL_ROUTES),
    Object.values(groups.SUPPORT_ROUTES)
  ].forEach(routes => {
    routes.forEach(route => {
      metadataByPath[route.path] = route.metadata;
    });
  });
  
  return metadataByPath;
} 