import { MAIN_ROUTES, SOLUTION_ROUTES } from "./routes";

interface NavItem {
  label: string;
  path: string;
}

interface SolutionItem {
  title: string;
  path: string;
  description: string;
}

// Main navigation items
export const NAVBAR_ITEMS = {
  PLATFORM: {
    label: "Platform & Features",
    path: MAIN_ROUTES.PLATFORM.path
  },
  BLOG: {
    label: "Blog",
    path: MAIN_ROUTES.BLOGS.path
  },
  ABOUT: {
    label: "About",
    path: MAIN_ROUTES.ABOUT.path
  },
  CONTACT: {
    label: "Contact",
    path: MAIN_ROUTES.CONTACT.path
  },
  PRICING: {
    label: "Pricing",
    path: MAIN_ROUTES.PRICING.path
  }
};

// Auth navigation items
export const AUTH_ITEMS = {
  SIGN_IN: {
    label: "Sign In",
    path: MAIN_ROUTES.SIGNIN.path
  },
  SIGN_UP: {
    label: "Sign Up",
    path: MAIN_ROUTES.SIGNUP.path
  },
  SIGN_OUT: {
    label: "Sign Out",
    path: ""
  }
};

// Solutions dropdown items
export const SOLUTION_ITEMS: Record<string, SolutionItem> = {
  WEALTH_MANAGER: {
    title: "Wealth Managers",
    path: SOLUTION_ROUTES.WEALTH_MANAGER.path,
    description: "One system that enhances decision-making and streamlines portfolio management."
  },
  RELATIONSHIP_MANAGER: {
    title: "RM & Advisors",
    path: SOLUTION_ROUTES.RELATIONSHIP_MANAGER.path,
    description: "One system that strengthens client engagement and boosts relationship management."
  },
  COMPLIANCE_OFFICER: {
    title: "Compliance Officers",
    path: SOLUTION_ROUTES.COMPLIANCE_OFFICER.path,
    description: "One system to automate compliance checks and reinforce regulatory safeguards."
  },
  OPERATION_TEAM: {
    title: "Operations Teams",
    path: SOLUTION_ROUTES.OPERATION_TEAM.path,
    description: "One system to streamline workflows and enhance operational efficiency."
  },
  CIO: {
    title: "CIOs",
    path: SOLUTION_ROUTES.CIO.path,
    description: "One system to unify investment strategy and support data-driven decisions."
  },
  FAMILY_OFFICE: {
    title: "Family Offices",
    path: SOLUTION_ROUTES.FAMILY_OFFICE.path,
    description: "One system to simplify multigenerational wealth management and optimize family governance."
  }
};

// Contact card content
export const CONTACT_CARD = {
  title: "Need a tailored solution?",
  description: "Contact our team to discover how Valuefy can meet your specific requirements.",
  email: "contact@valuefy.com"
};

// Mobile menu section labels
export const MOBILE_MENU = {
  SOLUTIONS_LABEL: "Solutions for",
  PLATFORM_LABEL: "Platform and Features"
};

// Helper functions
export const isPathActive = (currentPath: string, path: string): boolean => {
  return currentPath === path;
};

export const isSolutionActive = (currentPath: string): boolean => {
  return currentPath.startsWith("/solutions/");
};

export const isPlatformActive = (currentPath: string): boolean => {
  return currentPath === MAIN_ROUTES.PLATFORM.path;
}; 