import trustIcon from '../assets/about-us/trust-icon.png';
import automationIcon from '../assets/about-us/seamless.png';
import experienceIcon from '../assets/about-us/experience.png';
import scaleIcon from '../assets/about-us/scale.png';

const TrustFeatures = () => {
  const features = [
    {
      icon: trustIcon,
      alt: "Trust Icon",
      text: "Trusted WealthTech for top institutions."
    },
    {
      icon: automationIcon,
      alt: "Automation Icon",
      text: "Automation, seamless, scalable solutions."
    },
    {
      icon: experienceIcon,
      alt: "Experience Icon",
      text: "15+ Years of WealthTech Innovation."
    },
    {
      icon: scaleIcon,
      alt: "Scale Icon",
      text: "Designed for Scale. Built for Growth."
    }
  ];

  return (
    <div className="w-full bg-black">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 divide-y sm:divide-y-0 sm:divide-x divide-gray-800">
        {features.map((feature, index) => (
          <div key={index} className="flex flex-col items-center justify-center text-center py-10 px-6 text-white">
            <div className="mb-8">
              <img 
                src={feature.icon} 
                alt={feature.alt} 
                className="h-32 w-auto" 
              />
            </div>
            <p className="text-2xl font-inter">{feature.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustFeatures;