import { useRef, useState, useEffect } from "react";
import bgimg from "../assets/wealth-manager/todays-reality-bg.png";
 
const CIOReality = () => {
  // Add styles directly for consistent appearance
  const styles = {
    activeCard: {
      background: "rgba(0, 0, 1, 0.9)",
      boxShadow:
        "0 8px 32px rgba(0, 0, 0, 0.3), 0 0 10px 2px rgba(138, 43, 226, 0.7)", // Added purple glow
      borderRadius: "12px",
      border: "1px solid white", // Subtle purple border
    },
    inactiveCard: {
      background: "rgba(0, 0, 0, 0.6)",
      boxShadow: "0 4px 16px rgba(0, 0, 0, 0.2)",
      borderRadius: "8px",
    },
    scrollContainer: {
      scrollbarWidth: "none", // Firefox
      msOverflowStyle: "none", // IE and Edge
      "&::-webkit-scrollbar": {
        // Chrome, Safari, and Opera
        display: "none",
      },
    },
  };
 
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLElement>(null);
  const [activeChallenge, setActiveChallenge] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [autoScrollActive, setAutoScrollActive] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [lastScrollTime, setLastScrollTime] = useState(0);
 
  const challenges = [
    {
      text: "",
    },
    {
      text: "Portfolio selection and rebalancing are time-consuming.",
    },
    {
      text: "Data is fragmented across multiple custodians.",
    },
    {
      text: "Advisory models lack real-time, AI-driven insights.",
    },
    {
      text: "Difficulty tracking portfolio performance in real-time.",
    },
    {
      text: "Limited visibility into client holistic financial picture.",
    },
    {
      text: "",
    },
  ];
 
  // Check if the section is visible in viewport
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.3, // Section is considered visible when 30% is in viewport
      }
    );
 
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
 
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
 
  // Control auto-scrolling based on visibility
  useEffect(() => {
    if (isVisible) {
      setAutoScrollActive(true);
    } else {
      setAutoScrollActive(false);
    }
  }, [isVisible]);
 
  // Auto-scroll effect
  useEffect(() => {
    if (!autoScrollActive || !scrollContainerRef.current) return;
 
    const interval = setInterval(() => {
      const container = scrollContainerRef.current;
      if (!autoScrollActive || !container) return;
      const cards = Array.from(container.children);
 
      // Don't scroll to the empty cards at the beginning and end
      const minIndex = 1;
      const maxIndex = cards.length - 2;
 
      let nextIndex;
 
      if (scrollDirection === "down") {
        nextIndex = activeChallenge + 1;
        if (nextIndex > maxIndex) {
          nextIndex = maxIndex;
          setScrollDirection("up");
        }
      } else {
        nextIndex = activeChallenge - 1;
        if (nextIndex < minIndex) {
          nextIndex = minIndex;
          setScrollDirection("down");
        }
      }
 
      if (nextIndex !== activeChallenge) {
        setActiveChallenge(nextIndex);
        // Instead of scrollIntoView, use scrollTop to contain scrolling within the container
        const container = scrollContainerRef.current;
        if (container && cards.length > nextIndex) {
          const targetCard = cards[nextIndex];
          const containerRect = container.getBoundingClientRect();
          const targetRect = targetCard.getBoundingClientRect();
          const relativePosition = targetRect.top - containerRect.top;
 
          container.scrollBy({
            top:
              relativePosition -
              containerRect.height / 2 +
              targetRect.height / 2,
            behavior: "smooth",
          });
        }
      }
    }, 2000); // Change card every 2 seconds
 
    return () => clearInterval(interval);
  }, [autoScrollActive, activeChallenge, scrollDirection]);
 
  // Handle manual scroll
  const handleScroll = () => {
    // Prevent auto-scroll from competing with manual scroll
    const now = Date.now();
    if (now - lastScrollTime < 2000) {
      setAutoScrollActive(false);
 
      // Restart auto-scroll after 5 seconds of inactivity
      setTimeout(() => {
        setAutoScrollActive(isVisible);
      }, 5000);
    }
    setLastScrollTime(now);
 
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const containerTop = container.getBoundingClientRect().top;
      const containerHeight = container.offsetHeight;
      const containerCenter = containerTop + containerHeight / 2;
 
      // Find which challenge is closest to the center of the viewport
      let closestIndex = 0;
      let minDistance = Infinity;
 
      Array.from(container.children).forEach((child, index) => {
        const childRect = child.getBoundingClientRect();
        const childCenter = (childRect.top + childRect.bottom) / 2;
        const distance = Math.abs(childCenter - containerCenter);
 
        if (distance < minDistance) {
          minDistance = distance;
          closestIndex = index;
        }
      });
 
      if (closestIndex !== activeChallenge) {
        setActiveChallenge(closestIndex);
      }
    }
  };
 
  return (
    <section
      ref={sectionRef}
      className="bg-black text-white py-16 md:py-48 px-6 relative overflow-hidden"
      style={{
        backgroundImage: `url(${bgimg})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row justify-between items-start gap-10">
        {/* Left side content */}
        <div className="w-full md:w-1/2 sticky top-24 font-inter line-height: 60%">
          <div className="max-w-lg">
            <p className="text-2xl mb-8">
            Investment advisors are the strategic minds
              <br /> behind wealth creation
            </p>
            <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-20">
              But Today's Reality ?
            </h2>
          </div>
        </div>
 
        {/* Right side challenges - vertical scrollable */}
        <div className="w-full md:w-1/2 relative">
          <div
            ref={scrollContainerRef}
            onScroll={handleScroll}
            className="flex flex-col space-y-8 max-h-96 overflow-y-auto pr-4 scrollbar-hide"
            style={{
              scrollBehavior: "smooth",
              overflow: "hidden auto",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            {challenges.map((challenge, index) => (
              <div
                key={index}
                style={
                  index === activeChallenge
                    ? styles.activeCard
                    : styles.inactiveCard
                }
                className={`p-6 my-2 transition-all duration-300 ${
                  index === activeChallenge
                    ? "scale-100"
                    : "scale-95 opacity-70"
                }`}
              >
                <div className="flex items-start gap-4">
                  <p className="text-3xl font-inter ">{challenge.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
 
export default CIOReality;