import demo from "../assets/product-demo/prod-demo.png";
import bgripple from '../assets/family-office/background-ripple.png';

const stats = [
  {
    value: "100+",
    description: "Relationship Managers trust Valuefy for client insights & automation"
  },
  {
    value: "2X",
    description: "Client REngagement with AI-powered analytics"
  },
  {
    value: "3X",
    description: "Faster Proposal & Transaction Processing"
  }
];

const RelationshipShowcase = () => {
  return (
    <section className="bg-black text-white py-16 px-6 relative">
      {/* Background design elements */}

      <div className="max-w-5xl mx-auto">
        {/* Heading */}
        <h2 className="text-3xl font-bold text-center mb-10">
          See Valuefy in Action
        </h2>

        {/* Dashboard Image Container */}
        <div className="relative rounded-lg overflow-hidden mb-12 shadow-xl">
          {/* Dashboard Image */}
          <img
            src={demo}
            alt="Valuefy Platform Dashboard"
            className="w-full h-auto object-cover rounded-lg"
            style={{
              filter: "blur(1.5px)",
              opacity: 0.7,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Add a subtle shadow
            }}
          />

          {/* Blue Button Overlay */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 translate-y-0">
            <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-3 px-6 rounded-full shadow-lg transition-colors">
              Experience the product
            </button>
          </div>

          {/* Product UI Highlights - User Icons */}
        </div>

        {/* Stats Section */}
        <div className="w-full bg-black text-white py-32 bg-cover bg-center relative"  style={{ backgroundImage: `url(${bgripple})` }}>
      {/* Optional: Add a dark overlay to the background image for better readability */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      <div className="max-w-6xl mx-auto px-4 relative z-10">
        <h2 className="text-5xl font-bold text-center mb-16 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">The Future Of Wealth Advisory Starts Here.</h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {stats.map((stat, index) => (
            <div key={index} className="text-center px-4 py-6 bg-black/70 rounded-lg shadow-lg relative group">
              {/* Add dividers between columns except for the last one */}
              {index < stats.length - 1 && (
                <div className="hidden md:block absolute right-0 top-1/4 bottom-1/4 w-px bg-gray-700"></div>
              )}
              
              <h3 className="text-6xl font-bold mb-8 transform transition-transform duration-300 group-hover:scale-110 bg-gradient-to-r from-white via-gray-400 to-white bg-clip-text text-transparent">
                {stat.value}
              </h3>
              <p className="text-gray-300">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>

        {/* CTA Buttons */}
        {/* <div className="flex flex-col justify-center items-center gap-4 mb-8">
          <button className="bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-3 px-6 rounded-md shadow-md transition-colors">
            Request A Demo
          </button>
          
          <div className="flex items-center">
            <button className="text-white hover:text-indigo-300 font-medium transition-colors">
              Explore Solutions
            </button>
            <div className="ml-2 w-1 h-1 rounded-full bg-white"></div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default RelationshipShowcase;
