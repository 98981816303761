"use client";

import type { CSSProperties } from "react";
import React, { useEffect, useId, useRef, useState } from "react";

interface AuroraTextProps {
  children: React.ReactNode;
  className?: string;
  colors?: string[];
  speed?: number; // 1 is default speed, 2 is twice as fast, 0.5 is half speed
  minFontSize?: number; // Minimum font size in px for mobile
  maxFontSize?: number; // Maximum font size in px for larger screens
}

export function AuroraText({
  children,
  className = "",
  colors = ["#FF0080", "#7928CA", "#0070F3", "#38bdf8", "#a855f7", "#2dd4bf"],
  speed = 1,
  minFontSize = 16, // Default minimum font size
  maxFontSize = 64, // Default maximum font size
}: AuroraTextProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const textRef = useRef<SVGTextElement>(null);
  const containerRef = useRef<HTMLSpanElement>(null);
  const [fontSize, setFontSize] = useState(0);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isReady, setIsReady] = useState(false);
  const [textStyle, setTextStyle] = useState<Partial<CSSStyleDeclaration>>({});
  const maskId = useId();
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  // Extract all relevant text styles from parent
  useEffect(() => {
    if (containerRef.current) {
      const computedStyle = window.getComputedStyle(containerRef.current);

      // Extract text-related styles
      const relevantStyles = {
        fontSize: computedStyle.fontSize,
        fontFamily: computedStyle.fontFamily,
        fontWeight: computedStyle.fontWeight,
        fontStyle: computedStyle.fontStyle,
        letterSpacing: computedStyle.letterSpacing,
        lineHeight: computedStyle.lineHeight,
        textTransform: computedStyle.textTransform,
        fontVariant: computedStyle.fontVariant,
        fontStretch: computedStyle.fontStretch,
        fontFeatureSettings: computedStyle.fontFeatureSettings,
      };

      setTextStyle(relevantStyles);
    }
  }, [className]);

  // Handle responsive font sizing
  useEffect(() => {
    const updateFontSize = () => {
      if (containerRef.current) {
        const computedStyle = window.getComputedStyle(containerRef.current);
        let computedFontSize = parseFloat(computedStyle.fontSize);

        // Apply responsive constraints
        computedFontSize = Math.max(
          minFontSize,
          Math.min(maxFontSize, computedFontSize)
        );

        setFontSize(computedFontSize);
      }
    };

    updateFontSize();

    // Use ResizeObserver for more accurate responsiveness
    if (typeof ResizeObserver !== "undefined" && containerRef.current) {
      resizeObserverRef.current = new ResizeObserver(() => {
        updateFontSize();
        // Also update dimensions when container size changes
        if (textRef.current) {
          const bbox = textRef.current.getBBox();
          setDimensions({
            width: bbox.width,
            height: bbox.height,
          });
        }
      });

      resizeObserverRef.current.observe(containerRef.current);
    }

    // Fallback to window resize for older browsers
    window.addEventListener("resize", updateFontSize);

    return () => {
      window.removeEventListener("resize", updateFontSize);
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
    };
  }, [className, minFontSize, maxFontSize]);

  // Update dimensions and set ready state
  useEffect(() => {
    const updateDimensions = () => {
      if (textRef.current) {
        const bbox = textRef.current.getBBox();
        // Ensure dimensions are always positive values
        const width = Math.max(1, bbox.width);
        const height = Math.max(1, bbox.height);

        setDimensions({ width, height });
        setIsReady(true);
      }
    };

    // Short delay to ensure text has rendered properly
    const timeoutId = setTimeout(updateDimensions, 50);

    // Media query for orientation changes
    const mediaQuery = window.matchMedia("(orientation: portrait)");
    const handleOrientationChange = () => {
      // Reset and recalculate on orientation change
      setIsReady(false);
      setTimeout(updateDimensions, 100);
    };

    mediaQuery.addEventListener("change", handleOrientationChange);
    window.addEventListener("resize", updateDimensions);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("resize", updateDimensions);
      mediaQuery.removeEventListener("change", handleOrientationChange);
    };
  }, [children, fontSize, textStyle]);

  // Canvas animation effect
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !isReady || dimensions.width <= 0 || dimensions.height <= 0)
      return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Set canvas size with device pixel ratio for sharp rendering
    const dpr = window.devicePixelRatio || 1;
    canvas.width = dimensions.width * dpr;
    canvas.height = dimensions.height * dpr;

    // Scale the context to account for the device pixel ratio
    ctx.scale(dpr, dpr);

    // Set CSS dimensions
    canvas.style.width = `${dimensions.width}px`;
    canvas.style.height = `${dimensions.height}px`;

    let time = 0;
    const baseSpeed = 0.008; // Original speed as base unit
    let animationFrameId: number;

    function animate() {
      if (!ctx || !canvas) return;
      ctx.clearRect(0, 0, dimensions.width, dimensions.height);

      time += baseSpeed * speed;

      // Adjust gradient size based on dimensions for better mobile appearance
      const gradientSize = Math.max(dimensions.width, dimensions.height) * 0.4;

      colors.forEach((color, i) => {
        const x =
          dimensions.width *
          (0.5 +
            Math.cos(time * 0.8 + i * 1.3) * 0.4 +
            Math.sin(time * 0.5 + i * 0.7) * 0.2);
        const y =
          dimensions.height *
          (0.5 +
            Math.sin(time * 0.7 + i * 1.5) * 0.4 +
            Math.cos(time * 0.6 + i * 0.8) * 0.2);

        const gradient = ctx.createRadialGradient(x, y, 0, x, y, gradientSize);

        gradient.addColorStop(0, `${color}99`);
        gradient.addColorStop(0.5, `${color}33`);
        gradient.addColorStop(1, `${color}00`);

        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, dimensions.width, dimensions.height);
      });

      animationFrameId = requestAnimationFrame(animate);
    }

    animate();

    // Cleanup animation frame on unmount
    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [dimensions, colors, speed, isReady]);

  return (
    <span
      ref={containerRef}
      className={`relative inline-block ${className}`}
      style={{
        width: isReady ? dimensions.width || "auto" : "auto",
        height: isReady ? dimensions.height || "auto" : "auto",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Hidden text for SEO and accessibility */}
      <span className="sr-only">{children}</span>

      {/* Visual placeholder while canvas loads */}
      <span
        style={{
          opacity: isReady ? 0 : 1,
          transition: "opacity 0.3s ease-in",
          position: isReady ? "absolute" : "relative",
          display: "inline-block",
          whiteSpace: "nowrap",
        }}
        aria-hidden="true"
      >
        {children}
      </span>

      <div
        className="absolute inset-0"
        style={{
          opacity: isReady ? 1 : 0,
          transition: "opacity 0.3s ease-in",
          width: "100%",
          height: "100%",
          overflow: "visible",
        }}
        aria-hidden="true"
      >
        <svg
          width={dimensions.width}
          height={dimensions.height}
          className="absolute inset-0"
          style={{ overflow: "visible" }}
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <clipPath id={maskId}>
              <text
                ref={textRef}
                x="50%"
                y="50%"
                dominantBaseline="middle"
                textAnchor="middle"
                style={textStyle as CSSProperties}
              >
                {children}
              </text>
            </clipPath>
          </defs>
        </svg>

        <canvas
          ref={canvasRef}
          style={{
            clipPath: `url(#${maskId})`,
            WebkitClipPath: `url(#${maskId})`,
            width: "100%",
            height: "100%",
          }}
          className="h-full w-full"
        />
      </div>
    </span>
  );
}