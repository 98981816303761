import React from "react";
import CBI from "../assets/company/cbi.png";
import Equirus from "../assets/company/equirus.png";
import Equirus_Logo from "../assets/company/equirus-logo.png";
import IDFC from "../assets/company/idfc.png";
import one3 from '../assets/company/one360.png';
import barclays from '../assets/company/barclays.png';
import federal from '../assets/company/federal.png';
import waterfield from '../assets/company/waterfield.png';
import icici from '../assets/company/icici.png';
import hdfc from '../assets/company/hdfc.png';
import sherkhan from '../assets/company/sharekhan.png';
import shriram from '../assets/company/shriram.png';

const CompaniesMarquee: React.FC = () => {
  // Array of company logo data for easy maintenance and duplication
  const companyLogos = [
    { src: CBI, alt: "Central Bank of India" },
    { src: one3, alt: "One 360" },
    { src: Equirus_Logo, alt: "Equirus Logo" },
    { src: Equirus, alt: "Equirus" },
    { src: IDFC, alt: "IDFC" },
    { src: barclays, alt: "Barclays" },
    { src: federal, alt: "Federal Bank" },
    { src: waterfield, alt: "Waterfield" },
    { src: icici, alt: "ICICI" },
    { src: hdfc, alt: "HDFC" },
    { src: sherkhan, alt: "Sherkhan" },
    { src: shriram, alt: "Shriram" },
  ];

  // Duplicate the array to ensure continuous scrolling
  const duplicatedLogos = [...companyLogos, ...companyLogos, ...companyLogos];

  return (
    <div className="bg-black text-white py-4 md:py-6 overflow-hidden">
      <div className="px-4 md:px-6">
        <h1 className="text-white text-xl sm:text-2xl md:text-3xl font-bold text-center mb-4 md:mb-9">
          Our Esteemed Clients
        </h1>
      </div>

      {/* Marquee container with responsive adjustments */}
      <div className="flex whitespace-nowrap animate-marquee">
        <div className="flex items-center space-x-6 sm:space-x-8 md:space-x-12">
          {duplicatedLogos.map((logo, index) => (
            <img
              key={`${logo.alt}-${index}`}
              src={logo.src}
              alt={logo.alt}
              className="h-8 sm:h-10 md:h-12 mx-2 sm:mx-3 md:mx-4 object-contain"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CompaniesMarquee;