/**
 * Scroll helpers for handling scroll behavior when navigating between routes
 */

/**
 * Scrolls to the top of the page with smooth animation
 */
export const scrollToTop = (smooth = true) => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: smooth ? 'smooth' : 'auto'
  });
};

/**
 * Scrolls to a specific element by ID with smooth animation
 */
export const scrollToElement = (elementId: string, smooth = true) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
      behavior: smooth ? 'smooth' : 'auto',
      block: 'start'
    });
  }
};

/**
 * Use this when navigating between routes to ensure the page starts at the top
 */
export const resetScrollPosition = () => {
  // For immediate reset without animation
  window.scrollTo(0, 0);
}; 