import React, { useState, useEffect } from "react";
import testimonial1 from "../assets/testimonial/testimonial1.png";
import testimonial2 from "../assets/testimonial/testimonial2.png";
import testimonial3 from "../assets/testimonial/testimonial3.png";
import testimonial4 from "../assets/testimonial/testimonial4.png";

interface TestimonialImage {
  id: number;
  src: string;
  alt: string;
}

const TestimonialCarousel: React.FC = () => {
  // Replace these paths with your actual local image paths
  const testimonialImages: TestimonialImage[] = [
    {
      id: 1,
      src: testimonial1,
      alt: "ZICO testimonial",
    },
    {
      id: 2,
      src: testimonial2,
      alt: "Carol Seah testimonial",
    },
    {
      id: 3,
      src: testimonial3,
      alt: "Carol Seah testimonial",
    },
    {
      id: 4,
      src: testimonial4,
      alt: "Carol Seah testimonial",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);

  // For mobile swipe functionality
  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStart(e.targetTouches[0].clientX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }

    setTouchEnd(null);
    setTouchStart(null);
  };

  // Auto rotation for the carousel
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(interval);
  }, [activeIndex]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? testimonialImages.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === testimonialImages.length - 1 ? 0 : prevIndex + 1
    );
  };


  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center justify-center p-4 sm:p-6 md:p-8">
      <div className="max-w-6xl w-full">
        {/* Responsive heading with improved text sizing */}
        <h1 className="text-4xl md:text-6xl font-bold mb-16" style={{ lineHeight: '1.5' }}>
          What the {" "}
          <span className="bg-gradient-to-r from-purple-600 via-purple-700 to-purple-900 bg-clip-text text-transparent">
            Biggest Wealth Managers
          </span>
          <br />
          say about Valuefy
        </h1>
       <div className="relative w-full">
          {/* Image Carousel with touch support */}
          <div
            className="overflow-hidden px-2 sm:px-4 md:px-6"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            <div
              className="flex transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${activeIndex * 100}%)` }}
            >
              {testimonialImages.map((image) => (
                <div
                  key={image.id}
                  className="w-full flex-shrink-0 px-2 sm:px-4"
                >
                  <div className="relative aspect-[16/9] sm:aspect-[16/10] md:aspect-[16/9] overflow-hidden rounded-lg">
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="w-full h-full object-cover"
                      onError={(e) => {
                        const target = e.target as HTMLImageElement;
                        target.src = "/api/placeholder/800/400";
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Navigation dots - more visible on mobile */}
          <div className="flex justify-center mt-6 sm:mt-8 md:mt-12 space-x-3">
            {testimonialImages.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full transition-all duration-300 ${
                  activeIndex === index ? "bg-white scale-125" : "bg-gray-600"
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>

          {/* Previous/Next buttons - Better positioned for mobile */}
          <div className="flex justify-between w-full absolute top-1/2 left-0 transform -translate-y-1/2 px-2 sm:px-6 md:px-8">
            <button
              onClick={handlePrev}
              className="w-8 h-8 sm:w-10 sm:h-10 bg-gray-800 bg-opacity-50 rounded-full flex items-center justify-center border border-gray-700 hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-white"
              aria-label="Previous testimonial"
            >
              <span className="text-lg sm:text-xl">&lt;</span>
            </button>
            <button
              onClick={handleNext}
              className="w-8 h-8 sm:w-10 sm:h-10 bg-gray-800 bg-opacity-50 rounded-full flex items-center justify-center border border-gray-700 hover:bg-gray-700 transition-all focus:outline-none focus:ring-2 focus:ring-white"
              aria-label="Next testimonial"
            >
              <span className="text-lg sm:text-xl">&gt;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
