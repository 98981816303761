import fm1 from '../assets/family-office/fm1.png';
import fm2 from '../assets/family-office/fm2.png';
import fm3 from '../assets/family-office/fm3.png';
import fm4 from '../assets/family-office/fm4.png';
import fm5 from '../assets/family-office/fm5.png';
import fm6 from '../assets/family-office/fm6.png';
import fm7 from '../assets/family-office/fm7.png';

const FamilyOfficeFeatures = () => {
  // Images with pre-written text
  const featureImages = [
    {
      imageSrc: fm1,
      alt: "Unified Wealth View",
      mobileImageSrc: fm1
    },
    {
      imageSrc: fm2,
      alt: "AI Powered Analytics",
      mobileImageSrc: fm2
    },
    {
      imageSrc: fm3,
      alt: "Automated Transactions",
      mobileImageSrc: fm3
    },
    {
      imageSrc: fm4,
      alt: "Compliance & Risk Monitoring",
      mobileImageSrc: fm6
    },
    {
      imageSrc: fm5,
      alt: "Uncompromising Security",
      mobileImageSrc: fm7
    },
  ];

  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-2xl sm:text-4xl md:text-5xl text-purple-400 font-inter mb-2" style={{
            background: 'linear-gradient(90deg, rgb(128, 108, 234), rgb(162, 101, 230))',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
          }}>The Future of Family Office</h2>
          <h1 className="text-4xl md:text-6xl font-bold">Wealth Management with Valuefy</h1>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
          {featureImages.slice(0, 3).map((feature, index) => (
            <div 
              key={index} 
              className="group relative overflow-hidden rounded-lg transition-all duration-300 hover:scale-105"
            >
              <img 
                src={feature.imageSrc}
                alt={feature.alt}
                className="hidden md:block w-full h-full rounded-lg transition-all duration-500 group-hover:brightness-75"
              />
              <img 
                src={feature.mobileImageSrc}
                alt={feature.alt}
                className="md:hidden w-full h-full rounded-lg transition-all duration-500 group-hover:brightness-75"
              />
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-8 p-4">
          {featureImages.slice(3).map((feature, index) => (
            <div 
              key={index} 
              className="group relative overflow-hidden rounded-lg transition-all duration-300 hover:scale-105"
            >
              <img 
                src={feature.imageSrc}
                alt={feature.alt}
                className="hidden md:block w-full h-full rounded-lg transition-all duration-500 group-hover:brightness-75"
              />
              <img 
                src={feature.mobileImageSrc}
                alt={feature.alt}
                className="md:hidden w-full h-full rounded-lg transition-all duration-500 group-hover:brightness-75"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FamilyOfficeFeatures;