import React, { useState, useEffect, useRef } from "react";
import Trade from "../assets/icons/trade.png";
import AI from "../assets/icons/ai.png";
import Eng from "../assets/icons/engagement.png";
import Adv from "../assets/icons/advisor.png";
import Data from "../assets/icons/data.png";
import ValuefyLogo from "../assets/company/white-logo.png";
 
interface FeatureCardProps {
  icon: string;
  title: string;
  description: string;
  link: string;
  angle: number;
  orbit: number;
  isRotating: boolean;
  setIsRotating: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
  index: number;
}
 
const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  link,
  angle,
  orbit,
  setIsRotating,
  isMobile,
}) => {
  const [isHovered, setIsHovered] = useState(false);
 
  // Calculate position based on angle and orbit for desktop
  const x = isMobile ? 0 : Math.cos(angle) * orbit;
  const y = isMobile ? 0 : Math.sin(angle) * orbit;
 
  const handleMouseEnter = () => {
    setIsHovered(true);
    setIsRotating(false);
  };
 
  const handleMouseLeave = () => {
    setIsHovered(false);
    setIsRotating(true);
  };
 
  if (isMobile) {
    return (
      <div className="mb-4 w-full">
        <div className="bg-white rounded-lg shadow-lg p-4">
          <div className="flex flex-col">
            <div className="flex items-center mb-2">
              <img src={icon} alt={title} className="w-8 h-8 mr-3" />
              <h3 className="text-lg font-bold">{title}</h3>
            </div>
            <p className="text-sm text-gray-600 mb-2">{description}</p>
            <a
              href={link}
              className="text-blue-600 text-sm font-medium flex items-center"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    );
  }
 
  return (
    <div
      className={`absolute transition-all duration-400 ease-in-out`}
      style={{
        transform: `translate(${x}px, ${y}px)`,
        transformOrigin: "center",
        zIndex: isHovered ? 10 : 1,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isHovered ? (
        <div className="bg-white rounded-lg shadow-lg p-4 w-80 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-400 ease-in-out">
          <div className="flex flex-col">
            <img src={icon} alt={title} className="w-10 h-10 mb-2" />
            <h3 className="text-lg font-bold mb-1">{title}</h3>
            <p className="text-sm text-gray-600 mb-2">{description}</p>
            <a
              href={link}
              className="text-blue-600 text-sm font-medium flex items-center"
            >
              Learn More
              <svg
                className="w-4 h-4 ml-1"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      ) : (
        <div className="bg-white p-3 rounded-lg shadow-md transform -translate-x-1/2 -translate-y-1/2 cursor-pointer transition-all duration-400 hover:scale-110">
          <img src={icon} alt={title} className="w-8 h-8" />
        </div>
      )}
    </div>
  );
};
 
const RevolvingIcons: React.FC = () => {
  const [rotation, setRotation] = useState(0);
  const [isRotating, setIsRotating] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const animationRef = useRef<number>();
 
  const features = [
    {
      icon: Data,
      title: "Data Without Chaos",
      description: "Aggregated, Standardized, and Always Ready",
      link: "/signup",
      orbit: 220,
    },
    {
      icon: AI,
      title: "AI-Powered Portfolio Management",
      description: "Smarter Insights, Better Decisions",
      link: "/signup",
      orbit: 170,
    },
    {
      icon: Adv,
      title: "Effortless Advisory",
      description: "Automated Proposals That Convert Faster",
      link: "/signup",
      orbit: 210,
    },
    {
      icon: Eng,
      title: "Next-Gen Client Engagement",
      description: "Onboard Faster, Engage Smarter",
      link: "/signup",
      orbit: 180,
    },
    {
      icon: Trade,
      title: "Trade with Confidence",
      description: "Seamless Transactions, Zero Errors",
      link: "/signup",
      orbit: 170,
    },
  ];
 
  // Check for mobile screen size
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Standard breakpoint for mobile
    };
 
    // Initial check
    checkMobile();
 
    // Add resize listener
    window.addEventListener("resize", checkMobile);
 
    // Cleanup
    return () => window.removeEventListener("resize", checkMobile);
  }, []);
 
  // Animation effect
  useEffect(() => {
    const animate = () => {
      if (isRotating && !isMobile) {
        setRotation((prev) => (prev + 0.001) % (2 * Math.PI));
      }
      animationRef.current = requestAnimationFrame(animate);
    };
 
    animationRef.current = requestAnimationFrame(animate);
 
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isRotating, isMobile]);
 
  return (
    <div className="relative w-full h-screen bg-black overflow-hidden">
      {/* Background concentric circles - only show on desktop */}
      {!isMobile && (
        <div className="absolute inset-0 flex items-center justify-center">
          {[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((i) => (
            <div
              key={i}
              className="absolute rounded-full border border-indigo-400 opacity-20"
              style={{
                width: `${i * 100}px`,
                height: `${i * 100}px`,
              }}
            />
          ))}
        </div>
      )}
 
      {/* Central heading - adjust for mobile */}
      <div
        className={`absolute left-0 right-0 text-center ${
          isMobile ? "pt-8" : ""
        }`}
      >
        <h1
          className={`${
            isMobile ? "text-3xl" : "text-5xl"
          } font-bold text-white mb-4`}
        >
          Why Valuefy?
        </h1>
        <p
          className={`${
            isMobile ? "text-lg px-4" : "text-xl"
          } text-white opacity-80`}
        >
          Seamless Wealth orchestration – Built for Modern Wealth Management
        </p>
      </div>
 
      {/* Valuefy logo in the center with glowing purple gradient */}
      {!isMobile ? (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="absolute w-1/3 h-1/3 rounded-full bg-purple-900/20 blur-xl" />
          <div className="flex items-center">
            <img src={ValuefyLogo} alt="Valuefy" height={150} width={150} />
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center pointer-events-none">
          <div className="absolute w-1/3 h-1/3 rounded-full bg-purple-900/20 blur-xl" />
          <div className="flex items-center">
            <img src={ValuefyLogo} alt="Valuefy" height={100} width={100} />
          </div>
        </div>
      )}
 
      {/* Features - desktop orbital view or mobile stacked view */}
      {!isMobile ? (
        <div className="absolute inset-0 flex items-center justify-center">
          {features.map((feature, index) => {
            const angle = rotation + index * ((2 * Math.PI) / features.length);
            return (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                link={feature.link}
                angle={angle}
                orbit={feature.orbit}
                isRotating={isRotating}
                setIsRotating={setIsRotating}
                isMobile={isMobile}
                index={index}
              />
            );
          })}
        </div>
      ) : (
        <div className="absolute pt-10 inset-0 flex flex-col items-center justify-center">
          <div className="w-full max-w-md px-4 mt-32 mb-16 space-y-4 overflow-y-auto max-h-screen">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                link={feature.link}
                angle={0}
                orbit={0}
                isRotating={false}
                setIsRotating={setIsRotating}
                isMobile={isMobile}
                index={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
 
export default RevolvingIcons;