import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import BlogDetail from "./BlogDetail";
import RelatedArticles from "./BlogRelatedArticles";
import BlogNewsletterWithImage from "./BlogNewsletterWithImage";
// import ValuefyFeatures from './ValuefyFeatures';
import ValuefyInAction from "./ValuefyInAction";
import { BLOG_POSTS_CONTENT } from "./blog-posts.constants";
import { BLOG_POSTS } from "./blogs.constants";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import valefyLogo from '../assets/blog/valuefy-logo-blog.svg';

// This interface should match what BlogDetail expects
interface NormalizedBlogPost {
  id: string;
  title: string;
  subtitle?: string;
  author?: {
    name: string;
    image?: string;
  };
  date: string;
  readTime: string;
  mainImage: string;
  content: string;
  sections: Array<{
    id: string;
    title: string;
  }>;
}

const BlogDetailPage: React.FC = () => {
  const location = useLocation();
  const { slug } = useParams<{ slug: string }>();
  
  // Extract the blog ID from the URL path
  const pathSegments = location.pathname.split("/");
  const blogId = pathSegments[pathSegments.length - 1];

  console.log("URL Path:", location.pathname);
  console.log("Extracted blogId:", blogId);
  console.log("Slug:", slug);

  const [post, setPost] = useState<NormalizedBlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Scroll to top when component mounts or blogId changes
    window.scrollTo(0, 0);
    setLoading(true);

    // Find blog post from multiple sources and normalize the data
    const findAndNormalizeBlog = () => {
      // Try to find in blog-posts.constants.ts
      let foundPost = BLOG_POSTS_CONTENT[blogId];
      
      if (foundPost) {
        // Normalize the post from BLOG_POSTS_CONTENT if found
        const normalizedPost: NormalizedBlogPost = {
          id: foundPost.id,
          title: foundPost.title,
          subtitle: foundPost.subtitle,
          author: {
            name: typeof foundPost.author === 'string' ? foundPost.author : 'Valuefy',
            image: valefyLogo // Default author image
          },
          date: foundPost.date,
          readTime: foundPost.readTime || '5 min read',
          mainImage: foundPost.mainImage,
          content: foundPost.content,
          sections: (foundPost.sections || []).map(section => ({
            id: section.title.toLowerCase().replace(/\s+/g, '-'),
            title: section.title
          }))
        };
        
        setPost(normalizedPost);
        setLoading(false);
        return;
      }
      
      // If not found in BLOG_POSTS_CONTENT, try BLOG_POSTS
      const blogPostFromList = BLOG_POSTS.find(
        (post) => post.url.split("/").pop() === slug
      );
      
      if (blogPostFromList) {
        // Normalize the post from BLOG_POSTS if found
        const normalizedPost: NormalizedBlogPost = {
          id: blogPostFromList.id,
          title: blogPostFromList.title,
          subtitle: '',
          author: {
            name: 'Valuefy',
            image: valefyLogo
          },
          date: blogPostFromList.date,
          readTime: '5 min read',
          mainImage: blogPostFromList.imageUrl,
          content: blogPostFromList.description,
          sections: [] // No sections for this post type
        };
        
        setPost(normalizedPost);
      }
      
      setLoading(false);
    };

    // Execute the search
    findAndNormalizeBlog();
  }, [blogId, slug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-black text-white">
        <Navbar />
        <div className="container mx-auto px-4 py-20 flex justify-center">
          <svg
            className="animate-spin h-8 w-8 text-purple-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        </div>
      </div>
    );
  }

  if (!post) {
    return <NotFound />;
  }

  return (
    <div className="min-h-screen bg-black text-white">
      <Navbar />
      <BlogDetail post={post} />

      {/* Related Articles Section */}
      <RelatedArticles currentPostId={post.id} numberOfPosts={3} />

      {/* Newsletter Section */}
      <ValuefyInAction />
      <BlogNewsletterWithImage />
    </div>
  );
};

export default BlogDetailPage;
