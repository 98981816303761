import { useRef} from 'react';
import carousal1 from '../assets/about-us/carousal1.png'
import carousal2 from '../assets/about-us/carousal2.png'
import carousal3 from '../assets/about-us/carousal3.png'
import carousal4 from '../assets/about-us/carousal4.png'

const WhoWeServe = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const audiences = [
    {
      title: "CXOs & Strategy Leaders",
      description: "Driving digital transformation in wealth management.",
      image: carousal1 // Replace with your actual image path
    },
    {
      title: "Relationship Managers",
      description: "Engaging clients with automated insights and personalized solutions.",
      image: carousal2 // Replace with your actual image path
    },
    {
      title: "Ops & Compliance Managers",
      description: "Automating workflows and ensuring regulatory excellence.",
      image: carousal3 // Replace with your actual image path
    },
    {
      title: "Advisory Managers",
      description: "Delivering data-backed investment guidance at scale.",
      image: carousal4 // Replace with your actual image path
    }
  ];
  // Scroll functionality
  // const scroll = (scrollOffset : number) => {
  //   if (scrollRef.current) {
  //     scrollRef.current.scrollBy({
  //       left: scrollOffset,
  //       behavior: 'smooth'
  //     });
  //   }
  // };

  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-8">Who We Serve</h2>
        
        <p className="text-center mb-12 text-2xl mx-auto">
          We power <span className="text-purple-500" style={{lineHeight:'190%'}}>India's and the world's</span> leading financial institutions, catering to <br/> professionals across all levels
        </p>
        
        {/* Scrollable container */}
        <div className="relative">
          <div 
            ref={scrollRef}
            className="flex overflow-x-auto pb-8 hide-scrollbar snap-x"
            style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
          >
            {audiences.map((audience, index) => (
              <div 
                key={index} 
                className="flex-shrink-0 w-full md:w-80 mx-2 snap-center bg-black-900 rounded-lg overflow-hidden"
              >
                <div className="h-128 overflow-hidden">
                  <img 
                    src={audience.image} 
                    alt={audience.title} 
                    className="w-full h-full object-cover"
                  />
                </div>
                
                <div className="p-6">
                  <h3 className="text-3xl font-bold mb-2">{audience.title}</h3>
                  <p className="text-gray-300 text-xl">{audience.description}</p>
                </div>
              </div>
            ))}
          </div>
          
          {/* Scroll progress indicator/line */}
          <div className="mt-4 w-full h-1 bg-gray-800">
          </div>
          
          {/* Optional scroll buttons */}
          {/* <div className="hidden md:flex justify-between w-full absolute top-1/2 transform -translate-y-1/2 px-4 z-10">
            <button 
              onClick={() => scroll(-300)}
              className="bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            <button 
              onClick={() => scroll(300)}
              className="bg-black bg-opacity-50 hover:bg-opacity-75 rounded-full p-2 focus:outline-none"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default WhoWeServe;