import React from 'react';
import Navbar from './Navbar';

// You can create these components later
import WMHero from './WMHero';
import TodaysReality from './TodaysReality';
import ValuefyFeatures from './ValuefyFeatures';
import RelationshipShowcase from './RelationshipShowcase';
import BookDemoRM from './BookDemoRM';
// Footer removed to avoid duplication with RootLayout footer
// import RelationshipFooter from './RelationshipFooter';

const RMPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <TodaysReality/>
      <ValuefyFeatures/>
      <RelationshipShowcase/>
      <BookDemoRM/>
      {/* RelationshipFooter removed to avoid duplication */}
    </div>
  );
};

export default RMPage;