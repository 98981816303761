import { useState } from 'react';
import image1 from '../assets/about-us/what-we-do.png'
const WhatWeDo = () => {
  // Define services content
  const services = [
    {
      id: 1,
      title: "Portfolio Analytics & Insights",
      description: "Automation-powered investment intelligence that decodes complex portfolios in real time."
    },
    {
      id: 2,
      title: "Intelligent Workflow Automation",
      description: "Automating client onboarding, compliance, and AUM growth."
    },
    {
      id: 3,
      title: "Hyper-Personalized Client Engagement",
      description: "A next-gen Wealth CRM, built for deep client relationships."
    },
    {
      id: 4,
      title: "Integrated Wealth Management Ecosystem",
      description: "A seamless platform designed for financial institutions at scale."
    }
  ];

  // State to track which items are expanded
  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({});

  // Toggle function for each collapsible item
  const toggleItem = (id:number) => {
    setExpandedItems(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-5xl font-bold text-center mb-8">What We Do</h2>
        
        <p className="text-center mb-12 text-3xl mx-auto">
          We provide <span className="text-purple-500">next-generation WealthTech solutions</span> that optimize investment <br/>
          processes, enhance client experiences, and drive intelligent growth.
        </p>
        
        <div className="flex flex-col md:flex-row gap-8">
          {/* Left side - Image */}
          <div className="md:w-1/2">
            <div className="rounded-lg overflow-hidden h-full">
              <img 
                src={image1} 
                alt="Team celebrating success"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          
          {/* Right side - Services */}
          <div className="md:w-1/2 space-y-4">
            {services.map((service) => (
              <div 
                key={service.id} 
                className="border-b border-gray-800 pb-2"
              >
                <div 
                  className="flex justify-between items-center cursor-pointer py-3"
                  onClick={() => toggleItem(service.id)}
                >
                  <h3 className="text-4xl font-inter">{service.title}</h3>
                  <span className="text-2xl">
                    {expandedItems[service.id] ? '-' : '+'}
                  </span>
                </div>
                
                <div 
                  className={`overflow-hidden transition-all duration-300 ease-in-out ${
                    expandedItems[service.id] 
                      ? 'max-h-24 opacity-100 pt-2 pb-4' 
                      : 'max-h-0 opacity-0'
                  }`}
                >
                  <p className="text-gray-300 text-2xl">{service.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;