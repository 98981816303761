import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// import image from '../assets/blog/Vector.png';
// Import the image - update path as needed

import { BLOG_POSTS, BlogPost } from './blogs.constants';




const BlogTopArticles: React.FC = () => {
  const [featuredArticle, setFeaturedArticle] = useState<BlogPost | null>(null);
  const [topArticles, setTopArticles] = useState<BlogPost[]>([]);

  useEffect(() => {
    // Sort blog posts by date (newest first)
    const sortedPosts = [...BLOG_POSTS].sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB.getTime() - dateA.getTime();
    });

    // Get the first 4 most recent posts
    const recentPosts = sortedPosts.slice(0, 4);
    
    // Set the first post as featured article
    if (recentPosts.length > 0) {
      setFeaturedArticle(recentPosts[0]);
    }
    
    // Set the next 3 posts as top articles
    if (recentPosts.length > 1) {
      setTopArticles(recentPosts.slice(1, 4));
    }
  }, []);

  // Function to handle scroll to top when clicking links
  const handleLinkClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='w-full relative'>
      {/* <img className='absolute top-0 left-0 w-full' src={image} /> */}
      <section className="w-full text-white z-10 relative mt-32 md:mt-0">
        <div className="container mx-auto px-4 pb-8 pt-[4.5rem]">
          <h2 className="text-3xl font-bold text-center mb-12">Explore Our Top Articles</h2>

          {featuredArticle && topArticles.length > 0 && (
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              {/* Left Column: Featured Article with Image */}
              <div className="flex flex-col">
                <Link to={featuredArticle.url} onClick={handleLinkClick} className="block">
                  <div className="rounded-lg overflow-hidden mb-4">
                    <img
                      src={featuredArticle.imageUrl}
                      alt={featuredArticle.title}
                      className="w-full h-128 object-cover transition-transform duration-300 hover:scale-105"
                    />
                  </div>
                  <h3 className="text-4xl font-bold mb-8">{featuredArticle.title}</h3>
                  <p className="text-gray-300 mb-4">{featuredArticle.description}</p>
                  <div className="flex items-center text-purple-500 font-medium">
                    Read Now
                    <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </div>
                </Link>
              </div>

              {/* Right Column: List of Articles */}
              <div className="flex flex-col space-y-16">
                {topArticles.map((article) => (
                  <div key={article.id} className="border-b border-gray-800 pb-6 last:border-0">
                    <Link to={article.url} onClick={handleLinkClick}>
                      <h3 className="text-2xl font-bold mb-4 hover:text-purple-400 transition-colors">{article.title}</h3>
                      <div className="flex items-center text-purple-500 font-medium">
                        Read Now
                        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default BlogTopArticles;