import * as Sentry from '@sentry/react';

/**
 * Initialize Sentry for error tracking
 * This should be called once at the application startup
 */
export function initSentry(): void {
  // Use environment variable with fallback value
  const sentryDsn = import.meta.env.VITE_SENTRY_DSN || "https://598f9f2879539e4bf94c583030d577d6@o4509061699534848.ingest.de.sentry.io/4509061703925840";

  // Always initialize Sentry with either environment variable or fallback
  Sentry.init({
    dsn: sentryDsn,
    
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    
    // Environment should match your deployment environment
    environment: import.meta.env.MODE,
    
    // Enable debug in development only
    debug: import.meta.env.DEV,
    
    // Capture browser metadata
    sendDefaultPii: true,
    
    // Capture source code context
    attachStacktrace: true,
    
    // Don't include entire URL in breadcrumbs to avoid leaking PII
    normalizeDepth: 3,
    
    beforeBreadcrumb(breadcrumb) {
      // Remove sensitive keys like auth tokens from breadcrumbs
      if (breadcrumb.data && breadcrumb.data.url) {
        // Remove query params from URLs to avoid capturing sensitive information
        const url = new URL(breadcrumb.data.url);
        url.search = '';
        breadcrumb.data.url = url.toString();
      }
      return breadcrumb;
    },
    
    beforeSend(event) {
      // You can filter out certain errors or modify the event before sending
      // For example, don't send errors from local development
      if (window.location.hostname === 'localhost') {
        return null;
      }
      
      return event;
    }
  });
  
  if (import.meta.env.VITE_SENTRY_DSN === undefined) {
    console.warn('Using fallback Sentry DSN. This is only recommended for development.');
  } else {
    console.info('Sentry initialized successfully.');
  }
}

/**
 * Capture an exception manually
 * @param error The error object to capture
 * @param context Additional context to include with the error
 */
export function captureException(error: Error, context?: Record<string, any>): void {
  Sentry.captureException(error, { 
    extra: context
  });
}

/**
 * Set user information for better error context
 * Call this after user authentication
 */
export function setUserContext(user: { id: string; email?: string; username?: string }): void {
  Sentry.setUser(user);
}

/**
 * Clear user information
 * Call this after user logs out
 */
export function clearUserContext(): void {
  Sentry.setUser(null);
}

/**
 * Set extra context information
 * Useful for adding additional debugging information
 */
export function setExtraContext(key: string, value: any): void {
  Sentry.setExtra(key, value);
} 