import React from "react";

import dashboardImage from "../assets/dashboard.png";
import HeroImg from "../assets/background/hero-gradient.png";
import mobileAppImage from "../assets/mobile-app.png";
import CompaniesMarquee from "./Companies";
import ValuefyLanding from "./Valuefy-Landing";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import { checkIfUserIsSignedUp } from "../utils/authService";

const Hero: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = async () => {
    try {
      const isUserSignedUp = await checkIfUserIsSignedUp();
      
      if (isUserSignedUp) {
        // Use window.location for navigating to a different application
        window.location.href = "/demo/dashboard/";  // Note the trailing slash
      } else {
        // This stays as React Router navigation within the same app
        navigate("/signup");
      }
    } catch (error) {
      console.error("Error checking authentication status:", error);
      navigate("/signup");
    }
  };

  return (
    <>
      {/* Background gradient - only visible on large screens */}
      <div className="absolute top-0 left-0 z-0 hidden lg:block">
        <img
          src={HeroImg}
          alt="Background Element"
          className="w-[700px] h-[1100px]"
        />
      </div>
      {/* <p className="sticky top-0 z-50 bg-white shadow-md"> */}
      <Navbar />
      {/* </p> */}
      <div className="bg-black text-white py-8 px-4 sm:px-6 xl:px-[100px] md:py-12 lg:py-16 min-h-[calc(100vh-80px)] flex flex-col lg:items-center relative overflow-hidden">
        {/* Background element in top-left corner */}
        <div className="absolute top-0 left-0 z-0 w-full sm:w-auto overflow-hidden">
          <img
            src={HeroImg}
            alt="Background Element"
            className="w-full sm:w-auto"
          />
        </div>

        <div className="max-w-7xl w-full flex flex-col lg:flex-row justify-between items-center gap-6 sm:gap-8 lg:gap-10 relative z-10 mt-4 sm:mt-6 lg:mt-0">
          {/* Content Section */}
          <div className="w-full lg:max-w-xl">
            {/* Headline - responsive font sizing */}
            <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-5xl font-light opacity-90 mb-2 sm:mb-4 primary-gradient-text">
              Built to ensure <em>Growth</em> for
            </h2>

            <h1 className="text-5xl sm:text-4xl md:text-5xl lg:text-[5.625rem] font-bold pb-2 sm:pb-2 mb-4 sm:mb-6 lg:mb-10 secondary-gradient-text tracking-tighter leading-tight">
              Global Wealth
              <br />
              Managers
            </h1>

            {/* Tagline */}
            <p className="text-base sm:text-lg lg:text-xl font-medium mb-2 sm:mb-3">
              One Platform | 360° Client Management | Smarter Decisions
            </p>

            {/* Description */}
            <p className="text-sm sm:text-base opacity-90 leading-relaxed mb-4 sm:mb-6">
              One System, that empowers wealth managers and their organisation
              with seamless client interactions, AI-driven insights, and
              automated workflows so you focus on building wealth, not managing
              complexity.
            </p>

            {/* CTA Button - centered on mobile, left-aligned on larger screens */}
            <div className="flex justify-center sm:justify-start">
              <button
                onClick={handleClick}
                className="btn-primary-gradient hover:bg-primary-hover text-white px-6 sm:px-8 py-2.5 sm:py-3 rounded-lg flex items-center justify-center space-x-2 transition-colors w-full sm:w-auto max-w-xs sm:max-w-[14.25rem]"
              >
                <span>Book a Demo</span>
                {/* <span>See Live in Action</span> */}
                <span className="text-xl">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <g filter="url(#filter0_i_1728_242)">
                      <path
                        d="M13.993 1.6665H7.00964C3.9763 1.6665 2.16797 3.47484 2.16797 6.50817V13.4832C2.16797 16.5248 3.9763 18.3332 7.00964 18.3332H13.9846C17.018 18.3332 18.8263 16.5248 18.8263 13.4915V6.50817C18.8346 3.47484 17.0263 1.6665 13.993 1.6665ZM12.8263 10.4415L9.88464 13.3832C9.75964 13.5082 9.6013 13.5665 9.44297 13.5665C9.28464 13.5665 9.1263 13.5082 9.0013 13.3832C8.75963 13.1415 8.75963 12.7415 9.0013 12.4998L11.5013 9.99984L9.0013 7.49984C8.75963 7.25817 8.75963 6.85817 9.0013 6.6165C9.24297 6.37484 9.64297 6.37484 9.88464 6.6165L12.8263 9.55817C13.0763 9.79984 13.0763 10.1998 12.8263 10.4415Z"
                        fill="#F8FAFC"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter0_i_1728_242"
                        x="2.16797"
                        y="1.6665"
                        width="16.6602"
                        height="16.6665"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="BackgroundImageFix"
                          result="shape"
                        />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="0.3" />
                        <feComposite
                          in2="hardAlpha"
                          operator="arithmetic"
                          k2="-1"
                          k3="1"
                        />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="shape"
                          result="effect1_innerShadow_1728_242"
                        />
                      </filter>
                    </defs>
                  </svg>
                </span>
              </button>
            </div>
          </div>

          {/* Images Section - Stack images on mobile, position them side by side on larger screens */}
          <div className="relative flex-1 flex justify-center w-full mt-10 sm:mt-12 md:mt-10 lg:mt-0">
            {/* Dashboard Image */}
            <div className="w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl rounded-lg overflow-hidden shadow-2xl">
              <img
                src={dashboardImage}
                alt="Wealth Management Dashboard"
                className="w-full h-auto"
              />
            </div>

            {/* Mobile App Image - Completely hidden on mobile and tablets (only visible on lg screens and above) */}
            <div
              className="absolute 
                hidden lg:block
                -bottom-28 
                right-8 
                lg:w-80 xl:w-[450px] 
                rounded-xl overflow-hidden 
                z-20"
            >
              <img
                src={mobileAppImage}
                alt="Mobile App Interface"
                className="w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <CompaniesMarquee />
      <div>
        <ValuefyLanding />
      </div>
    </>
  );
};

export default Hero;