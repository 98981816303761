import WealthImg from "../assets/platform/WealthPower.png";
import MessageSVG from "../assets/message.svg";


interface AboutUnlock {
  headline?: string;
  subheading?: string;
  signUpButtonText?: string;
  imageSrc?: string;
  onSignUp?: () => void;
  talkToExpert?: boolean;
}

const AboutUnlock = ({
  headline = `Let's Talk Wealth Tech`,
  subheading = "",
  // signUpButtonText = "Sign Up",
  //   imageSrc = { WealthImg },
  // onSignUp = () => {},
  talkToExpert = false,
}: AboutUnlock) => {
  return (
    <div className="relative w-full h-[800px] overflow-hidden bg-black bg-[url('https://storage.googleapis.com/tempo-public-images/github%7C97468556-1741606605716-tmpd3013f8519ba4340aa9b9d9b7560d9efpng')]">
      {/* Background wave pattern */}
      <div className="absolute left-0 top-0 w-1/2 h-full opacity-10"></div>
      {/* Content container */}
      <div className="container mx-auto h-full flex items-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left column - Text content */}
          <div className="z-10 pl-4 md:pl-12">
            <h1 className="lg:text-8xl text-5xl font-bold mb-6 leading-tight bg-gradient-to-r from-white to-gray-500 bg-clip-text text-transparent">
              {headline}
            </h1>

            <p className="text-lg text-gray-300 mb-10 max-w-lg">{subheading}</p>
            <div className="mb-8">
              {/* <Button
                  onClick={onSignUp}
                  className="bg-white text-[#121212] hover:bg-gray-200 font-medium rounded-md px-10 py-3 text-lg"
                  size="lg"
                >
                  {signUpButtonText}
                </Button> */}
            </div>
            {talkToExpert && (
              <a
                // href={learnMoreLink}
                className="text-white flex items-center gap-1 text-sm mt-4 group relative w-fit"
              >
                <img
                  src={MessageSVG} // Adjust the path to where your SVG is located
                  alt="Local Icon"
                  className="w-4 h-4 transition-transform duration-300"
                />
                <span className="font-medium">Talk to an Expert</span>
                <svg
                  className="w-4 h-4 transition-transform duration-300 group-hover:translate-x-1"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="absolute -bottom-1 left-0 w-0 h-0.5 bg-white transition-all duration-300 group-hover:w-full"></span>
              </a>
            )}
          </div>

          {/* Right column - Image */}
          <div className="relative w-full h-full md:w-auto md:h-auto ">
            <div className="relative overflow-hidden rounded-lg w-full h-full">
              {/* Make sure no padding and margin on the right side */}
              <img
                src={WealthImg}
                alt="Two professionals in conversation"
                className="w-full h-full object-cover"
                style={{ marginRight: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUnlock;
