import React from 'react';
import Navbar from './Navbar';

// You can create these components later
import WMHero from './WMHero';
import ValuefyFeatures from './ValuefyFeatures';
import OperationReality from './OperationReality';
import OperationShowcase from './OperationShowcase';
import BookDemoCO from './BookDemoCO';
// Footer removed to avoid duplication with RootLayout footer
// import OperationFooter from './OperationTeamFooter';

const OperationTeamPage: React.FC = () => {
  return (
    <div className="min-h-screen">
      <Navbar />
      <WMHero/>
      <OperationReality/>
      <ValuefyFeatures/>
      <OperationShowcase/>
      <BookDemoCO/>
      {/* OperationFooter removed to avoid duplication */}
    </div>
  );
};

export default OperationTeamPage;