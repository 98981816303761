import { 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc, 
  query,
  collection,
  where,
  getDocs,
  serverTimestamp,
  DocumentReference 
} from 'firebase/firestore';
import { db } from '../utils/firebase/config';

// Types
export interface UserProfile {
  uid: string;
  phoneNumber?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  createdAt?: any; // Firestore Timestamp
  updatedAt?: any; // Firestore Timestamp
  lastLoginAt?: any; // Firestore Timestamp
  // Organization fields
  organizationName?: string;
  designation?: string;
  location?: string;
  // Add more fields as needed
}

export const USERS_COLLECTION = 'users';

/**
 * Creates or updates a user profile in Firestore
 * 
 * @param uid User ID from Firebase Auth
 * @param userData User profile data to save
 * @returns Promise that resolves when data is saved
 */
export const saveUserProfile = async (uid: string, userData: Partial<UserProfile>): Promise<void> => {
  try {
    const userRef = doc(db, USERS_COLLECTION, uid);
    const userDoc = await getDoc(userRef);
    
    // Generate display name if firstName is provided
    if (userData.firstName && !userData.displayName) {
      userData.displayName = generateDisplayName(userData);
    }
    
    if (!userDoc.exists()) {
      // New user - create document
      await setDoc(userRef, {
        uid,
        ...userData,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastLoginAt: serverTimestamp()
      });
      console.log(`Created new user profile for ${uid}`);
    } else {
      // Existing user - update document
      await updateDoc(userRef, {
        ...userData,
        updatedAt: serverTimestamp(),
        lastLoginAt: serverTimestamp()
      });
      console.log(`Updated user profile for ${uid}`);
    }
  } catch (error) {
    console.error('Error saving user profile:', error);
    throw error;
  }
};

/**
 * Retrieves a user profile from Firestore
 * 
 * @param uid User ID from Firebase Auth
 * @returns Promise that resolves with the user profile data
 */
export const getUserProfile = async (uid: string): Promise<UserProfile | null> => {
  try {
    const userRef = doc(db, USERS_COLLECTION, uid);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      return userDoc.data() as UserProfile;
    }
    
    return null;
  } catch (error) {
    console.error('Error getting user profile:', error);
    throw error;
  }
};

/**
 * Updates the last login timestamp for a user
 * 
 * @param uid User ID from Firebase Auth
 * @returns Promise that resolves when timestamp is updated
 */
export const updateUserLastLogin = async (uid: string): Promise<void> => {
  try {
    const userRef = doc(db, USERS_COLLECTION, uid);
    await updateDoc(userRef, {
      lastLoginAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating last login:', error);
    // Don't throw - this is non-critical
  }
};

/**
 * Generates a display name from user data
 */
const generateDisplayName = (userData: Partial<UserProfile>): string => {
  if (userData.firstName && userData.lastName) {
    return `${userData.firstName} ${userData.lastName}`;
  } else if (userData.firstName) {
    return userData.firstName;
  } else if (userData.email) {
    // Use part before @ in email
    return userData.email.split('@')[0];
  } else if (userData.phoneNumber) {
    // Just use "User" with last 4 digits of phone
    const lastFour = userData.phoneNumber.slice(-4);
    return `User ${lastFour}`;
  }
  
  return 'Valuefy User';
};

/**
 * Checks if a phone number already exists in the users collection
 * 
 * @param phoneNumber Phone number to check (with country code)
 * @returns Promise that resolves with boolean indicating if phone exists and the user data if found
 */
export const checkPhoneNumberExists = async (phoneNumber: string): Promise<{exists: boolean, userData?: UserProfile}> => {
  try {
    // Create a query to find users with the given phone number
    const usersRef = collection(db, USERS_COLLECTION);
    const q = query(usersRef, where("phoneNumber", "==", phoneNumber));
    
    // Execute the query
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      // Phone number exists - return the user data from the first matching document
      const userData = querySnapshot.docs[0].data() as UserProfile;
      return { exists: true, userData };
    }
    
    // Phone number doesn't exist
    return { exists: false };
  } catch (error) {
    console.error('Error checking phone number existence:', error);
    // Default to not existing in case of error to avoid blocking new signups
    return { exists: false };
  }
}; 