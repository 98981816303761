import icon1 from '../assets/about-us/edge1.png';
import icon2 from '../assets/about-us/edge2.png';
import icon4 from '../assets/about-us/edge3.png';

const ValuefyEdge = () => {
  return (
    <div className="w-full bg-black text-white py-16">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-16">
          <span className="font-light italic text-6xl">The</span>{" "}
          <span className="font-bold text-6xl">Valuefy</span>{" "}
          <span className="font-light italic text-6xl">Edge</span>
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
  {/* Left column - takes 2/3 of space on desktop */}
  <div className="md:col-span-2 grid grid-cols-1 gap-4">
    {/* Automation-First Approach */}
    <div className="bg-[rgba(69,42,124,0.10)] md:bg-transparent rounded-lg overflow-hidden p-4 md:p-0">
      {/* Text for mobile */}
      <div className="block md:hidden">
        <h3 className="text-3xl font-bold mb-2">Automation-First Approach</h3>
        <p className="text-gray-300">
          We believe in Automating Success. By leveraging advanced workflows, 
          automation-powered insights, and predictive analytics, we enable 
          financial professionals to focus on advisory, not operations.
        </p>
      </div>
      {/* Image for desktop */}
      <img 
        src={icon1} 
        alt="Automation-First Approach" 
        className="hidden md:block w-full h-auto"
      />
    </div>
    
    {/* Hyper-Customization at Scale */}
    <div className="bg-[rgba(69,42,124,0.10)] md:bg-transparent rounded-lg overflow-hidden p-4 md:p-0">
      {/* Text for mobile */}
      <div className="block md:hidden">
        <h3 className="text-3xl font-bold mb-2">Hyper-Customization at Scale</h3>
        <p className="text-gray-300">
          No two wealth managers operate the same way.
          Our platforms are built for flexibility, tailored to investment styles, client segments, and regulatory needs.
        </p>
      </div>
      {/* Image for desktop */}
      <img 
        src={icon2} 
        alt="Hyper-Customization at Scale" 
        className="hidden md:block w-full h-auto"
      />
    </div>
  </div>
  
  {/* Right column - takes 1/3 of space on desktop but full height */}
  <div className="bg-[rgba(69,42,124,0.10)] md:row-span-2 h-full md:bg-transparent rounded-lg overflow-hidden p-4 md:p-0">
    {/* Text for mobile */}
    <div className="block md:hidden">
      <h3 className="text-3xl font-bold mb-2">Enterprise-Grade Security & Compliance</h3>
      <p className="text-gray-300">
        We ensure banking-level security and compliance, protecting sensitive data while meeting global regulatory standards.
      </p>
    </div>
    {/* Image for desktop */}
    <img 
      src={icon4} 
      alt="Enterprise-Grade Security & Compliance" 
      className="hidden md:block w-full h-full object-cover"
    />
  </div>
</div>
      </div>
    </div>
  );
};

export default ValuefyEdge;