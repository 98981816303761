import { useState, useEffect, useRef } from "react";
import Tensed_Man from "../assets/valuefy-landing/tensed-man.png";
import Relaxed_Man from "../assets/valuefy-landing/relax-man.png";
import Red_Blur_Bg from "../assets/background/red-bg-blur.png";
import Blue_Blur_Bg from "../assets/background/blue-bg-blur.png";
import Cross from "../assets/valuefy-landing/cross.png";
import Tick from "../assets/valuefy-landing/tick.png";
import Valuefy_Logo from "../assets/background/valuefy-logo.png";
import Valuefy_Logo_Black from "../assets/background/valuefy-logo-black.png";
import Circle_Bg from "../assets/background/circle-bg.png";

// Card data for current and valuefy tabs
const currentCards = [
  "A seamless experience for clients and advisors alike.",
  "Client relationships are strained by slow, outdated processes.",
  "Endless hours spent juggling spreadsheets and disparate systems.",
  "Compliance headaches and slow transaction approvals.",
  "Missed opportunities due to fragmented data.",
  "A seamless experience for clients and advisors alike.",
  "Client relationships are strained by slow, outdated processes.",
  "Endless hours spent juggling spreadsheets and disparate systems.",
  "Compliance headaches and slow transaction approvals.",
  "Missed opportunities due to fragmented data.",
];

const valuefyCards = [
  "A seamless experience for clients and advisors alike.",
  "AI-powered portfolio insights at your fingertips.",
  "Effortless data aggregation from multiple custodians in real time.",
  "Automated transactions, compliance, and risk monitoring.",
  "A CRM for managing clients expectations before they arise.",
  "A seamless experience for clients and advisors alike.",
  "AI-powered portfolio insights at your fingertips.",
  "Effortless data aggregation from multiple custodians in real time.",
  "Automated transactions, compliance, and risk monitoring.",
  "A CRM for managing clients expectations before they arise.",
];

export default function ValuefyLanding() {
  const [activeTab, setActiveTab] = useState<"current" | "valuefy">("current");
  const [cardPositions, setCardPositions] = useState<number[]>(
    Array.from({ length: 10 }, (_, i) => (i * 360) / 10)
  );
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [autoSwitch, setAutoSwitch] = useState<boolean>(true);

  const animationRef = useRef<number>();
  const autoSwitchTimerRef = useRef<NodeJS.Timeout | null>(null);

  // Check if the viewport is mobile or tablet
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Initial check
    checkMobile();

    // Add event listener
    window.addEventListener("resize", checkMobile);

    // Clean up
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Auto-switch between tabs every 2 seconds
  useEffect(() => {
    if (autoSwitch) {
      autoSwitchTimerRef.current = setInterval(() => {
        setActiveTab((prevTab) =>
          prevTab === "current" ? "valuefy" : "current"
        );
      }, 5500);
    }

    return () => {
      if (autoSwitchTimerRef.current) {
        clearInterval(autoSwitchTimerRef.current);
        autoSwitchTimerRef.current = null;
      }
    };
  }, [autoSwitch]);

  // Pause auto-switching when user manually clicks a tab
  const handleTabClick = (tab: "current" | "valuefy") => {
    setActiveTab(tab);
    setAutoSwitch(false);

    // Resume auto-switching after 5 seconds of inactivity
    if (autoSwitchTimerRef.current) {
      clearInterval(autoSwitchTimerRef.current);
    }

    setTimeout(() => {
      setAutoSwitch(true);
    }, 5000);
  };

  useEffect(() => {
    let isPaused = false;

    const animateCards = () => {
      setCardPositions((prevPositions) => {
        return prevPositions.map((pos) => {
          // Check if card is at the max position (0 degrees)
          if (Math.abs(pos) < 0.4 && !isPaused) {
            isPaused = true;
            setTimeout(() => {
              isPaused = false;
              animationRef.current = requestAnimationFrame(animateCards);
            }, 1000); // Pause for 1 second
            return pos; // Keep position unchanged during pause
          }
          return (pos - 0.25 + 360) % 360; // Continue rotating
        });
      });

      if (!isPaused) {
        animationRef.current = requestAnimationFrame(animateCards);
      }
    };

    // Only animate on desktop
    if (!isMobile) {
      animationRef.current = requestAnimationFrame(animateCards);
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isMobile]);

  const getCardStyle = (degree: number) => {
    // For mobile, stack the cards vertically
    if (isMobile) {
      return {
        opacity: 1,
        zIndex: 10,
      };
    }

    // Desktop rotating style
    const normalizedDegree = degree % 360;
    const radian = (normalizedDegree * Math.PI) / 180;
    const radius = window.innerWidth < 1024 ? 250 : 350; // Smaller radius for tablet

    // Adjust x based on screen size
    const x = Math.sin(radian) * radius + (window.innerWidth < 1024 ? 80 : 160);
    const y = Math.cos(radian) * radius;

    const scaleFactor = 0.6 + 0.4 * Math.sin(radian + Math.PI);
    const zIndex = Math.floor(100 * Math.sin(radian)) + 100;

    return {
      transform: `translate(${x}px, ${-y}px) scale(${scaleFactor})`,
      opacity: scaleFactor,
      zIndex,
    };
  };

  // Get the first 5 cards for mobile view
  const getMobileCards = (cards:any) => {
    return cards.slice(0, 5);
  };

  return (
    <div
      className={`relative min-h-screen overflow-hidden transition-all duration-400 ${
        activeTab === "valuefy"
          ? "bg-white"
          : "bg-black from-[#050a14] to-[#0a1428]"
      }`}
    >
      {/* Background pattern - responsive sizing */}
      {activeTab === "current" && (
        <img
          src={Red_Blur_Bg}
          alt="Background Element"
          className="absolute w-full sm:w-3/4 md:w-[1000px] h-full object-cover z-0 opacity-70 sm:opacity-100"
        />
      )}
      {activeTab === "valuefy" && (
        <img
          src={Blue_Blur_Bg}
          alt="Background Element"
          className="absolute w-full sm:w-3/4 md:w-[1000px] h-full object-cover z-0 opacity-70 sm:opacity-100"
        />
      )}
      <div className="absolute right-0 top-[220px] z-0 hidden md:block">
        <img
          src={Circle_Bg}
          alt="Background Element"
          className="w-auto h-[300px] lg:h-[550px]"
        />
      </div>

      <div className="container mx-auto px-4 py-8 md:py-16 flex flex-col md:flex-row items-center relative z-10">
        {/* Left content */}
        <div className="w-full md:w-1/2 mb-10 md:mb-0 flex flex-col items-center md:items-start">
          <h1
            className={`text-3xl sm:text-4xl md:text-5xl font-bold mb-2 transition-all duration-500 text-center md:text-left ${
              activeTab === "valuefy" ? "text-black" : "text-white"
            }`}
          >
            Life powered by:
            <div className="mt-1">Valuefy</div>
          </h1>

          <p
            className={`mb-5 max-w-md text-center md:text-left text-lg sm:text-xl transition-all duration-500 ${
              activeTab === "valuefy" ? "text-gray-800" : "text-gray-300"
            }`}
          >
            Why settle for disconnected multiple systems?
            <br />
            Get One Integrated System
          </p>

          {/* Tabs - made more touchable on mobile */}
          <div className="inline-flex rounded-lg overflow-hidden border border-gray-700 bg-white p-[3px] gap-[0.25rem]">
            <button
              className={`px-4 sm:px-6 py-2 sm:py-3 text-sm font-medium rounded-lg transition-all duration-300 ${
                activeTab === "current"
                  ? "bg-gradient-pink text-white"
                  : "bg-transparent text-black-300 hover:bg-gray-300"
              }`}
              onClick={() => handleTabClick("current")}
            >
              Current Life
            </button>
            <button
              className={`px-4 sm:px-6 py-2 sm:py-3 text-sm font-medium rounded-lg transition-all duration-300 ${
                activeTab === "valuefy"
                  ? "bg-blue-600 text-white"
                  : "bg-transparent text-black hover:bg-gray-300"
              }`}
              onClick={() => handleTabClick("valuefy")}
            >
              <div className="flex items-center gap-2">
                <span>Valuefy</span>
                <img
                  src={
                    activeTab === "current" ? Valuefy_Logo_Black : Valuefy_Logo
                  }
                  alt="logo"
                  height={11}
                  width={11}
                />
              </div>
            </button>
          </div>

          {/* Person image - responsive sizing */}
          <div className="mt-6 sm:mt-8 md:mt-12 relative w-full sm:w-[350px] md:w-[450px] h-[300px] sm:h-[400px] md:h-[500px]">
            <img
              src={activeTab === "valuefy" ? Relaxed_Man : Tensed_Man}
              height={500}
              width={400}
              alt="Business professional using Valuefy"
              className="relative z-10 w-full h-auto sm:h-[450px] md:h-[565px] object-contain md:object-cover"
            />
          </div>
        </div>

        {/* Right content - Revolving Cards with mobile stacking */}
        <div className="w-full md:w-1/2 relative h-auto md:h-[600px] mt-8 md:mt-0">
          {isMobile ? (
            // Mobile view - stack cards vertically (only first 5)
            <div className="flex flex-col space-y-4 px-2">
              {(activeTab === "valuefy"
                ? getMobileCards(valuefyCards)
                : getMobileCards(currentCards)
              ).map((text:string, index:any) => (
                <div
                  key={index}
                  className={`rounded-lg p-4 transition-colors duration-500 ${
                    activeTab === "valuefy"
                      ? "bg-gray-200 border border-gray-300"
                      : "bg-blue-900/20 border border-blue-800/30"
                  } w-full`}
                >
                  <div className="flex items-start gap-3">
                    <img
                      src={activeTab === "valuefy" ? Tick : Cross}
                      alt={activeTab === "valuefy" ? "tick" : "cross"}
                      className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 mt-1 flex-shrink-0 transition-all duration-500"
                    />
                    <p
                      className={`text-sm sm:text-base md:text-lg transition-all duration-500 ${
                        activeTab === "valuefy"
                          ? "text-gray-800"
                          : "text-gray-300"
                      }`}
                    >
                      {text}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            // Desktop view - revolving cards (all 10 cards)
            <div className="absolute right-0 h-full flex items-center">
              <div className="relative w-[200px] sm:w-[250px] md:w-[300px] h-[400px] sm:h-[500px] md:h-[600px]">
                {cardPositions.map((position, index) => (
                  <div
                    key={index}
                    style={{
                      ...getCardStyle(position),
                      position: "absolute",
                      top: "50%",
                      right: 0,
                      transition:
                        "transform 0.1s ease-out, opacity 0.1s ease-out",
                    }}
                    className={`rounded-lg p-4 transition-colors duration-500 ${
                      activeTab === "valuefy"
                        ? "bg-gray-200 border border-gray-300"
                        : "bg-blue-900/20 border border-blue-800/30"
                    } w-[280px] sm:w-[320px] md:w-[400px]`}
                  >
                    <div className="flex items-start gap-3">
                      <img
                        src={activeTab === "valuefy" ? Tick : Cross}
                        alt={activeTab === "valuefy" ? "tick" : "cross"}
                        className="w-8 h-8 sm:w-10 sm:h-10 md:w-12 md:h-12 mt-1 flex-shrink-0 transition-all duration-500"
                      />
                      <p
                        className={`text-sm sm:text-base md:text-lg transition-all duration-500 ${
                          activeTab === "valuefy"
                            ? "text-gray-800"
                            : "text-gray-300"
                        }`}
                      >
                        {activeTab === "valuefy"
                          ? valuefyCards[index]
                          : currentCards[index]}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}